import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { FunctionComponent, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import config from "../../config";
import { AppThunk, RootState } from "../../store/store";
import { Asset, Status } from "../Assets/Asset";
import { authUserSelector } from "../Auth/authSlice";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { IAssetMap } from "./IAssetMap";

interface AssetMapState {
  isLoading: boolean;
  assetsMap: IAssetMap[];
  status: Status[];
  mapLayers: string[];
}

const initialState: AssetMapState = {
  isLoading: false,
  assetsMap: [],
  status: ["none", "critical", "major", "minor", "warning"],
  mapLayers: [],
};

export const assetMapSlice = createSlice({
  name: "assetMap",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<AssetMapState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setAssets(state, action: PayloadAction<AssetMapState["assetsMap"]>) {
      state.assetsMap = action.payload;
    },
    replaceAssetMap(state, action: PayloadAction<any>) {
      // const assetMap = state.assetsMap.find(
      //   (a) => a.id === action.payload?.device?.asset?.id
      // );
      // if (assetMap) assetMap.last_event = action.payload;
    },
  },
});

export const assetMapReducer = assetMapSlice.reducer;

const { setAssets, setLoading, replaceAssetMap } = assetMapSlice.actions;

export const fetchAssetMap = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.get<IAssetMap[]>(`/api/assets/map`);

    batch(() => {
      dispatch(setAssets(response.data));
      dispatch(setLoading(false));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const useAssetMapSubscription = (assetMap: Asset) => {
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  let socket: any = null;
  try {
    socket = new WebSocket(config.socketUrl);
  } catch (e) {
    // console.log(e);
  }

  useEffect(() => {
    if (assetMap) {
      if (socket !== undefined && socket !== null) {
        socket.addEventListener("open", () => {
          let msg = {
            type: "authentication",
            token: user?.token,
            devices: assetMap.devices.map((d) => d.dev_eui),
            baseUrl: Axios.defaults.baseURL,
          };
          socket.send(JSON.stringify(msg));
        });

        const replaceEventDispatch = (event: Event) => {
          const device = JSON.parse((event as any).data);
          dispatch(replaceAssetMap(device));
        };

        socket.addEventListener("message", replaceEventDispatch);

        return () => {
          socket.removeEventListener("message", replaceEventDispatch);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetMap]);
};

export const AssetMapSubscription: FunctionComponent<{
  assetMap: Asset;
}> = ({ assetMap }) => {
  useAssetMapSubscription(assetMap);
  return null;
};

export const assetMapAssetsSelector = (state: RootState) =>
  state.assetMap.assetsMap;
export const assetMapIsLoadingSelector = (state: RootState) =>
  state.assetMap.isLoading;
