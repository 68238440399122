import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { ConfirmButton } from "../../../../common/components/Buttons/ConfirmButton";
import DateFnsDistanceNow from "../../../../common/components/DateFnsDistanceNow/DateFnsDistanceNow";
import PropertyField from "../../../../components/PropertyField/PropertyField";
import { format } from "../../../../utils/dateFnsInternationalization";
import { AssetProfileDefinition } from "../../../AssetProfile/assetProfile";
import { assetProfileApi } from "../../../AssetProfile/assetProfileApi";
import { assetProfileDefinitionStore } from "../../../AssetProfile/assetProfileDefinitionStore";
import { authUserSelector } from "../../../Auth/authSlice";
import { Customer } from "../../../Customers/Customer";
import {
  customersErrorMessageSelector,
  customersIsLoadingSelector,
  customersSelector,
  fetchCustomers,
} from "../../../Customers/customersSlice";
import { Device } from "../../../Device/Device";
import {
  devicesErrorMessageSelector,
  devicesIsLoadingSelector,
  devicesSelector,
  fetchDevices,
} from "../../../Device/devicesSlice";
import { GeoZone } from "../../../GeoZone/GeoZone";
import {
  fetchGeoZones,
  geoZonesErrorMessageSelector,
  geoZonesIsLoadingSelector,
  geoZonesSelector,
} from "../../../GeoZone/geoZoneSlice";
import { UserRole } from "../../../User/User";
import { Asset, AssetStatus } from "../../Asset";
import {
  deliveredAsset,
  deregisterAsset,
  resetAssetDaysOutsideGeozone,
} from "../../assetsSlice";
import AssetStatusComponent from "../../AssetStatus";
import {
  assetsStatusSelector,
  fetchAssetStatuses,
} from "../../assetStatusSlice";
import {
  openAssetSelector,
  setOpenAsset,
  updateOpenAsset,
} from "../../openAssetSlice";
import { AssetProfileDetails } from "./AssetProfileDetails";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    "& > div.MuiFilledInput-adornedStart:not(.nothing):not(.nothing)": {
      paddingTop: 23,
      paddingBottom: 4,
    },
  },
  deliveredButton: {
    background: theme.palette.success.main,
  },
}));

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const ReportButton: FunctionComponent<{
  buttonText?: string;
  readyText?: string;
  color?: "primary" | "secondary" | "inherit";
  displayButton?: boolean;
  className?: string;
  onClick?: () => void;
}> = ({ buttonText, readyText, color, displayButton, className, onClick }) => {
  const selectedColor = color !== undefined ? color : "primary";

  return (
    <>
      {displayButton ? (
        <Box mt={2}>
          <Button
            disableElevation
            color={selectedColor}
            variant="contained"
            onClick={onClick}
            className={className}
          >
            {buttonText}
          </Button>
        </Box>
      ) : (
        <Box mt={2}>
          <Typography variant="body1">{readyText}</Typography>
        </Box>
      )}
    </>
  );
};

declare type DetailsProps = {
  isEditing: boolean;
  hasId: boolean;
};

export const Details: FunctionComponent<DetailsProps> = (
  { isEditing, hasId },
  ref
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openAsset = useSelector(openAssetSelector);
  const assetStatuses = useSelector(assetsStatusSelector);
  const devices = useSelector(devicesSelector);
  const isDevicesLoading = useSelector(devicesIsLoadingSelector);
  const devicesErrorMessage = useSelector(devicesErrorMessageSelector);

  const geoZones = useSelector(geoZonesSelector);
  const isGeoZonesLoading = useSelector(geoZonesIsLoadingSelector);
  const geoZonesErrorMessage = useSelector(geoZonesErrorMessageSelector);

  const customers = useSelector(customersSelector);
  const isCustomersLoading = useSelector(customersIsLoadingSelector);
  const customersErrorMessage = useSelector(customersErrorMessageSelector);

  const user = useSelector(authUserSelector);
  const isClient = user?.roles.includes(UserRole.ROLE_CLIENT);
  const isTransporter = user?.roles.includes(UserRole.ROLE_TRANSPORTER);

  const canReportEmpty = isClient || isTransporter;
  const canReportDelivered = isTransporter;

  const assetProfiles = useSelector(
    assetProfileDefinitionStore.entitiesSelector
  );
  const isAssetProfilesLoading = useSelector(
    assetProfileDefinitionStore.isLoadingSelector
  );

  useMount(() => {
    dispatch(assetProfileDefinitionStore.fetch());
  });

  useEffect(() => {
    if (isEditing) {
      dispatch(fetchDevices(true, true));
      dispatch(fetchGeoZones());
      dispatch(fetchCustomers());
    }

    dispatch(fetchAssetStatuses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasId, isEditing]);

  useEffect(() => {
    if (
      !!openAsset?.id &&
      !openAsset?.asset_profile_definitions &&
      !!assetProfiles
    ) {
      const promises: Promise<any>[] = [];
      openAsset?.asset_profile_relations?.forEach((assetProfile) => {
        const promise = assetProfileApi.get(assetProfile);
        promises.push(promise);
      });

      Promise.all(promises).then((result) => {
        const selectedProfiles = assetProfiles?.filter((profile) => {
          return result?.some(
            (selectedProfile) =>
              selectedProfile.related_definition === profile.id
          );
        });

        if (!!openAsset?.id) {
          dispatch(
            updateOpenAsset({ asset_profile_definitions: selectedProfiles })
          );
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAsset, assetProfiles]);

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <PropertyField
          label={t("Name")}
          value={openAsset?.name}
          isEditing={isEditing}
          required
          xs={12}
          sm={hasId ? 6 : undefined}
          onChange={(value) => dispatch(updateOpenAsset({ name: value }))}
        />
        <PropertyField
          label={t("Reference")}
          value={openAsset?.reference}
          isEditing={isEditing}
          xs={12}
          sm={hasId ? 6 : undefined}
          onChange={(value) => dispatch(updateOpenAsset({ reference: value }))}
        />
        {/*<PropertyField
          label={t("Client")}
          value={openAsset?.client}
          isEditing={isEditing}
          xs={12}
          sm={hasId ? 6 : undefined}
        />
        <PropertyField
          label={t("Asset group")}
          value={openAsset?.assetGroup}
          isEditing={isEditing}
          xs={12}
          sm={hasId ? 6 : undefined}
        />*/}
        <PropertyField
          label={t("Description")}
          value={openAsset?.description}
          isEditing={isEditing}
          multiline
          xs={12}
          sm={hasId ? 12 : undefined}
          onChange={(value) =>
            dispatch(updateOpenAsset({ description: value }))
          }
        />
        {!isClient && (
          <>
            {isEditing ? (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={devices}
                  disableCloseOnSelect
                  getOptionLabel={(option: Device) =>
                    `${option.dev_eui}_${option.name}`
                  }
                  loading={isDevicesLoading}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.dev_eui + " - " + option.barcode}
                    </>
                  )}
                  fullWidth
                  loadingText={`${t("Loading")}...`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("Devices")}`}
                      fullWidth
                      variant="filled"
                      className={classes.textField}
                      error={!isDevicesLoading && !!devicesErrorMessage}
                      helperText={
                        isDevicesLoading ? (
                          `${t("Loading")}...`
                        ) : devicesErrorMessage ? (
                          <>
                            {t(devicesErrorMessage)}{" "}
                            <Link onClick={() => dispatch(fetchDevices())}>
                              {t("Try again")}
                            </Link>
                          </>
                        ) : undefined
                      }
                    />
                  )}
                  ChipProps={{
                    size: "small",
                  }}
                  onChange={(_, value) =>
                    dispatch(updateOpenAsset({ devices: value }))
                  }
                  noOptionsText={devicesErrorMessage}
                  value={openAsset?.devices || []}
                />
              </Grid>
            ) : (
              <PropertyField
                label={t("Devices")}
                value={openAsset?.devices
                  ?.map((d) => d.name || d.dev_eui)
                  .join(", ")}
                xs={12}
                sm={hasId ? 6 : undefined}
              />
            )}
          </>
        )}
        {openAsset?.street && (
          <PropertyField
            label={t("Address")}
            value={`${openAsset.street}, ${openAsset.postalCode || ""}, ${openAsset.city || ""
              }, ${openAsset.region || ""}, ${openAsset.country || ""}`}
            xs={12}
            sm={6}
          />
        )}
        {openAsset?.days_still !== undefined && (
          <PropertyField
            label={t("Days still")}
            value={openAsset.days_still}
            xs={12}
            sm={6}
          />
        )}
        {openAsset?.lastCommunication && (
          <Grid item xs={12} sm={6}>
            <PropertyField
              label={t("Last location update")}
              value={format(new Date(openAsset.lastCommunication), "Pp")}
            />
            <DateFnsDistanceNow
              date={openAsset.lastCommunication}
              addSuffix
              includeSeconds
            />
          </Grid>
        )}
        {openAsset?.lastLocationUpdate && (
          <Grid item xs={12} sm={6}>
            <PropertyField
              label={t("Last location update")}
              value={format(new Date(openAsset.lastLocationUpdate), "Pp")}
            />
            <DateFnsDistanceNow
              date={openAsset.lastLocationUpdate}
              addSuffix
              includeSeconds
            />
          </Grid>
        )}
        {!isClient &&
          hasId &&
          (isEditing ? (
            <Grid item xs={12}>
              <Autocomplete
                options={geoZones}
                disableCloseOnSelect
                getOptionLabel={(option: GeoZone) => option.name}
                loading={isGeoZonesLoading}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => <>{option.name}</>}
                fullWidth
                loadingText={`${t("Loading")}...`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("Geo zone")}`}
                    fullWidth
                    variant="filled"
                    className={classes.textField}
                    error={!isGeoZonesLoading && !!geoZonesErrorMessage}
                    helperText={
                      isGeoZonesLoading ? (
                        `${t("Loading")}...`
                      ) : geoZonesErrorMessage ? (
                        <>
                          {t(geoZonesErrorMessage)}{" "}
                          <Link onClick={() => dispatch(fetchDevices())}>
                            {t("Try again")}
                          </Link>
                        </>
                      ) : undefined
                    }
                  />
                )}
                ChipProps={{
                  size: "small",
                }}
                onChange={(_, value) =>
                  dispatch(updateOpenAsset({ geo_zone: value }))
                }
                noOptionsText={devicesErrorMessage}
                value={openAsset?.geo_zone || null}
              />
            </Grid>
          ) : (
            <PropertyField
              label={t("Geo zone")}
              value={openAsset?.geo_zone?.name || t("Not in a zone")}
              xs={12}
              sm={hasId ? 6 : undefined}
            />
          ))}

        <PropertyField
          label={t("Device last communication")}
          value={openAsset?.devices
            ?.map((d) =>
              !!d.last_communication
                ? format(new Date(d.last_communication), "Pp")
                : ""
            )
            .join(", ")}
          xs={12}
          sm={hasId ? 6 : undefined}
        />
        <Grid item xs={12} sm={hasId ? 6 : undefined}></Grid>

        {hasId &&
          (isEditing ? (
            <Grid item xs={12} sm={hasId ? 6 : undefined}>
              <Autocomplete
                options={customers}
                disableCloseOnSelect
                getOptionLabel={(option: Customer) => {
                  return option.company || option.name || t("Unknown");
                }}
                loading={isCustomersLoading}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <>{option.company || t("Unknown")}</>
                )}
                fullWidth
                loadingText={`${t("Loading")}...`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("Customer")}`}
                    fullWidth
                    variant="filled"
                    className={classes.textField}
                    error={!isCustomersLoading && !!isCustomersLoading}
                    helperText={
                      isGeoZonesLoading ? (
                        `${t("Loading")}...`
                      ) : customersErrorMessage ? (
                        <>
                          {t(customersErrorMessage)}{" "}
                          <Link onClick={() => dispatch(fetchCustomers())}>
                            {t("Try again")}
                          </Link>
                        </>
                      ) : undefined
                    }
                  />
                )}
                ChipProps={{
                  size: "small",
                }}
                onChange={(_, value) => {
                  const status = assetStatuses.find(
                    (status: AssetStatus) => status.status === "registered"
                  );
                  dispatch(
                    updateOpenAsset({
                      client: value as Customer,
                      status: status,
                    })
                  );
                }}
                noOptionsText={customersErrorMessage}
                value={openAsset?.client || null}
              />
            </Grid>
          ) : (
            <PropertyField
              label={t("Customer")}
              value={
                !!openAsset?.client
                  ? openAsset?.client?.company || t("Unknown")
                  : t("Not assigned to a client")
              }
              xs={12}
              sm={hasId ? 6 : undefined}
            />
          ))}
        {hasId &&
          (isEditing ? (
            <Grid item xs={12} sm={hasId ? 6 : undefined}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                clearable
                format="dd-MM-yyyy"
                id="date-picker-inline"
                label={t("Schedule")}
                disablePast={true}
                value={openAsset?.scheduled}
                onChange={(date: MaterialUiPickersDate) => {
                  date?.setHours(12);
                  date?.setMinutes(0);
                  date?.setSeconds(0);
                  dispatch(updateOpenAsset({ scheduled: date?.toISOString() }));
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    className={classes.textField}
                    variant="filled"
                  />
                )}
              />
            </Grid>
          ) : (
            <PropertyField
              label={t("Scheduled")}
              value={
                openAsset?.scheduled
                  ? format(new Date(openAsset?.scheduled!), "dd-MM-yyyy")
                  : ""
              }
              xs={12}
              sm={hasId ? 6 : undefined}
            />
          ))}

        {!!isEditing ? (
          <Grid item xs={12} sm={hasId ? 6 : undefined}>
            <Autocomplete
              multiple
              options={assetProfiles}
              disableCloseOnSelect
              getOptionLabel={(option: AssetProfileDefinition) => {
                return !!option.name ? t(option.name) : t("Unknown");
              }}
              loading={isAssetProfilesLoading}
              getOptionSelected={(option, value) => {
                return option["@id"] === value["@id"];
              }}
              renderOption={(option, { selected }) => (
                <>{!!option.name ? t(option.name) : t("Unknown")}</>
              )}
              fullWidth
              loadingText={`${t("Loading")}...`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("Asset profiles")}`}
                  fullWidth
                  variant="filled"
                  className={classes.textField}
                />
              )}
              ChipProps={{
                size: "small",
              }}
              onChange={(_, value) => {
                dispatch(updateOpenAsset({ asset_profile_definitions: value }));
              }}
              noOptionsText={customersErrorMessage}
              value={openAsset?.asset_profile_definitions || []}
            />
          </Grid>
        ) : (
          <PropertyField
            label={t("Asset profiles")}
            value={openAsset?.asset_profile_definitions
              ?.map((d) => t(d?.name || ""))
              .join(", ")}
            xs={12}
            sm={hasId ? 6 : undefined}
          />
        )}
        <Grid item xs={12} sm={hasId ? 6 : undefined}></Grid>

        {openAsset?.days_outside_geo_zone !== undefined && (
          <Grid item xs={12} sm={6}>
            <Grid container>
              <PropertyField
                label={t("Days outside geo zone")}
                value={openAsset.days_outside_geo_zone}
                xs={12}
                sm={12}
              />
              {!isClient && !!openAsset?.days_outside_geo_zone && (
                <Grid item xs={12}>
                  <Box mt={2}>
                    <ConfirmButton
                      title={t("reset_loan_days_message")}
                      okText={t("Yes")}
                      color="secondary"
                      cancelText={t("No")}
                      buttonText={t("RESET_LOAN_DAYS")}
                      variant="contained"
                      onClick={() =>
                        dispatch(
                          resetAssetDaysOutsideGeozone(openAsset as Asset)
                        )
                      }
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {openAsset?.status && (
          <Grid item xs={12} sm={6}>
            <PropertyField
              label={t("Status")}
              value={<AssetStatusComponent assetStatus={openAsset.status} />}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {openAsset?.client !== undefined &&
            openAsset?.client !== null &&
            !isEditing && (
              <>
                {canReportDelivered && (
                  <ReportButton
                    displayButton={
                      canReportDelivered &&
                      openAsset.status?.status !== "send_deregistered" &&
                      openAsset.status?.status !== "send_delivered" &&
                      openAsset.status?.status !== "deregistered" &&
                      openAsset.status?.status !== "delivered"
                    }
                    className={classes.deliveredButton}
                    buttonText={t("Report delivered buck")}
                    readyText={t("Asset is delivered")}
                    onClick={() => {
                      batch(() => {
                        dispatch(deliveredAsset(openAsset as Asset));
                        dispatch(
                          setOpenAsset({
                            ...openAsset,
                            ...{ status: { status: "send_delivered" } },
                          })
                        );
                      });
                    }}
                  />
                )}
                {canReportEmpty && (
                  <ReportButton
                    displayButton={
                      canReportEmpty &&
                      openAsset.status?.status !== "send_deregistered" &&
                      openAsset.status?.status !== "deregistered"
                    }
                    buttonText={t("Report empty buck")}
                    readyText={t("Asset is signed off")}
                    onClick={() => {
                      batch(() => {
                        dispatch(deregisterAsset(openAsset as Asset));
                        dispatch(
                          setOpenAsset({
                            ...openAsset,
                            ...{ status: { status: "send_deregistered" } },
                          })
                        );
                      });
                    }}
                  />
                )}
              </>
            )}
        </Grid>

        <Grid item xs={12} sm={12}>
          {openAsset?.asset_profile_relations?.map(
            (assetProfile: any, index: number) => (
              <AssetProfileDetails key={index} uri={assetProfile} />
            )
          )}
        </Grid>
      </Grid>
    </DialogContent>
  );
};
