import { AssetStatus } from "../Assets/Asset";
import { Customer } from "../Customers/Customer";
import { GeoZone } from "../GeoZone/GeoZone";

export type Type =
  | ""
  | "start"
  | "moving"
  | "still"
  | "stopped"
  | "intervalBased"
  | "intervalBasedLocalGateway";

export const MOVEMENT_TYPES: { value: Type; label: string }[] = [
  { value: "", label: "All" },
  { value: "start", label: "Start" },
  { value: "moving", label: "Moving" },
  { value: "still", label: "Still" },
  { value: "stopped", label: "Stopped" },
  { value: "intervalBased", label: "Interval-based" },
  { value: "intervalBasedLocalGateway", label: "Interval-based-Local-Gateway" },
];

export function translateMovementType(movementType: Event["type"] | undefined) {
  return movementType
    ? MOVEMENT_TYPES.find((t) => t.value === movementType)!.label
    : "";
}

export type Event = {
  id: number;
  date: Date | string;
  street: string;
  postal_code: string;
  city: string;
  region: string;
  country: string;
  geo_zone?: GeoZone;
  type: Type;
  latitude: number;
  longitude: number;
  accuracy: number;
  inside_geozone?: string;
  client?: Customer;
  asset_status?: AssetStatus;
  radius?: number;
};
