import {
  IconButton, Theme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import React, { FunctionComponent, useState } from "react";


const useStyles = makeStyles((theme: Theme) => ({
  asc: {
    transform: "rotate(-180deg)",
  },
  desc: {
    transform: "rotate(0)",
  },
}));

export const OrderButton: FunctionComponent<{
    order?: "asc" | "desc";
    inversed?: boolean;
    onClick?: (order: string) => void;
  }> = ({ order = "asc", inversed, onClick }) => {
    const classes = useStyles();
    const [orderValue, setOrderValue] = useState(order);
  
    return (
      <IconButton
        component="span"
        disableFocusRipple
        disableRipple
        size="small"
        onClick={() => {
          let newOrderValue: "asc" | "desc" =
            orderValue === "asc" ? "desc" : "asc";
  
          setOrderValue(newOrderValue);
          onClick?.call(null, newOrderValue);
        }}
      >
        <FilterListIcon
          className={((orderValue === "asc" && !inversed) || (orderValue === "desc" && !!inversed)) ? classes.asc : classes.desc}
        />
      </IconButton>
    );
  };