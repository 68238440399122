import {
  Backdrop,
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AddRounded } from "@material-ui/icons";
import {
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon
} from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount, useToggle } from "react-use";
import AutoHeight from "../../../common/components/AutoHeight/AutoHeight";
import { TablePagination } from "../../../components/TablePagination/TablePagination";
import { ALARM_TYPES } from "../AlarmRule";
import {
  alarmRulesIsLoadingSelector,
  alarmRulesOpenAlarmRuleSelector,
  alarmRulesPageSelector,
  alarmRulesSelector,
  alarmRulesTotalItemsSelector,
  fetchAlarmRules,
  setOpenAlarmRule,
  setPage
} from "../alarmRulesSlice";

const useStyles = makeStyles((theme: Theme) => ({
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const COLUMN_COUNT = 4;

const Table = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alarmRules = useSelector(alarmRulesSelector);
  const isLoading = useSelector(alarmRulesIsLoadingSelector);
  const [isSpeedDialOpen, toggleSpeedDialOpen] = useToggle(false);
  const openUser = useSelector(alarmRulesOpenAlarmRuleSelector);
  const totalItems = useSelector(alarmRulesTotalItemsSelector);
  const page = useSelector(alarmRulesPageSelector);

  useMount(() => {
    dispatch(fetchAlarmRules());
  });

  const tablePagination = (
    <TablePagination count={totalItems} page={page} setPage={setPage} />
  );

  return (
    <Paper square>
      {isLoading && !!alarmRules.length && (
        <LinearProgress style={{ marginBottom: -4 }} />
      )}
      <AutoHeight autoHeightIndex={isMobile ? 0 : 1}>
        <Box overflow={"hidden"}>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} sm={"auto"}>
              {tablePagination}
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={"auto"}>
                <Box p={1} display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    variant={"contained"}
                    disableElevation
                    startIcon={<AddRounded />}
                    color={"primary"}
                    onClick={() => dispatch(setOpenAlarmRule({}))}
                  >
                    {t("Add new")}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <TableContainer>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("ID")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Name")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Type")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Email")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alarmRules.map((alarmRule) => (
                <TableRow
                  key={alarmRule.id}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={() => dispatch(setOpenAlarmRule(alarmRule))}
                  selected={openUser && alarmRule.id === openUser.id}
                >
                  <TableCell>{alarmRule.id}</TableCell>
                  <TableCell>{alarmRule.name}</TableCell>
                  <TableCell>{t(ALARM_TYPES[alarmRule.type])}</TableCell>
                  <TableCell>{alarmRule.email}</TableCell>
                </TableRow>
              ))}
              {!isLoading && !alarmRules.length && (
                <TableRow>
                  <TableCell colSpan={COLUMN_COUNT}>
                    {t("No result found")}
                  </TableCell>
                </TableRow>
              )}
              {isLoading &&
                [...Array(5)].map((_, i) => (
                  <TableRow key={i}>
                    {[...Array(COLUMN_COUNT)].map((_, j) => (
                      <TableCell key={j}>
                        <Skeleton variant={"text"} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {isMobile && <tr style={{ height: 88 }} />}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </AutoHeight>

      {isMobile && (
        <>
          <Backdrop
            open={isSpeedDialOpen}
            style={{
              zIndex: theme.zIndex.drawer + 1,
            }}
            onClick={() => toggleSpeedDialOpen(false)}
          />
          <SpeedDial
            ariaLabel=""
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={() => toggleSpeedDialOpen(false)}
            onOpen={() => toggleSpeedDialOpen(true)}
            open={isSpeedDialOpen}
            direction={"up"}
          >
            {[
              <SpeedDialAction
                key={0}
                icon={<AddRounded />}
                title={""}
                tooltipTitle={<Typography noWrap>{t("Add new")}</Typography>}
                tooltipOpen
                onClick={() => dispatch(setOpenAlarmRule({}))}
              />,
            ]}
          </SpeedDial>
        </>
      )}
    </Paper>
  );
};

export default Table;
