import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { AssetStatus } from "./Asset";
import { setOpenAsset } from "./openAssetSlice";

interface AssetStatusState {
  isLoading: boolean;
  statuses: AssetStatus[];
  search: string;
}

const initialState: AssetStatusState = {
  isLoading: false,
  statuses: [],
  search: "",
};

export const assetStatusSlice = createSlice({
  name: "assetStatus",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<AssetStatusState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setStatuses(state, action: PayloadAction<AssetStatusState["statuses"]>) {
      state.statuses = action.payload;
    },
    setSearch(state, action: PayloadAction<AssetStatusState["search"]>) {
      state.search = action.payload;
    },
  },
});

export const assetStatusReducer = assetStatusSlice.reducer;

const { setStatuses, setLoading } = assetStatusSlice.actions;
export { setLoading };

export const fetchAssetStatuses = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.get<{
      "hydra:totalItems": number;
      "hydra:member": AssetStatus[];
    }>(`/api/asset-statuses`, {
      params: {
        search: getState().assetStatuses.search,
      },
    });
    const statuses = response.data["hydra:member"];
    batch(() => {
      dispatch(setStatuses(statuses));
      dispatch(setLoading(false));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const addAssetStatus = (
  status: Partial<AssetStatus>
): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    await Axios.post<AssetStatus>(`/api/asset-statuses`, {
      ...status,
    });
    batch(() => {
      dispatch(fetchAssetStatuses());
      dispatch(
        enqueueSnackbar({
          message: "Asset status added",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const updateAssetStatus = (status: AssetStatus): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.put(`/api/asset-statuses/${status.id}`, {
      ...status,
    });
    batch(() => {
      dispatch(fetchAssetStatuses());
      dispatch(
        enqueueSnackbar({
          message: "Asset status updated",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const removeAssetStatus = (status: AssetStatus): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.delete(`/api/asset-statuses/${status.id}`);
    batch(() => {
      dispatch(fetchAssetStatuses());
      dispatch(setOpenAsset(undefined));
      dispatch(
        enqueueSnackbar({
          message: "Asset status deleted",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const assetsStatusSelector = (state: RootState) =>
  state.assetStatuses.statuses;
export const assetsIsLoadingSelector = (state: RootState) =>
  state.assetStatuses.isLoading;
