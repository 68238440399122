import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useDeepCompareEffect, useWindowSize } from "react-use";

const AutoHeight: FunctionComponent<{
  autoHeightIndex?: number;
  disable?: boolean;
}> = ({ children, autoHeightIndex, disable }) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const resize = useWindowSize();

  const checkHeightChange = () => {
    if (divRef.current) {
      let height: number | undefined = undefined;
      if (!disable) {
        height = window.innerHeight;
        height = height - divRef.current.getBoundingClientRect().top;
        if (typeof autoHeightIndex !== "undefined") {
          for (let i = 0; i < divRef.current.children.length; i++) {
            const child = divRef.current.children[i] as HTMLElement;
            if (i !== autoHeightIndex) {
              height = height - child.getBoundingClientRect().height;
            }
          }
        }
      }
      const element = divRef.current.children[
        autoHeightIndex || 0
      ] as HTMLElement;
      element.style.height = height ? `${height}px` : "";
    }
  };

  // check height every second until the component unmount
  useEffect(() => {
    const interval = setInterval(checkHeightChange, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check height every 100ms for 1 second then stop
  useLayoutEffect(() => {
    const interval = setInterval(checkHeightChange, 100);
    setTimeout(() => clearInterval(interval), 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // updates the element height when the window is resized or "wasUpdated" is triggered
  useDeepCompareEffect(() => {
    checkHeightChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resize, disable, autoHeightIndex]);

  return <div ref={divRef}>{children}</div>;
};

export default AutoHeight;
