const en = {
  translation: {
    "Username or e-mail": "Username or e-mail",
    "Password": "Password",
    "Stay logged in": "Stay logged in",
    "Forgot your password?": "Forgot your password?",
    "Sign in": "Sign in",
    "Unknown": "Unknown",
    "English": "English",
    "Dutch": "Dutch",
    "German": "German",
    "French": "French",
    "is required": "is required",
    "Home": "Home",
    "logo": "logo",
    "Sign out": "Sign out",
    "Powered by": "Powered by",
    "Under construction": "Under construction",
    "Rows per page": "Rows per page",
    "Assets": "Assets",
    "Asset map": "Asset map",
    "of": "of",
    "more than": "more than",
    "ID": "ID",
    "Name": "Name",
    "Street": "Street",
    "Postal code": "Postal code",
    "City": "City",
    "Region": "Region",
    "Country": "Country",
    "Days still": "Days still",
    "Last communication": "Last communication",
    "Last location update": "Last location update",
    "Device last communication": "Last communication",
    "Geo zone": "Geo zone",
    "Client": "Customer",
    "Asset group": "Asset group",
    "Description": "Description",
    "Days outside geo zone": "Loan days",
    "No problems": "No problems",
    "User not found or wrong password": "User not found or wrong password",
    "Address": "Address",
    "Asset": "Asset",
    "Last page": "Last page",
    "Next page": "Next page",
    "Previous page": "Previous page",
    "First page": "First page",
    "Search": "Search",
    "Advanced filter": "Advanced filter",
    "New": "New",
    "Events": "Location updates",
    "Devices": "Devices",
    "Add new": "Add new",
    "Delete": "Delete",
    "Link to Group": "Link to Group",
    "Link to Client": "Link to Client",
    "Selected": "Selected",
    "There was an error while trying to fetch the events from the asset":
      "There was an error while trying to fetch the events from the asset",
    "Not in a zone": "Not in a zone",
    "Interval-based": "Interval-based",
    "Clients": "Customers",
    "Asset groups": "Asset groups",
    "Type": "Type",
    "External ID": "External ID",
    "Empty": "Empty",
    "Device activation date": "Device activation date",
    "Model": "Model",
    "DEVEUI": "DEVEUI",
    "Barcode": "Barcode",
    "Notes": "Notes",
    "Device": "Device",
    "Page not found": "Page not found",
    "From": "From",
    "Invalid date format": "Invalid date format",
    "To": "To",
    "Asset updated": "Asset updated",
    "Asset deleted": "Asset deleted",
    "Loading": "Loading",
    "Try again": "Try again",
    "All": "All",
    "Start": "Start",
    "Moving": "Moving",
    "Still": "Still",
    "Stopped": "Stopped",
    "Interval-based-Local-Gateway": "Interval-based-Local-Gateway",
    "Users": "Users",
    "ROLE_USER": "User",
    "ROLE_ADMIN": "Admin",
    "ROLE_CLIENT": "Customer",
    "ROLE_TRANSPORTER": "Transporter",
    "ROLE_ACCOUNT_MANAGER": "Account Manager",
    "User": "User",
    "Username": "Username",
    "Role": "Role",
    "critical": "Critical",
    "major": "Major",
    "minor": "Minor",
    "warning": "Warning",
    "none": "None",
    "no items": "No Items",
    "add new item": "Add new item",
    "The file will be uploaded when you save the new asset":
      "The file will be uploaded when you save the new asset",
    "There was an error while trying to retrieve the attachment":
      "There was an error while trying to retrieve the attachment",
    "There was an error while trying to upload the attachment":
      "There was an error while trying to upload the attachment",
    "Drag and drop files here": "Drag and drop files here",
    "or": "or",
    "Browse files": "Browse files",
    "Release the file to attach it": "Release the file to attach it",
    "User added": "User added",
    "Status": "Status",
    "There was an error while trying to fetch the files credentials":
      "There was an error while trying to fetch the files credentials",
    "Asset not found": "Asset not found",
    "Save": "Save",
    "Save asset profile": "Are you sure you want to save the changes?",
    "Import devices": "Import devices",
    "Alarm rules": "Alarm rules",
    "Alarm rule added": "Alarm rule added",
    "Email": "Email",
    "Asset tag is sabotaged": "Asset tag is sabotaged",
    "Asset is reported as missing": "Asset is reported as missing",
    "Asset is outside the Geo Zone": "Asset is outside the Geo Zone",
    "Asset has battery level low": "Asset has battery level low",
    "Alarm rule": "Alarm rule",
    "The name is required": "The name is required",
    "The email needs to be valid": "The email needs to be valid",
    "Sabotage has been detected with": "Sabotage has been detected with",
    "Low battery has been detected for": "Low battery has been detected for",
    "has been detected outside geozone": "has been detected outside geozone",
    "Text": "Text",
    "Alarm rule updated": "Alarm rule updated",
    "Sensor name": "Sensor name",
    "Change": "Change",
    "Active": "Active",
    "Roles": "Roles",
    "Change my password": "Change my password",
    "No result found": "No result found",
    "Edit": "Edit",
    "User deleted": "User deleted",
    "Filter": "Filter",
    "Must be a number": "Must be a number",
    "Must be a valid email": "Must be a valid email",
    "Are you sure you want to delete": "Are you sure you want to delete",
    "Cancel": "Cancel",
    "Fill all the required fields": "Fill all the required fields",
    "Devices imported": "Devices imported",
    "Select one type": "Select one type",
    "The text is required": "The text is required",
    "Device deleted": "Device deleted",
    "Device updated": "Device updated",
    "You don't have any assets to show on the map":
      "You don't have any assets to show on the map",
    "Drone no-fly areas": "Drone no-fly areas",
    "Fine dust": "Fine dust",
    "Foundation problems": "Foundation problems",
    "Height map": "Height map",
    "Electricity pylons": "Electricity pylons",
    "Quality of life card": "Quality of life card",
    "Premises": "Premises",
    "Parcels": "Parcels",
    "Map layers": "Map layers",
    "Company": "Company",
    "Logical ID": "Logical ID",
    "Reference card": "Reference card",
    "Map": "Map",
    "Satelite": "Satellite",
    "Satelite with Labels": "Satellite with Labels",
    "Clear": "Clear",
    "Asset is sabotaged": "Asset is sabotaged",
    "Create": "Create",
    "Switch to tenant {{name}}": "Switch to tenant {{name}}",
    "#": "#",
    "Subdomain": "Subdomain",
    "Zip code": "Zip code",
    "Phone": "Phone",
    "Tenants": "Tenants",
    "Search for an ID": "Search for an ID",
    "Create tenant": "Create tenant",
    "Yes": "Yes",
    "No": "No",
    "Are you sure you want to delete?": "Are you sure you want to delete?",
    "Edit tenant": "Edit tenant",
    "Drag and drop the icon here or click here to select the icon":
      "Drag and drop the icon here or click here to select the icon",
    "Tenant created": "Tenant created",
    "Tenant updated": "Tenant updated",
    "Tenant deleted": "Tenant deleted",
    "Tenant not found": "Tenant not found",
    "Create user": "Create user",
    "Preferences": "Preferences",
    "Employee number": "Employee number",
    "Full name": "Full name",
    "New password": "New password",
    "Edit user": "Edit user",
    "Details": "Details",
    "Select role": "Select role",
    "Tenant roles": "Tenant roles",
    "Add role": "Add role",
    "Enter the role name": "Enter the role name",
    "Add new tenant": "Add new tenant",
    "Select a tenant": "Select a tenant",
    "Tenant": "Tenant",
    "User updated": "User updated",
    "Customers": "Customers",
    "Customer": "Customer",
    "New Customer": "New customer",
    "Not assigned to a client": "Not assigned to a customer",
    "GeoZone added": "GeoZone added",
    "Report empty buck": "Report empty buck",
    "Asset is signed off": "Asset is signed off",
    "Open": "Open",
    "": "",
    "Report delivered buck": "Delivered",
    "Asset is delivered": "Asset is delivered",
    "Delivered": "Delivered",
    "Schedule": "Schedule",
    "Scheduled": "Scheduled",
    "Date": "Date",
    "Reset password": "Reset password",
    "An email is send to {{email}}": "An email is send to {{email}}",
    "Back to login": "Go back to the login page",
    "Reference": "Reference",
    "ASSET_CREATE": "Create assets",
    "ASSET_READ": "Read assets",
    "ASSET_EDIT": "Edit assets",
    "ASSET_DELETE": "Delete assets",
    "ALARM_CREATE": "Create alarms",
    "ALARM_READ": "Read alarms",
    "ALARM_EDIT": "Edit alarms",
    "ALARM_DELETE": "Delete alarms",
    "DEVICE_CREATE": "Create devices",
    "DEVICE_READ": "Read devices",
    "DEVICE_EDIT": "Edit devices",
    "DEVICE_DELETE": "Delete devices",
    "RESET_LOAN_DAYS": "Reset loan days",
    "reset_loan_days_message": "Are you sure you want to reset the loan days?",
    "VIEW_ASSET_MAP": "View asset map",
    "USER_CREATE": "Create user",
    "USER_READ": "View user",
    "USER_EDIT": "Edit user",
    "USER_DELETE": "Delete user",
    "PERMISSION_CREATE": "Create roles",
    "PERMISSION_READ": "View roles",
    "PERMISSION_EDIT": "Edit roles",
    "PERMISSION_DELETE": "Delete roles",
    "CUSTOMER_CREATE": "Create customer",
    "CUSTOMER_READ": "View customer",
    "CUSTOMER_EDIT": "Edit customer",
    "CUSTOMER_DELETE": "Remove customer",
    "ASSET_PROFILE_READ": "View asset profile",
    "ASSET_PROFILE_EDIT": "Edit asset profile",
    "CABLE_REEL_RESET": "Reset cable length",
    "Permissions": "Roles and rights",
    "Battery status": "Battery",
    "Battery value low": "Battery value low",
    "Cable reel profile": "Cable reel profile",
    "cable_type": "Cable type",
    "cable_reel_type": "Reel type",
    "cable_length": "Cable length (m)",
    "cable_diameter": "Cable diameter (mm)",
    "cable_reel_width": "Winding width (mm)",
    "cable_reel_diameter_min": "Core diameter (mm)",
    "cable_reel_diameter_max": "Reel diameter (mm)",
    "cable_residual_length": "Cable residual length",
    "Device profiles": "Device profiles",
    "cable_length_reset_message": "Are you sure you want to reset the length?",
    "Account Manager": "Account Manager",
    "Not assigned to a account manager": "Not assigned to a account manager",
    "The client will receive an email with login credentials.": "The client will receive an email with login credentials.",
    "e.g.": "e.g.",
    "Asset profiles": "Asset profiles",
  },
};

export default en;
