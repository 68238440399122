export const MAP_LAYERS = [
  {
    label: "Drone no-fly areas",
    url: "https://geodata.nationaalgeoregister.nl/dronenoflyzones/wms",
    layers: "luchtvaartgebieden,landingsite",
  },
  {
    label: "Fine dust",
    url: "https://geodata.rivm.nl/geoserver/wms",
    layers: "lucht:actueel_no2",
  },
  {
    label: "Foundation problems",
    url:
      "https://geodata.nationaalgeoregister.nl/indgebfunderingsproblematiek/wms",
    layers: "indgebfunderingsproblematiek",
  },
  {
    label: "Height map",
    url: "https://geodata.nationaalgeoregister.nl/bodemkaart50000/wms",
    layers: "bodemkaart50000",
  },
  {
    label: "Electricity pylons",
    url: "https://geodata.rivm.nl/geoserver/wms?",
    layers: "vw_nl_netlijnen_2019_v3",
  },
  {
    label: "Quality of life card",
    url: "https://geo.leefbaarometer.nl/leefbaarometer/wms",
    layers: "schaalafhankelijke_leefbaarheidskaart",
  },
  {
    label: "Premises",
    url: "https://geodata.nationaalgeoregister.nl/bag/wms/v1_1",
    layers: "pand",
  },
  {
    label: "Parcels",
    url: "https://geodata.nationaalgeoregister.nl/kadastralekaart/wms/v4_0",
    layers: "perceel",
  },
];
