import {
  Badge,
  Box,
  Button,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link as MuiLink,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  BatteryAlert,
  BatteryFull,
  FiberManualRecord,
  FilterListRounded,
  SearchRounded,
} from "@material-ui/icons";
import AddRounded from "@material-ui/icons/AddRounded";
import { Skeleton } from "@material-ui/lab";
import Axios from "axios";
import clsx from "clsx";
import fileDownload from "js-file-download";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMount, useToggle } from "react-use";
import AutoHeight from "../../../common/components/AutoHeight/AutoHeight";
import DebounceOnChange from "../../../common/components/DebounceOnChange/DebounceOnChange";
import ExportMenu from "../../../components/Menu/ExportMenu";
import { TableCellWithOrdering } from "../../../components/TableCellWithOrdering/TableCellWithOrdering";
import { TablePagination } from "../../../components/TablePagination/TablePagination";
import { SecureComponent } from "../../../providers/SecurityProvider";
import { base64toBlob } from "../../../utils/base64ToBlob";
import { format } from "../../../utils/dateFnsInternationalization";
import { authUserSelector } from "../../Auth/authSlice";
import { UserRole } from "../../User/User";
import {
  devicesFilterSelector,
  devicesIsLoadingSelector,
  devicesOpenDeviceSelector,
  devicesPageSelector,
  devicesSearchSelector,
  devicesSelector,
  devicesTotalItemsSelector,
  fetchDevices,
  setOpenDevice,
  setOrder,
  setPage,
  setSearch,
} from "../devicesSlice";
import { Filter } from "../Filter/Filter";

const COLUMN_COUNT = 8;

const useStyles = makeStyles(() => ({
  circle: {
    marginTop: -2,
    marginLeft: -3,
    marginRight: 4,
  },
  red: {
    color: red[500],
  },
  green: {
    color: green[500],
  },
}));

const Table = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const devices = useSelector(devicesSelector);
  const isLoading = useSelector(devicesIsLoadingSelector);
  const openDevice = useSelector(devicesOpenDeviceSelector);
  const user = useSelector(authUserSelector);
  const isAdmin = user?.roles.includes(UserRole.ROLE_ADMIN);
  const search = useSelector(devicesSearchSelector);
  const [isFilterOpen, toggleFilterOpen] = useToggle(false);
  const filter = useSelector(devicesFilterSelector);
  const totalItems = useSelector(devicesTotalItemsSelector);
  const page = useSelector(devicesPageSelector);

  useMount(() => {
    dispatch(fetchDevices());
  });

  const isFiltered = !!Object.keys(filter).find(
    (filterKey) => !!(filter as any)[filterKey]
  );
  const tablePagination = (
    <TablePagination count={totalItems} page={page} setPage={setPage} />
  );

  const handleExportPdf = () => {
    Axios.get("/api/devices/export/?type=pdf").then((response) => {
      if (response.data) {
        const blob = base64toBlob(response.data, "application/pdf");
        fileDownload(blob, "Devices." + new Date() + ".pdf");
      }
    });
  };

  const handleExportExcel = () => {
    Axios.get("/api/devices/export/?type=excel").then((response) => {
      if (response.data) {
        const blob = base64toBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        fileDownload(blob, "Devices." + new Date() + ".xlsx");
      }
    });
  };

  const handleExportCsv = () => {
    Axios.get("/api/devices/export/?type=csv").then((response) => {
      if (response.data) {
        const blob = base64toBlob(response.data, "text/csv");
        fileDownload(blob, "Devices." + new Date() + ".csv");
      }
    });
  };

  return (
    <Paper square>
      {isLoading && !!devices.length && (
        <LinearProgress style={{ marginBottom: -4 }} />
      )}
      <AutoHeight autoHeightIndex={1}>
        <Box overflow={"hidden"} className="no-print">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <DebounceOnChange
                component={FilledInput}
                placeholder={t("Search")}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchRounded />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={toggleFilterOpen}>
                      <Badge
                        color="secondary"
                        variant="dot"
                        invisible={!isFiltered}
                      >
                        <FilterListRounded />
                      </Badge>
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
                inputProps={{
                  style: {
                    paddingTop: 14,
                    paddingBottom: 14,
                  },
                }}
                style={{
                  borderRadius: 0,
                }}
                value={search}
                onChange={({ target: { value } }) => dispatch(setSearch(value))}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              style={{ paddingRight: 10 }}
            >
              <Grid item xs={12} sm={"auto"}>
                {tablePagination}
              </Grid>
              <SecureComponent roles={["ROLE_SUPER_ADMIN"]}>
                <Grid item xs={12} sm={"auto"}>
                  <Button
                    variant={"contained"}
                    disableElevation
                    startIcon={<AddRounded />}
                    color={"primary"}
                    onClick={() => dispatch(setOpenDevice({}))}
                  >
                    {t("Add new")}
                  </Button>
                </Grid>
              </SecureComponent>
              <Grid item xs={12} sm={"auto"}>
                <ExportMenu
                  onExportPdf={handleExportPdf}
                  onExportExcel={handleExportExcel}
                  onExportCsv={handleExportCsv}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <TableContainer>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("ID")}
                  </Typography>
                </TableCell>
                {/* {isAdmin && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("Logical ID")}
                    </Typography>
                  </TableCell>
                )} */}
                {/*{isAdmin && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("Company")}
                    </Typography>
                  </TableCell>
                )}*/}
                {/* {isAdmin && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("User")}
                    </Typography>
                  </TableCell>
                )} */}
                <TableCellWithOrdering
                  title={t("Asset")}
                  onOrder={(order: string) => {
                    dispatch(setOrder({ "asset.name": order }));
                  }}
                />
                <TableCellWithOrdering
                  title={t("Sensor name")}
                  onOrder={(order: string) => {
                    dispatch(setOrder({ "name": order }));
                  }}
                />
                <TableCellWithOrdering
                  title={t("Model")}
                  onOrder={(order: string) => {
                    dispatch(setOrder({ "model": order }));
                  }}
                />
                <TableCellWithOrdering
                  title={t("DEVEUI")}
                  onOrder={(order: string) => {
                    dispatch(setOrder({ "devEui": order }));
                  }}
                />
                <TableCellWithOrdering
                  title={t("Battery status")}
                  onOrder={(order: string) => {
                    dispatch(setOrder({ "batteryLevelLow": order }));
                  }}
                />
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Notes")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Last location update")}
                  </Typography>
                </TableCell>
                <TableCellWithOrdering
                  title={t("Device activation date")}
                  onOrder={(order: string) => {
                    dispatch(setOrder({ "activationDate": order }));
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map((device) => (
                <TableRow
                  key={device.id}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={() => dispatch(setOpenDevice(device))}
                  selected={openDevice && device.id === openDevice.id}
                >
                  {/* {isAdmin && <TableCell>{device.id}</TableCell>} */}
                  <TableCell>{device.logical_id}</TableCell>
                  {/*{isAdmin && <TableCell>{device.user?.company}</TableCell>}*/}
                  {/* {isAdmin && <TableCell>{device.user?.name}</TableCell>} */}
                  <TableCell>
                    <Box display={"flex"} alignItems={"center"}>
                      {device.is_sabotaged && (
                        <Tooltip title={t("Asset is sabotaged") as string}>
                          <FiberManualRecord
                            className={clsx(classes.circle, {
                              [classes.red]: true,
                            })}
                          />
                        </Tooltip>
                      )}
                      {device.asset &&
                        (isAdmin ? (
                          device.asset?.name
                        ) : (
                          <MuiLink
                            component={!isAdmin ? Link : "span"}
                            to={`/assets/${device.asset?.id}`}
                          >
                            {device.asset?.name}
                          </MuiLink>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell>{device.name}</TableCell>
                  <TableCell>{device.model}</TableCell>
                  <TableCell>{device.dev_eui}</TableCell>
                  <TableCell>
                    {device.battery_level_low ? (
                      <BatteryAlert
                        className={clsx(classes.red)}
                      ></BatteryAlert>
                    ) : (
                      <BatteryFull
                        className={clsx(classes.green)}
                      ></BatteryFull>
                    )}
                  </TableCell>
                  {/* <TableCell>{device.barcode}</TableCell> */}
                  <TableCell>{device.notes}</TableCell>
                  <TableCell>
                    {device.last_event?.date &&
                      format(new Date(device.last_event.date), "Pp")}
                  </TableCell>
                  <TableCell>
                    {device.activation_date &&
                      format(new Date(device.activation_date), "Pp")}
                  </TableCell>
                </TableRow>
              ))}
              {!isLoading && !devices.length && (
                <TableRow>
                  <TableCell colSpan={COLUMN_COUNT + (isAdmin ? 2 : 0)}>
                    {t("No result found")}
                  </TableCell>
                </TableRow>
              )}
              {isLoading &&
                !devices.length &&
                [...Array(5)].map((_, i) => (
                  <TableRow key={i}>
                    {[...Array(COLUMN_COUNT + (isAdmin ? 1 : 0))].map(
                      (_, j) => (
                        <TableCell key={j}>
                          <Skeleton variant={"text"} />
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              {isMobile && <tr style={{ height: 88 }} />}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </AutoHeight>

      <Filter open={isFilterOpen} onClose={toggleFilterOpen} />
    </Paper>
  );
};

export default Table;
