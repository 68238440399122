import {
  Badge,
  Box,
  Button,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  AddRounded,
  FilterListRounded,
  OpenInBrowserRounded,
  SearchRounded,
} from "@material-ui/icons";
import clearEmptyProperties from "../../utils/clearEmptyProperties";
import { Skeleton } from "@material-ui/lab";
import TablePaginationActions from "../../common/components/TablePaginationActions/TablePaginationActions";
import React, { useState } from "react";
import {
  isTenantsFilterOpenAtom,
  openTenantAtom,
  selectedTenantAtom,
  tenantsFilterAtom,
} from "./Tenants.state";
import { useQuery } from "react-query";
import { Tenant } from "./Tenant";
import { Filter } from "./Filter/Filter";
import { Details } from "./Details/Details";
import { useAtom } from "jotai";
import { StyledTableAutoHeightContainerGridItem } from "../../components/StyledTableAutoHeightContainerGridItem/StyledTableAutoHeightContainerGridItem";
import { TableCellHeadNoWrap } from "../../components/TableCellHeadNoWrap/TableCellHeadNoWrap";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import useTitle from "../../common/hooks/useTitle";
import { useUpdateAtom } from "jotai/utils";

const SKELETON_ROW_COUNT = 5;
const COLUMN_COUNT = 10;

export const Tenants = () => {
  const { t } = useTranslation();
  useTitle(t("Tenants"));

  let [page, setPage] = useState(0);
  let [filter, setFilter] = useAtom(tenantsFilterAtom);
  let [, setFilterOpen] = useAtom(isTenantsFilterOpenAtom);
  let { data, isLoading } = useQuery<{
    "hydra:member": any[];
    "hydra:totalItems": number;
  }>(
    ["tenants", page, filter],
    (key, page, filter) =>
      Axios.get("/api/tenants", {
        params: {
          page: page + 1,
          ...clearEmptyProperties({
            ...filter,
          }),
        },
      }).then((res) => res.data),
    { keepPreviousData: true }
  );
  let tenants = data?.["hydra:member"] || [];
  let totalItems = data?.["hydra:totalItems"] || 0;
  let setOpenDetails = useUpdateAtom(openTenantAtom);
  let setSelectedTenant = useUpdateAtom(selectedTenantAtom);

  return (
    <Box>
      {isLoading && <LinearProgress style={{ marginBottom: -4, zIndex: 3 }} />}
      <Grid container spacing={0}>
        <StyledTableAutoHeightContainerGridItem>
          <FilledInput
            placeholder={t("Search for an ID")}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <SearchRounded />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <Tooltip title={t("Advanced filter") as any} placement="left">
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setFilterOpen((status) => !status)}
                  >
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        JSON.stringify(clearEmptyProperties(filter)) ===
                        JSON.stringify({})
                      }
                    >
                      <FilterListRounded />
                    </Badge>
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            }
            fullWidth
            inputProps={{
              style: {
                paddingTop: 14,
                paddingBottom: 14,
              },
            }}
            value={filter.id || ""}
            onChange={({ target: { value } }) => setFilter({ id: value })}
          />
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCellHeadNoWrap label={"#"} />
                  <TableCellHeadNoWrap label={"Subdomain"} />
                  <TableCellHeadNoWrap label={"Company"} />
                  <TableCellHeadNoWrap label={"Address"} />
                  <TableCellHeadNoWrap label={"Zip code"} />
                  <TableCellHeadNoWrap label={"City"} />
                  <TableCellHeadNoWrap label={"Country"} />
                  <TableCellHeadNoWrap label={"Phone"} />
                  <TableCellHeadNoWrap label={"Email"} />
                  <TableCellHeadNoWrap />
                </TableRow>
              </TableHead>
              <TableBody>
                {tenants.map((tenant: Tenant) => (
                  <TableRow
                    key={tenant.id}
                    onClick={() => setOpenDetails(tenant)}
                    hover
                  >
                    <TableCell>{tenant.id}</TableCell>
                    <TableCell>{tenant.subdomain}</TableCell>
                    <TableCell>{tenant.company}</TableCell>
                    <TableCell>{tenant.address}</TableCell>
                    <TableCell>{tenant.zipcode}</TableCell>
                    <TableCell>{tenant.city}</TableCell>
                    <TableCell>{tenant.country}</TableCell>
                    <TableCell>{tenant.phone}</TableCell>
                    <TableCell>{tenant.email}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          t("Switch to tenant {{name}}", {
                            name: tenant.company,
                          }) as any
                        }
                      >
                        <IconButton
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            setSelectedTenant(tenant);
                          }}
                        >
                          <OpenInBrowserRounded />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                {isLoading &&
                  new Array(SKELETON_ROW_COUNT).fill({}).map((_, i) => (
                    <TableRow key={i}>
                      {new Array(COLUMN_COUNT).fill({}).map((_, i) => (
                        <TableCell
                          key={i}
                          component={i === 0 ? "th" : undefined}
                          scope={i === 0 ? "row" : undefined}
                        >
                          <Skeleton variant="text" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={totalItems || 0}
              rowsPerPage={30}
              page={page}
              onPageChange={(e, page1) => setPage(page1)}
              rowsPerPageOptions={[]}
              ActionsComponent={TablePaginationActions}
            />
          </TableContainer>
          <Box p={2} display="flex">
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              endIcon={<AddRounded />}
              disableElevation
              onClick={() => setOpenDetails({})}
            >
              {t("Create")}
            </Button>
          </Box>
        </StyledTableAutoHeightContainerGridItem>
      </Grid>

      <Filter />
      <Details />
    </Box>
  );
};
