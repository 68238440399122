import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authIsAuthenticatedSelector, authUserSelector } from "../domains/Auth/authSlice";
import { roleStore } from "../domains/Role/RoleState";
import { Permission, Role, SecurityProvider } from "./SecurityProvider";


const KonneksieProvider: FunctionComponent = ({children}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authIsAuthenticatedSelector);
  const user = useSelector(authUserSelector);

  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [roles, setRoles] = useState<Permission[]>([]);

  useEffect(() => {
    if(!!isAuthenticated) {
      dispatch(roleStore.fetch());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if(!!user?.permissions) {
      const result: Permission[] = [];

      user?.permissions?.forEach((permission: string) => {
        result.push({name: permission});
      });

      setPermissions(result);
    }

    if(!!user?.roles) {
      const roles: Role[] = [];

      user?.roles?.forEach((role: string) => {
        roles.push({name: role});
      });

      setRoles(roles);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <SecurityProvider permissions={permissions} roles={roles}>
        {children}
      </SecurityProvider>
    </>
  );
}


export default KonneksieProvider;