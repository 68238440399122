import Axios from "axios";
import { Api } from "../../store/Api";
import { Asset } from "./Asset";


class AssetApi extends Api<Asset, Asset> {
  public constructor() {
    super("/api/assets");
  }

  public addProfiles = (entity: Asset): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post<Asset>(`${this.endpoint}/${entity.id}/asset-profiles`, entity.asset_profile_definitions);

        console.log("resposne: ", response);
        resolve(response?.data);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export const assetApi = new AssetApi();