import React, { FunctionComponent, ReactElement, ReactNode } from "react";
import clsx from "clsx";
import {
  AppBar as MuiAppBar,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import MenuButton from "../MenuButton/MenuButton";
import useTitle from "../../../hooks/useTitle";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // boxShadow: theme.shadows[0],
    transition: theme.transitions.create(
      ["width", "margin", "box-shadow", "background-color"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }
    ),
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(
      ["width", "margin", "box-shadow", "background-color"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }
    ),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  title: {
    fontSize: 17,
  },
  userMenuButton: {
    padding: 0,
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: 18,
  },
}));

const ElevationScroll: FunctionComponent<{ isMobile: boolean }> = ({
  children,
  isMobile,
}) => {
  const trigger = useScrollTrigger();
  const topTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const newChildren = React.cloneElement(children as ReactElement, {
    elevation: topTrigger ? 4 : 0,
  });

  return (
    <Slide appear={false} direction="down" in={!isMobile || !trigger}>
      {newChildren}
    </Slide>
  );
};

const AppBar: FunctionComponent<{
  open: boolean;
  logo?: ReactNode;
  onOpenDrawer?: () => void;
  appTitle?: string;
  showDrawer: boolean;
  className?: string;
}> = ({
  children,
  open,
  logo,
  onOpenDrawer,
  appTitle,
  showDrawer,
  className,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const classes = useStyles();
  const title = useTitle();

  return (
    <ElevationScroll isMobile={isMobile}>
      <MuiAppBar
        id="app-bar"
        position="fixed"
        className={clsx(classes.appBar, className, {
          [classes.appBarShift]: showDrawer && !isMobile && open,
        })}
      >
        <Toolbar variant={!isMobile ? "dense" : undefined}>
          <MenuButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            className={classes.menuButton}
            isMobile={isMobile}
            open={open}
            onClick={onOpenDrawer}
            location="app-bar"
            show={showDrawer}
          />

          {logo}

          {(title !== appTitle || !isMobile) && (
            <Typography variant="h6" noWrap className={classes.title}>
              {title}
            </Typography>
          )}

          {children}
        </Toolbar>
      </MuiAppBar>
    </ElevationScroll>
  );
};

export default AppBar;
