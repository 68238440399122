import {
  Button,
  LinearProgress,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import Menu from "../../../../common/components/Menu/Menu";
import React from "react";
import { queryCache, useMutation } from "react-query";
import { openTenantAtom } from "../../Tenants.state";
import { useSnackbar } from "notistack";
import Axios, { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

export const DeleteButton = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openTenant, setOpenTenant] = useAtom(openTenantAtom);

  const [deleteTenant, { isLoading }] = useMutation<{}, AxiosError>(
    () => Axios.delete(`/api/tenants/${openTenant!.id}`),
    {
      onSuccess() {
        enqueueSnackbar(t("Tenant deleted"));
        void queryCache.invalidateQueries("tenants");
        setOpenTenant(null);
      },
      onError(error) {
        if (error.response?.status === 404) {
          enqueueSnackbar(t("Tenant not found"));
          void queryCache.invalidateQueries("tenants");
          setOpenTenant(null);
        } else {
          enqueueSnackbar(error.response?.data.message || error.message);
        }
      },
    }
  );

  return (
    <Menu
      open={isLoading ? true : undefined}
      MenuListProps={{
        subheader: (
          <ListSubheader component="div">
            {isLoading && (
              <LinearProgress
                style={{
                  marginBottom: -4,
                  marginLeft: -16,
                  marginRight: -16,
                  zIndex: 3,
                }}
              />
            )}
            {t("Are you sure you want to delete?")}
          </ListSubheader>
        ),
      }}
      items={(closeMenu) => [
        <MenuItem disabled={isLoading} onClick={() => deleteTenant()}>
          {t("Yes")}
        </MenuItem>,
        <MenuItem disabled={isLoading} onClick={closeMenu}>
          {t("No")}
        </MenuItem>,
      ]}
    >
      <Button startIcon={<DeleteRounded />}>{t("Delete")}</Button>
    </Menu>
  );
};
