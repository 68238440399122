import { Button, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { SecureComponent } from "../../../providers/SecurityProvider";
import { AssetProfileCableReelCable } from "../assetProfile";


const CableDetails: FunctionComponent<{
  cable?: AssetProfileCableReelCable;
  onSave: (profile: AssetProfileCableReelCable) => void;
  onClose: () => void;
}> = ({cable, onSave, onClose}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const submitButtonRef = useRef<HTMLButtonElement>(null);


  return (
    <SidePanelModal
      title={t("cable_type")}
      open={!!cable}
      onClose={onClose}
      width={theme.spacing(75)}
    >
      <DialogContent>
        <Formik
          initialValues={{
            id: uuid().toString(),
            name: "",
            description: "",
            diameter: 0,
            ...cable,
          }}
          validationSchema={Yup.object<Partial<AssetProfileCableReelCable>>({
            name: Yup.string().required(t("The name is required")),
            description: Yup.string().required(t("The name is required")),
            diameter: Yup.number().required(t("Width is required")),
          })}
          onSubmit={(values) => {
            onSave(values);
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="name"
                    label={t("Article")}
                    variant="filled"
                    fullWidth
                    disabled={false}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="description"
                    label={t("Description")}
                    variant="filled"
                    fullWidth
                    disabled={false}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="diameter"
                    label={t("cable_diameter")}
                    variant="filled"
                    fullWidth
                    disabled={false}
                    required
                    type="number"
                  />
                </Grid>
              </Grid>
              <button
                ref={submitButtonRef}
                type="submit"
                style={{ border: 0, padding: 0 }}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <SecureComponent permissions={["ASSET_PROFILE_EDIT"]}>
          <Button
            onClick={() => {
              console.log("trigger");
              console.log(submitButtonRef.current);
              submitButtonRef.current?.click();
            }}
            color="primary"
            variant={"contained"}
            disableElevation
          >
            {t("Save")}
          </Button>
        </SecureComponent>
      </DialogActions>
    </SidePanelModal>
  );
};

export default CableDetails;
