import {
  Backdrop,
  Badge,
  Box,
  Button,
  Checkbox,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AddRounded,
  BatteryAlert,
  DeleteRounded,
  FiberManualRecord,
  FilterListRounded,
  SearchRounded,
} from "@material-ui/icons";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Axios from "axios";
import clsx from "clsx";
import fileDownload from "js-file-download";
import { useSnackbar } from "notistack";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useMount, useToggle } from "react-use";
import AutoHeight from "../../../common/components/AutoHeight/AutoHeight";
import DateFnsDistanceNow from "../../../common/components/DateFnsDistanceNow/DateFnsDistanceNow";
import DebounceOnChange from "../../../common/components/DebounceOnChange/DebounceOnChange";
import ExportMenu from "../../../components/Menu/ExportMenu";
import { TablePagination } from "../../../components/TablePagination/TablePagination";
import { SecureComponent } from "../../../providers/SecurityProvider";
import { base64toBlob } from "../../../utils/base64ToBlob";
import { format, getLocale } from "../../../utils/dateFnsInternationalization";
import { authUserSelector } from "../../Auth/authSlice";
import { frontendSettingsApi } from "../../FrontendSettings/FrontendSettingsApi";
import { UserRole } from "../../User/User";
import { Asset } from "../Asset";
import {
  assetsFilterSelector,
  assetsIsLoadingSelector,
  assetsPageSelector,
  assetsSearchSelector,
  assetsSelectedAssetsSelector,
  assetsSelector,
  assetsTotalItemsSelector,
  removeAsset,
  setOrder,
  setPage,
  setSearch,
  toggleSelectedAsset,
  updateAsset,
} from "../assetsSlice";
import AssetStatusComponent from "../AssetStatus";
import { Filter } from "../Filter/Filter";
import {
  fetchAndOpenAsset,
  openAssetSelector,
  setOpenAsset,
} from "../openAssetSlice";
import { AssetProfileColumn } from "./AssetProfileColumn";

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    marginTop: -2,
    marginLeft: -3,
    marginRight: 4,
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  tableContainer: {
    "@media print": {
      height: "auto !important",
      "& *": {
        color: "black",
      },
      "& td, & th": {
        padding: 0,
        paddingRight: 4,
      },
      "& span": {
        whiteSpace: "normal",
      },
    },
  },
  asc: {
    transform: "rotate(-180deg)",
  },
  desc: {
    transform: "rotate(0)",
  },
  datePickerRoot: {
    "&::before": {
      border: "none",
    },
    "&::after": {},
  },
  dateTimeTextField: {
    minWidth: theme.spacing(10),
    maxWidth: theme.spacing(13),
  },
}));

const COLUMN_COUNT = 12;

const OrderIconButton: FunctionComponent<{
  order?: "asc" | "desc";
  inversed?: boolean;
  onClick?: (order: string) => void;
}> = ({ order = "asc", inversed, onClick }) => {
  const classes = useStyles();
  const [orderValue, setOrderValue] = useState(order);

  return (
    <IconButton
      component="span"
      disableFocusRipple
      disableRipple
      size="small"
      onClick={() => {
        let newOrderValue: "asc" | "desc" =
          orderValue === "asc" ? "desc" : "asc";

        setOrderValue(newOrderValue);
        onClick?.call(null, newOrderValue);
      }}
    >
      <FilterListIcon
        className={
          (orderValue === "asc" && !inversed) ||
          (orderValue === "desc" && !!inversed)
            ? classes.asc
            : classes.desc
        }
      />
    </IconButton>
  );
};

const TableHeaderCell: FunctionComponent<{
  title: string;
  inversed?: boolean;
  onOrder?: (order: string) => void;
}> = ({ title, inversed, onOrder }) => {
  return (
    <TableCell>
      <Typography variant={"inherit"} noWrap>
        {title}
        {!!onOrder ? (
          <OrderIconButton inversed={inversed} onClick={onOrder} />
        ) : (
          <div>&nbsp;</div>
        )}
      </Typography>
    </TableCell>
  );
};

const Table = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnNames, setColumnNames] = useState<any>({});

  let assets = useSelector(assetsSelector);
  const selectedAssets = useSelector(assetsSelectedAssetsSelector);
  const openAsset = useSelector(openAssetSelector);
  const user = useSelector(authUserSelector);
  const isClient = user?.roles.includes(UserRole.ROLE_CLIENT);
  const isLoading = useSelector(assetsIsLoadingSelector);
  const [isSpeedDialOpen, toggleSpeedDialOpen] = useToggle(false);
  const search = useSelector(assetsSearchSelector);
  const [isFilterOpen, toggleFilterOpen] = useToggle(false);
  const filter = useSelector(assetsFilterSelector);
  const totalItems = useSelector(assetsTotalItemsSelector);
  const page = useSelector(assetsPageSelector);

  const params = useParams<{ id: string }>();

  useMount(() => {
    dispatch(setOrder({ days_outside_geo_zone: "desc" }));
    frontendSettingsApi
      .fetch({ name: "asset-table-hidden-columns" })
      .then((res) => {
        if (res["hydra:member"].length > 0) {
          setHiddenColumns(res["hydra:member"][0]?.data || []);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    // fetch column names
    frontendSettingsApi
      .fetch({ name: "asset-table-column-names" })
      .then((res) => {
        if (res["hydra:member"].length > 0) {
          setColumnNames(res["hydra:member"][0]?.data || []);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  });

  useEffect(() => {
    if (!!params?.id) {
      try {
        const assetId = parseInt(params.id);
        dispatch(fetchAndOpenAsset(assetId));
      } catch (err) {
        // do nothing
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const toggleSelectEntirePage = () => {
    const selected = assets.filter((asset) =>
      selectedAssets.includes(asset.id)
    );
    const unselected = assets.filter(
      (asset) => !selectedAssets.includes(asset.id)
    );
    if (unselected.length === 0) {
      enqueueSnackbar(
        `Unselected all the ${assets.length} assets on the current page`
      );
      selected!.forEach((asset) => dispatch(toggleSelectedAsset(asset)));
    } else {
      enqueueSnackbar(
        `Selected all the ${assets.length} assets on the current page`
      );
      unselected!.forEach((asset) => dispatch(toggleSelectedAsset(asset)));
    }
  };

  const removeAssetBulk = async (assets: Asset[]) => {
    for (const asset of assets) {
      await dispatch(removeAsset(asset));
      const selected = assets.filter((asset) =>
        selectedAssets.includes(asset.id)
      );
      const unselected = assets.filter(
        (asset) => !selectedAssets.includes(asset.id)
      );
      if (unselected.length === 0) {
        selected!.forEach((asset) => dispatch(toggleSelectedAsset(asset)));
      }
    }
  };
  const handleAssetClick = (asset: Asset) => {
    dispatch(setOpenAsset(asset));
  };

  const handleScheduledTimeChange = (
    asset: Asset,
    date: MaterialUiPickersDate
  ) => {
    date?.setHours(12);
    date?.setMinutes(0);
    date?.setSeconds(0);
    const dateValue = date ? date?.toISOString() : null;
    dispatch(
      updateAsset({
        ...asset,
        scheduled: dateValue,
      })
    );
  };

  const selectedCountFromCurrentPage = assets.filter((asset) =>
    selectedAssets.includes(asset.id)
  ).length;
  const tablePagination = (
    <TablePagination count={totalItems} page={page} setPage={setPage} />
  );
  const isFiltered = !!Object.keys(filter).find(
    (filterKey) => !!(filter as any)[filterKey]
  );

  const handleExportPdf = () => {
    Axios.get("/api/assets/export/?type=pdf").then((response) => {
      if (response.data) {
        const blob = base64toBlob(response.data, "application/pdf");
        fileDownload(blob, "Assets." + new Date() + ".pdf");
      }
    });
  };

  const handleExportExcel = () => {
    Axios.get("/api/assets/export/?type=excel").then((response) => {
      if (response.data) {
        const blob = base64toBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        fileDownload(blob, "Assets." + new Date() + ".xlsx");
      }
    });
  };

  const handleExportCsv = () => {
    Axios.get("/api/assets/export/?type=csv").then((response) => {
      if (response.data) {
        const blob = base64toBlob(response.data, "text/csv");
        fileDownload(blob, "Assets." + new Date() + ".csv");
      }
    });
  };

  return (
    <Paper square>
      {isLoading && !!assets.length && (
        <LinearProgress style={{ marginBottom: -4 }} />
      )}
      <AutoHeight autoHeightIndex={2}>
        <DebounceOnChange
          component={FilledInput}
          placeholder={t("Search")}
          startAdornment={
            <InputAdornment position="start">
              <IconButton>
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={toggleFilterOpen}>
                <Badge color="secondary" variant="dot" invisible={!isFiltered}>
                  <FilterListRounded />
                </Badge>
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          inputProps={{
            style: {
              paddingTop: 14,
              paddingBottom: 14,
            },
          }}
          style={{
            borderRadius: 0,
          }}
          value={search}
          onChange={({ target: { value } }) => dispatch(setSearch(value))}
          className="no-print"
        />
        <Box overflow={"hidden"} className="no-print">
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            style={{ paddingRight: 10 }}
          >
            <Grid item xs={12} sm={"auto"}>
              {tablePagination}
            </Grid>
            {!isMobile && !isClient && (
              <>
                <Grid item xs={12} sm={"auto"}>
                  <Box p={1} display={"flex"} justifyContent={"flex-end"}>
                    {!!selectedAssets.length && (
                      <SecureComponent permissions={["ASSET_DELETE"]}>
                        <Button
                          variant={"outlined"}
                          startIcon={<DeleteRounded />}
                          color={"secondary"}
                          style={{ marginRight: theme.spacing(1) }}
                          onClick={() =>
                            removeAssetBulk(
                              assets.filter((a) =>
                                selectedAssets.includes(a.id)
                              )
                            )
                          }
                        >
                          {t("Delete")}
                        </Button>
                        <Tooltip
                          title={`${t("Selected")} ${selectedAssets.length}`}
                        >
                          <Typography
                            variant={"button"}
                            align="center"
                            component={"div"}
                            color={"secondary"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: theme.spacing(1),
                            }}
                          >
                            ({selectedAssets.length})
                          </Typography>
                        </Tooltip>
                      </SecureComponent>
                    )}
                    <SecureComponent permissions={["ASSET_CREATE"]}>
                      <Button
                        variant={"contained"}
                        disableElevation
                        startIcon={<AddRounded />}
                        color={"primary"}
                        onClick={() => dispatch(setOpenAsset({}))}
                      >
                        {t("Add new")}
                      </Button>
                    </SecureComponent>
                  </Box>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <ExportMenu
                    onExportPdf={handleExportPdf}
                    onExportExcel={handleExportExcel}
                    onExportCsv={handleExportCsv}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <TableContainer className={classes.tableContainer}>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                {!isClient && (
                  <TableCell padding="checkbox" className="no-print">
                    <Checkbox
                      indeterminate={
                        selectedCountFromCurrentPage > 0 &&
                        selectedCountFromCurrentPage !== assets.length
                      }
                      disabled={!assets}
                      checked={
                        selectedCountFromCurrentPage > 0 &&
                        selectedCountFromCurrentPage === assets.length
                      }
                      onChange={toggleSelectEntirePage}
                    />
                  </TableCell>
                )}
                {!hiddenColumns.includes("id") && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("ID")}
                    </Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Asset")}
                  </Typography>
                </TableCell>
                {!hiddenColumns.includes("asset-profile") && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {columnNames.hasOwnProperty("asset-profile")
                        ? columnNames["asset-profile"]
                        : t("Asset profile")}
                    </Typography>
                  </TableCell>
                )}
                {!isClient && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("Street")}
                    </Typography>
                  </TableCell>
                  // <TableHeaderCell title={t("Street")} onOrder={(order: string) => {
                  //   dispatch(setOrder({ "devices.events.street": order }))
                  // }} />
                )}
                {!isClient && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {`${t("Postal code")} / ${t("City")}`}
                    </Typography>
                  </TableCell>
                  // <TableHeaderCell title={`${t("Postal code")} / ${t("City")}`} onOrder={(order: string) => {
                  //   dispatch(setOrder({ "devices.events.postalCode": order }))
                  // }} />
                )}
                {!isClient && !hiddenColumns.includes("country") && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("Country")}
                    </Typography>
                  </TableCell>
                  // <TableHeaderCell title={t("Country")} onOrder={(order: string) => {
                  //   dispatch(setOrder({ "devices.events.country": order }))
                  // }} />
                )}
                {!isClient && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("Last location update")}
                    </Typography>
                  </TableCell>
                )}
                <TableHeaderCell
                  title={t("Reference")}
                  onOrder={(order: string) => {
                    dispatch(setOrder({ reference: order }));
                  }}
                />
                {!isClient && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("Scheduled")}
                      <OrderIconButton
                        onClick={(order: string) =>
                          dispatch(setOrder({ scheduled: order }))
                        }
                      />
                    </Typography>
                  </TableCell>
                )}
                {!isClient && (
                  <TableCell>
                    <Typography variant={"inherit"} noWrap>
                      {t("Geo zone")}
                    </Typography>
                  </TableCell>
                )}
                {!isClient && (
                  <TableHeaderCell
                    title={t("Days outside geo zone")}
                    onOrder={(order: string) => {
                      dispatch(setOrder({ days_outside_geo_zone: order }));
                    }}
                  />
                )}
                {!isClient && (
                  <TableHeaderCell
                    title={t("Client")}
                    onOrder={(order: string) => {
                      dispatch(setOrder({ "client.name": order }));
                    }}
                  />
                )}
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Status")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets.map((asset) => (
                <TableRow
                  key={asset.id!}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={() => handleAssetClick(asset)}
                  selected={openAsset && asset.id === openAsset.id}
                >
                  {!isClient && (
                    <TableCell padding="checkbox" className="no-print">
                      <Checkbox
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={selectedAssets.includes(asset.id)}
                        onChange={() => dispatch(toggleSelectedAsset(asset))}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>
                  )}
                  <TableCell>{asset.logical_id}</TableCell>
                  <TableCell>
                    <Box display={"flex"} alignItems={"center"}>
                      {asset.devices !== undefined && (
                        <>
                          {asset.devices[0]?.is_sabotaged ? (
                            <Tooltip title={t("Asset is sabotaged") as string}>
                              <FiberManualRecord
                                className={clsx(classes.circle, {
                                  [classes.red]: true,
                                })}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={t("No problems") as string}>
                              <FiberManualRecord
                                className={clsx(classes.circle, {
                                  [classes.green]: true,
                                })}
                              />
                            </Tooltip>
                          )}

                          {!!asset.devices[0]?.battery_level_low && (
                            <Tooltip title={t("Battery value low") as string}>
                              <BatteryAlert
                                className={clsx(classes.red)}
                              ></BatteryAlert>
                            </Tooltip>
                          )}
                        </>
                      )}
                      {asset.name}
                    </Box>
                  </TableCell>
                  {!hiddenColumns.includes("asset-profile") && (
                    <TableCell>
                      <AssetProfileColumn asset={asset} />
                    </TableCell>
                  )}
                  {!isClient && (
                    <TableCell size="small">
                      {asset.last_event?.street}
                    </TableCell>
                  )}
                  {!isClient && (
                    <TableCell size="small">
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2">
                          {asset.last_event?.postal_code}
                        </Typography>
                        <Typography variant="body2">
                          {asset.last_event?.city}
                        </Typography>
                      </Box>
                    </TableCell>
                  )}
                  {!isClient && !hiddenColumns.includes("country") && (
                    <TableCell>{asset.last_event?.country}</TableCell>
                  )}
                  {!isClient && (
                    <TableCell>
                      {asset.last_event?.date && (
                        <Tooltip
                          title={format(new Date(asset.last_event.date), "Pp")}
                        >
                          <span>
                            <DateFnsDistanceNow
                              date={asset.last_event.date}
                              addSuffix
                              includeSeconds
                              locale={getLocale()}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                  <TableCell>{asset.reference}</TableCell>
                  {!isClient && (
                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <KeyboardDatePicker
                        disableToolbar
                        fullWidth
                        clearable
                        format="dd-MM-yyyy"
                        clearLabel={t("Clear")}
                        cancelLabel={t("Cancel")}
                        value={asset?.scheduled}
                        InputProps={{
                          classes: {
                            root: classes.datePickerRoot,
                            input: classes.dateTimeTextField,
                          },
                        }}
                        onChange={(date: MaterialUiPickersDate) =>
                          handleScheduledTimeChange(asset, date)
                        }
                        onClick={(e) => e.stopPropagation()}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        DialogProps={{
                          onClick: (e) => e.stopPropagation(),
                        }}
                      />
                    </TableCell>
                  )}
                  {!isClient && (
                    <TableCell>
                      {asset.geo_zone?.name || t("Not in a zone")}
                    </TableCell>
                  )}
                  {!isClient && (
                    <TableCell>{asset.days_outside_geo_zone}</TableCell>
                  )}
                  {!isClient && <TableCell>{asset.client?.company}</TableCell>}
                  <TableCell>
                    <AssetStatusComponent assetStatus={asset.status} />
                  </TableCell>
                </TableRow>
              ))}
              {!isLoading && !assets.length && (
                <TableRow>
                  <TableCell colSpan={COLUMN_COUNT}>
                    {t("No result found")}
                  </TableCell>
                </TableRow>
              )}
              {isLoading &&
                !assets.length &&
                [...Array(5)].map((_, i) => (
                  <TableRow key={i}>
                    {[...Array(COLUMN_COUNT)].map((_, j) => (
                      <TableCell key={j}>
                        <Skeleton variant={"text"} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {isMobile && <tr style={{ height: 88 }} />}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </AutoHeight>

      {isMobile && (
        <>
          <Backdrop
            open={isSpeedDialOpen}
            style={{
              zIndex: theme.zIndex.drawer + 1,
            }}
            onClick={() => toggleSpeedDialOpen(false)}
          />
          <SpeedDial
            ariaLabel=""
            className={clsx(classes.speedDial, "no-print")}
            icon={<SpeedDialIcon />}
            onClose={() => toggleSpeedDialOpen(false)}
            onOpen={() => toggleSpeedDialOpen(true)}
            open={isSpeedDialOpen}
            direction={"up"}
          >
            {[
              <SpeedDialAction
                key={0}
                icon={<AddRounded />}
                title={""}
                tooltipTitle={<Typography noWrap>{t("Add new")}</Typography>}
                tooltipOpen
                onClick={() => dispatch(setOpenAsset({}))}
              />,
              ...(selectedAssets.length
                ? [
                    <SpeedDialAction
                      key={1}
                      icon={<DeleteRounded />}
                      title=""
                      tooltipTitle={
                        <Typography noWrap>
                          {t("Delete")} ({selectedAssets.length})
                        </Typography>
                      }
                      tooltipOpen
                      onClick={() =>
                        removeAssetBulk(
                          assets.filter((a) => selectedAssets.includes(a.id))
                        )
                      }
                    />,
                  ]
                : []),
            ]}
          </SpeedDial>
        </>
      )}

      <Filter open={isFilterOpen} onClose={toggleFilterOpen} />
    </Paper>
  );
};

export default Table;
