import { Tenant } from "./Tenant";
import { atom } from "jotai";
import Axios from "axios";
import config from "../../config";

export const openTenantAtom = atom<Tenant | null>(null);
export const tenantsFilterAtom = atom<Partial<Tenant>>({});
export const isTenantsFilterOpenAtom = atom<boolean>(false);

const selectedTenantLocalStorage = localStorage.getItem("selectedTenant");
const selectedTenantInternalAtom = atom<Tenant | null>(
  selectedTenantLocalStorage ? JSON.parse(selectedTenantLocalStorage) : null
);
export const selectedTenantAtom = atom<Tenant | null, Tenant | null>(
  (get) => get(selectedTenantInternalAtom),
  (get, set, value) => {
    set(selectedTenantInternalAtom, value);
    if (value) {
      Axios.defaults.baseURL = config.apiUrl.replace(
        /http(s)?:\/\//g,
        (matched) => `${matched}${value.subdomain}.`
      );
    } else {
      Axios.defaults.baseURL = config.apiUrl;
    }
    localStorage.setItem("selectedTenant", JSON.stringify(value));
  }
);
