import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export interface Notification {
  key?: SnackbarKey;
  message: SnackbarMessage;
  options?: OptionsObject;
  dismissed?: boolean;
}

interface SnackbarState {
  notifications: Notification[];
}

const initialState: SnackbarState = {
  notifications: [],
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    enqueueSnackbar(state, action: PayloadAction<Notification>) {
      state.notifications.push({
        ...action.payload,
        key: action.payload.key || new Date().getTime() + Math.random(),
      } as Draft<Notification>);
    },
    closeSnackbar(
      state,
      action: PayloadAction<Notification["key"] | undefined>
    ) {
      for (const notification of state.notifications) {
        if (!action.payload || notification.key === action.payload) {
          notification.dismissed = true;
        }
      }
    },
    removeSnackbar(state, action: PayloadAction<Notification["key"]>) {
      state.notifications.splice(
        state.notifications.findIndex((n) => n.key === action.payload),
        1
      );
    },
  },
});

// noinspection JSUnusedGlobalSymbols
export const {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
} = snackbarSlice.actions;

export const selectNotifications = (state: RootState) =>
  state.snackbar.notifications;

export const snackbarReducer = snackbarSlice.reducer;
