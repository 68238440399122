import { IconButton, Theme, Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import {
  FirstPageRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  LastPageRounded,
} from "@material-ui/icons";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";

const useStyles1 = makeStyles((theme: Theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions: FunctionComponent<
  TablePaginationActionsProps & {
    firstIconButtonText?: string;
    backIconButtonText?: string;
    nextIconButtonText?: string;
    lastIconButtonText?: string;
  }
> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  firstIconButtonText,
  backIconButtonText,
  nextIconButtonText,
  lastIconButtonText,
}) => {
  firstIconButtonText = firstIconButtonText || "First page";
  backIconButtonText = backIconButtonText || "Previous page";
  nextIconButtonText = nextIconButtonText || "Next page";
  lastIconButtonText = lastIconButtonText || "Last page";

  const classes = useStyles1();
  const theme = useTheme();

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Tooltip title={firstIconButtonText}>
        <span>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
          >
            {theme.direction === "rtl" ? (
              <LastPageRounded />
            ) : (
              <FirstPageRounded />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={backIconButtonText}>
        <span>
          <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRightRounded />
            ) : (
              <KeyboardArrowLeftRounded />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={nextIconButtonText}>
        <span>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeftRounded />
            ) : (
              <KeyboardArrowRightRounded />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={lastIconButtonText}>
        <span>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          >
            {theme.direction === "rtl" ? (
              <FirstPageRounded />
            ) : (
              <LastPageRounded />
            )}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default TablePaginationActions;
