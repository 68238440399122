import Axios from "axios";
import qs from "qs";
import { Entity, EntityFilter } from "./ApiStore";

export declare type apiResponse<E extends Entity> = {
  "hydra:totalItems": number;
  "hydra:member": E[];
};

declare type stringifyOptions = {
  allowDots?: boolean;
  arrayFormat?: string;
};

export abstract class Api<E extends Entity, EF extends EntityFilter> {
  protected endpoint: string;

  public constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  public async fetch(filter?: EF, options?: stringifyOptions): Promise<apiResponse<E>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get<{
          "hydra:totalItems": number;
          "hydra:member": E[];
        }>(`${this.endpoint}`, {
          params: {
            ...filter,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, options || { allowDots: false, arrayFormat: "brackets" });
          },
        });

        resolve(response.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  public async post(entity: E): Promise<E> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post<E>(`${this.endpoint}`, entity);

        resolve(response?.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  public async put(entity: E): Promise<E> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put<E>(`${this.endpoint}/${entity.id}`, entity);

        resolve(response?.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  public async get(id: number | string): Promise<E> {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = typeof(id) === "string" ? id : `${this.endpoint}/${id}`; 
        const response = await Axios.get<E>(endpoint);

        resolve(response?.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  public async delete(entity: E): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await Axios.delete<E>(`${this.endpoint}/${entity.id}`);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }
}
