import {
  Button,
  CircularProgress,
  DialogActions,
  Fade,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";
import { Details } from "./Details/Details";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  removeUser,
  setOpenUser,
  updateUser,
  usersIsLoadingSelector,
  usersOpenUserSelector,
} from "../usersSlice";
import { User, UserCredentials } from "../User";
import { DeleteRounded } from "@material-ui/icons";
import Menu from "../../../common/components/Menu/Menu";

const OpenUser = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(usersOpenUserSelector);
  const userId = user?.id;
  const [hasId, setHasId] = useState<boolean>(!!user?.id);
  const isLoading = useSelector(usersIsLoadingSelector);

  useEffect(() => {
    if (user) {
      const hasId = !!userId;
      if (hasId) {
        setHasId(hasId);
      } else {
        setHasId(hasId);
      }
    } else {
      setHasId(!!userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  useUnmount(() => {
    handleClose();
  });

  const handleClose = () => {
    dispatch(setOpenUser(undefined));
  };
  const handleSave = () => {
    if (hasId) {
      dispatch(updateUser(user as User & UserCredentials));
    } else {
      dispatch(addUser(user as User & UserCredentials));
    }
  };

  return (
    <SidePanelModal
      title={`${!hasId ? `${t("New")} ` : ""}${t("User")}`}
      open={!!user}
      onClose={handleClose}
      width={theme.spacing(50)}
    >
      <Details />
      <DialogActions>
        {isLoading && (
          <Fade in timeout={{ enter: 1000 }}>
            <CircularProgress
              size={theme.spacing(3)}
              style={{ marginRight: theme.spacing(1) }}
              color="inherit"
            />
          </Fade>
        )}
        {hasId && user && (
          <Menu
            loading={isLoading}
            items={(closeMenu) => [
              <ListSubheader key={0} component="div">
                {t("Are you sure you want to delete")} {'"'}
                {user.name || user.email || `#${user.id}`}
                {'"'}?
              </ListSubheader>,
              <MenuItem
                key={0}
                onClick={() => dispatch(removeUser(user as User))}
              >
                {t("Delete")}
              </MenuItem>,
              <MenuItem key={0} onClick={closeMenu}>
                {t("Cancel")}
              </MenuItem>,
            ]}
          >
            <Button
              variant="text"
              disableElevation
              startIcon={<DeleteRounded />}
            >
              {t("Delete")}
            </Button>
          </Menu>
        )}
        <Button
          onClick={handleSave}
          color="primary"
          variant={"contained"}
          disableElevation
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};

export default OpenUser;
