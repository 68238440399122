import React, { useEffect, useRef } from "react";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { useTheme } from "@material-ui/core/styles";
import { Button, DialogActions, DialogContent, Grid } from "@material-ui/core";
import * as Yup from "yup";
import { InferType } from "yup";
import getFieldsFromSchema from "../../../utils/getFieldsFromSchema";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { isTenantsFilterOpenAtom, tenantsFilterAtom } from "../Tenants.state";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

const FilterSchema = Yup.object({
  subdomain: Yup.string().label("Subdomain").default(""),
  company: Yup.string().label("Company").default(""),
  address: Yup.string().label("Address").default(""),
  zipcode: Yup.string().label("Zip code").default(""),
  city: Yup.string().label("City").default(""),
  country: Yup.string().label("Country").default(""),
  phone: Yup.string().label("Phone").default(""),
  email: Yup.string().label("Email").default(""),
}).required();
const FilterFields = getFieldsFromSchema(FilterSchema);
export type FilterValues = InferType<typeof FilterSchema>;

export const Filter = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [filter, setFilter] = useAtom(tenantsFilterAtom);
  const [isFilterOpen, setFilterOpen] = useAtom(isTenantsFilterOpenAtom);

  const formRef = useRef<FormikProps<FilterValues>>(null);
  const submitFormRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    formRef.current?.setValues({ ...FilterSchema.default(), ...filter });
  }, [filter]);

  return (
    <SidePanelModal
      title={t("Filter")}
      width={theme.spacing(50)}
      open={isFilterOpen}
      onClose={() => setFilterOpen(false)}
    >
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={{ ...FilterSchema.default(), ...filter }}
          onSubmit={(values) => {
            setFilterOpen(false);
            void setFilter(values);
          }}
          validationSchema={FilterSchema}
        >
          {(formikProps) => (
            <Form>
              <Grid container spacing={2}>
                {FilterFields.map((field) => (
                  <Grid item xs={12} key={field.name}>
                    {!field.meta?.render ? (
                      <Field
                        component={TextField}
                        variant="filled"
                        fullWidth
                        name={field.name}
                        label={t(field.label)}
                        required={field.required}
                        disabled={false}
                      />
                    ) : (
                      field.meta.render(field, formikProps)
                    )}
                  </Grid>
                ))}
              </Grid>
              <button
                ref={submitFormRef}
                style={{ padding: 0, border: "none" }}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFilter({});
            setFilterOpen(false);
          }}
        >
          {t("Clear")}
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => submitFormRef.current?.click()}
        >
          {t("Filter")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};
