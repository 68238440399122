import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { User, UserCredentials, UserFilter } from "./User";

interface UsersState {
  isLoading: boolean;
  users: User[];
  search: string;
  openUser?: Partial<User & UserCredentials> | null;
  filter: UserFilter;
  totalItems: number;
  page: number;
}

const initialState: UsersState = {
  isLoading: false,
  users: [],
  search: "",
  openUser: undefined,
  filter: {},
  totalItems: 0,
  page: 0,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<UsersState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setUsers(state, action: PayloadAction<UsersState["users"]>) {
      state.users = action.payload;
    },
    setSearch(state, action: PayloadAction<UsersState["search"]>) {
      state.search = action.payload;
    },
    setOpenUser(state, action: PayloadAction<UsersState["openUser"]>) {
      state.openUser = action.payload;
    },
    updateOpenUser(state, action: PayloadAction<UsersState["openUser"]>) {
      state.openUser = { ...state.openUser, ...action.payload };
    },
    setFilter(state, action: PayloadAction<UsersState["filter"]>) {
      state.filter = action.payload;
    },
    setTotalItems(state, action: PayloadAction<UsersState["totalItems"]>) {
      state.totalItems = action.payload;
    },
    setPage(state, action: PayloadAction<UsersState["page"]>) {
      state.page = action.payload;
    },
  },
});

export const usersReducer = usersSlice.reducer;

const {
  setUsers,
  setLoading,
  setSearch: setSearchAction,
  setOpenUser,
  updateOpenUser,
  setFilter: setFilterAction,
  setTotalItems,
  setPage: setPageAction,
} = usersSlice.actions;
export { setOpenUser, updateOpenUser };

export const setPage = (page: UsersState["page"]): AppThunk => (dispatch) => {
  batch(() => {
    dispatch(setPageAction(page));
    dispatch(fetchUsers());
  });
};

export const setSearch = (search: UsersState["search"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(0));
    dispatch(setSearchAction(search));
    dispatch(fetchUsers());
  });
};

export const setFilter = (filter: UsersState["filter"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(0));
    dispatch(setFilterAction(filter));
    dispatch(fetchUsers());
  });
};

export const fetchUsers = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.get<{
      "hydra:totalItems": number;
      "hydra:member": User[];
    }>(`/api/users`, {
      params: {
        search: getState().users.search,
        page: getState().users.page + 1,
        ...getState().users.filter,
      },
    });
    const totalItems = response.data["hydra:totalItems"];
    const users = response.data["hydra:member"];
    batch(() => {
      dispatch(setUsers(users));
      dispatch(setTotalItems(totalItems));
      dispatch(setLoading(false));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const addUser = (user: User & UserCredentials): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.post(`/api/users`, user);
    batch(() => {
      dispatch(fetchUsers());
      dispatch(setOpenUser(undefined));
      dispatch(
        enqueueSnackbar({
          message: "User added",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const updateUser = (user: User & UserCredentials): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.put(`/api/users/${user.id}`, user);
    batch(() => {
      dispatch(fetchUsers());
      dispatch(setOpenUser(undefined));
      dispatch(
        enqueueSnackbar({
          message: "User updated",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const removeUser = (user: User): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await Axios.delete(`/api/users/${user.id}`);
    batch(() => {
      dispatch(fetchUsers());
      dispatch(setOpenUser(undefined));
      dispatch(
        enqueueSnackbar({
          message: "User deleted",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const usersSelector = (state: RootState) => state.users.users;
export const usersIsLoadingSelector = (state: RootState) =>
  state.users.isLoading;
export const usersSearchSelector = (state: RootState) => state.users.search;
export const usersOpenUserSelector = (state: RootState) => state.users.openUser;
export const usersFilterSelector = (state: RootState) => state.users.filter;
export const usersTotalItemsSelector = (state: RootState) =>
  state.users.totalItems;
export const usersPageSelector = (state: RootState) => state.users.page;
