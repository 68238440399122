import { Button, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { SecureComponent } from "../../../providers/SecurityProvider";
import { AssetProfileCableReelType } from "../assetProfile";


const CableReelDetails: FunctionComponent<{
  profile?: AssetProfileCableReelType;
  onSave: (profile: AssetProfileCableReelType) => void;
  onClose: () => void;
}> = ({profile, onSave, onClose}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <SidePanelModal
      title={t("cable_reel_type")}
      open={!!profile}
      onClose={onClose}
      width={theme.spacing(75)}
    >
      <DialogContent>
        <Formik
          initialValues={{
            id: uuid().toString(),
            name: "",
            width: 0,
            diameterMin: 0,
            diameterMax: 0,
            ...profile,
          }}
          validationSchema={Yup.object<Partial<AssetProfileCableReelType>>({
            name: Yup.string().required(t("The name is required")),
            width: Yup.number().required(t("Width is required")),
            diameterMin: Yup.number().required(t("The diameter is required")),
            diameterMax: Yup.number().required(t("The diameter is required")),
          })}
          onSubmit={(values) => {
            onSave(values);
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="name"
                    label={t("Name")}
                    variant="filled"
                    fullWidth
                    disabled={false}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="width"
                    label={t("cable_reel_width")}
                    variant="filled"
                    fullWidth
                    disabled={false}
                    required
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="diameterMin"
                    label={t("cable_reel_diameter_min")}
                    variant="filled"
                    fullWidth
                    disabled={false}
                    required
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="diameterMax"
                    label={t("cable_reel_diameter_max")}
                    variant="filled"
                    fullWidth
                    disabled={false}
                    required
                    type="number"
                  />
                </Grid>
              </Grid>
              <button
                ref={submitButtonRef}
                type="submit"
                style={{ border: 0, padding: 0 }}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <SecureComponent permissions={["ASSET_PROFILE_EDIT"]}>
          <Button
            onClick={() => {
              console.log("trigger");
              console.log(submitButtonRef.current);
              submitButtonRef.current?.click();
            }}
            color="primary"
            variant={"contained"}
            disableElevation
          >
            {t("Save")}
          </Button>
        </SecureComponent>
      </DialogActions>
    </SidePanelModal>
  );
};

export default CableReelDetails;
