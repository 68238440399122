import {
  Box, Grid, LinearProgress, Paper, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { isClient } from "react-use/lib/util";
import AutoHeight from "../../../common/components/AutoHeight/AutoHeight";
import { AssetProfile } from "../assetProfile";
import { assetProfileDefinitionStore } from "../assetProfileDefinitionStore";
  
const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    marginTop: -2,
    marginLeft: -3,
    marginRight: 4,
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  tableContainer: {
    "@media print": {
      height: "auto !important",
      "& *": {
        color: "black",
      },
      "& td, & th": {
        padding: 0,
        paddingRight: 4,
      },
      "& span": {
        whiteSpace: "normal",
      },
    },
  },
  asc: {
    transform: "rotate(-180deg)",
  },
  desc: {
    transform: "rotate(0)",
  },
  datePickerRoot: {
    "&::before": {
      border: "none",
    },
    "&::after": {},
  },
  dateTimeTextField: {
    minWidth: theme.spacing(10),
    maxWidth: theme.spacing(13),
  },
}));
  
const Table = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const entities = useSelector(assetProfileDefinitionStore.entitiesSelector);
  const openEntity = useSelector(assetProfileDefinitionStore.openEntitySelector);
  const isLoading = useSelector(assetProfileDefinitionStore.isLoadingSelector);

  useMount(() => {
      dispatch(assetProfileDefinitionStore.fetch());
  });

  const handleOpenEntity = (entity: AssetProfile) => {
    dispatch(assetProfileDefinitionStore.setOpenEntity(entity));
  };

  return (
    <Paper square>
      {isLoading && !!entities.length && (
        <LinearProgress style={{ marginBottom: -4 }} />
      )}
      <AutoHeight autoHeightIndex={1}>
        <Box overflow={"hidden"} className="no-print">
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            style={{ paddingRight: 10 }}
          >
            <Grid item xs={12} sm={"auto"}>
              {/* {tablePagination} */}
            </Grid>
          </Grid>
        </Box>
        <TableContainer className={classes.tableContainer}>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                {!isClient && (
                  <TableCell padding="checkbox" className="no-print">
                    {/* <Checkbox
                      indeterminate={
                        selectedCountFromCurrentPage > 0 &&
                        selectedCountFromCurrentPage !== assets.length
                      }
                      disabled={!assets}
                      checked={
                        selectedCountFromCurrentPage > 0 &&
                        selectedCountFromCurrentPage === assets.length
                      }
                      onChange={toggleSelectEntirePage}
                    /> */}
                  </TableCell>
                )}
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("ID")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Name")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entities.map((entity, index: number) => (
                <TableRow
                  key={entity.id!}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={() => handleOpenEntity(entity)}
                  selected={openEntity && entity.id === openEntity.id}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {t(entity.name!)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </AutoHeight>
    </Paper>
  );
};

export default Table;
  