import {
  Box,
  Button,
  Checkbox, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid, IconButton,
  Input, LinearProgress, Paper, Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField, useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AddRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import update from "immutability-helper";
import React, {
  FunctionComponent, MouseEvent, useEffect, useState
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMount, useToggle } from "react-use";
import AutoHeight from "../../../common/components/AutoHeight/AutoHeight";
import { SecureComponent } from "../../../providers/SecurityProvider";
import { roleStore } from "../RoleState";

declare type Role = {
  "@id"?: string;
  "#type"?: string;
  id?: number;
  name: string;
  role_permissions: Permission[];
  is_user_defined?: boolean;
};

declare type Permission = {
  "@id": string;
  "#type": string;
  id: number;
  permission: string;
};

const FormDialog: FunctionComponent<{
  title: string;
  text: string;
  open: boolean;
  onClose: (value?: string) => void;
}> = ({ title, text, open, onClose }) => {
  const { t } = useTranslation();
  const [fieldValue, setFieldValue] = useState("");

  const handleCancel = () => {
    if (!!onClose) {
      onClose();
    }
  };

  const handleOk = () => {
    if (!!onClose) {
      onClose(fieldValue);
    }
  };

  useEffect(() => {
    if (!open) {
      setFieldValue("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t("Role")}
            type="text"
            fullWidth
            value={fieldValue}
            onChange={(event) => setFieldValue(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleOk} color="primary">
            {t("Ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const TableHeaderCell: FunctionComponent<{
  role: Role;
  updateHeader: (role: Role, name: string) => void;
}> = ({ role, updateHeader }) => {
  const [isEditing, setIsEditing] = useToggle(false);
  const [fieldValue, setFieldValue] = useState(role.name);
  const { t } = useTranslation();

  const handleEditCustomRole = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (!!updateHeader) {
      updateHeader(role, fieldValue);
      setIsEditing(false);
    }
  };

  return (
    <TableCell>
      {isEditing ? (
        <>
          <Input
            id="standard-adornment-weight"
            value={fieldValue}
            onChange={(event: any) => setFieldValue(event.target.value)}
            endAdornment={
              <IconButton onClick={handleSave}>
                <SaveIcon />
              </IconButton>
            }
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </>
      ) : (
        <>
          {t(fieldValue)}
          {!!role.is_user_defined && (
            <IconButton onClick={handleEditCustomRole}>
              <EditIcon />
            </IconButton>
          )}
        </>
      )}
    </TableCell>
  );
};

const useStyles = makeStyles(() => ({
  table: {
    tableLayout: "fixed"
  }
}));

export const Table: FunctionComponent = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [roles, setRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useToggle(false);
  const [showAddRoleDialog, setShowAddRoleDialog] = useToggle(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  const fetchedRoles = useSelector(roleStore.entitiesSelector);

  useMount(() => {
    loadRoles();
  });

  const loadRoles = () => {
    dispatch(roleStore.fetch());
  };

  const { data: permissions } = useQuery<Permission[]>(
    ["permissions"],
    () =>
      axios
        .get("/api/permissions", {
          params: {
            pagination: false,
          },
        })
        .then((res) => res.data)
        .then((data) => data?.["hydra:member"] || []),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (!!fetchedRoles) {
      setRoles(fetchedRoles);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedRoles]);

  const isPermissionGranted = (role: Role, permission: Permission): boolean => {
    try {
      const result = role.role_permissions.find(
        (item: Permission) => item.id === permission.id
      );
      return !!result;
    } catch (err) {
      return false;
    }
  };

  const handlePermissionChange = (
    role: Role,
    permission: Permission,
    checked: boolean
  ) => {
    let updatedRoles: Role[] = roles;

    const index = roles.indexOf(role);

    if (checked) {
      updatedRoles = update(roles, {
        [index]: {
          role_permissions: {
            $push: [permission],
          },
        },
      });
    } else {
      updatedRoles = update(roles, {
        [index]: {
          role_permissions: {
            $set: role.role_permissions.filter(
              (item: Permission) => item.id !== permission.id
            ),
          },
        },
      });
    }
    setRoles(updatedRoles);
  };

  const handleSave = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsLoading(true);

    const promises: Promise<any>[] = [];
    roles.forEach(async (role: Role) => {
      if (!!role.id) {
        const promise = axios.put(`/api/roles/${role.id}`, role);
        promises.push(promise);
      } else {
        const promise = axios.post("/api/roles", role);
        promises.push(promise);
      }
    });

    Promise.all(promises)
      .then(() => {
        dispatch(roleStore.fetch());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddRole = () => {
    setShowAddRoleDialog(true);
  };

  const handleCloseAddRoleDialog = (role?: string) => {
    if (!!role) {
      setIsLoading(true);
      setShowAddRoleDialog(false);
      axios
        .post("/api/roles", { name: role, role_permissions: [] } as Role)
        .finally(handleReloadRoles);
    } else {
      setShowAddRoleDialog(false);
    }
  };

  const handleReloadRoles = () => {
    loadRoles();
    setIsLoading(false);
  };

  const handleUpdateHeader = (role: Role, name: string) => {
    setIsLoading(true);
    axios
      .put(`/api/roles/${role.id}`, { role, name: name })
      .finally(handleReloadRoles);
  };

  return (
    <Paper square>
      {isLoading && !!permissions?.length && (
        <LinearProgress style={{ marginBottom: -4 }} />
      )}
      <AutoHeight autoHeightIndex={1}>
        <Box overflow={"hidden"}>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} sm={"auto"}></Grid>
            {!isMobile && (
              <Grid item xs={12} sm={"auto"}>
                <Box p={1} display={"flex"} justifyContent={"flex-end"}>
                  <SecureComponent permissions={["COMING_SOON"]}>
                    <Button
                      variant={"contained"}
                      disableElevation
                      startIcon={<AddRounded />}
                      style={{ marginRight: 8 }}
                      color={"primary"}
                      onClick={handleAddRole}
                      disabled
                    >
                      {t("Add role")}
                    </Button>
                  </SecureComponent>
                  <SecureComponent permissions={["PERMISSION_EDIT"]}>
                    <Button
                      variant={"contained"}
                      disableElevation
                      color={"primary"}
                      onClick={handleSave}
                    >
                      {t("Save")}
                    </Button>
                  </SecureComponent>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <TableContainer>
          <MuiTable stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {roles.map((role: Role, key: number) => (
                  <TableHeaderCell
                    key={key}
                    role={role}
                    updateHeader={handleUpdateHeader}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions?.sort((a: Permission, b: Permission) => a.permission !== b.permission ? 1 : -1)?.map((item: Permission, key: number) => (
                <TableRow key={key}>
                  <TableCell>{t(item.permission)}</TableCell>
                  {roles?.map((role: Role, roleKey: number) => (
                    <TableCell key={roleKey}>
                      <Checkbox
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        checked={isPermissionGranted(role, item)}
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean
                        ) => handlePermissionChange(role, item, checked)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <FormDialog
          open={showAddRoleDialog}
          onClose={handleCloseAddRoleDialog}
          title={t("Add role")}
          text={t("Enter the role name")}
        />
      </AutoHeight>

      {/*<Filter open={isFilterOpen} onClose={toggleFilterOpen} />*/}
    </Paper>
  );
};
