import { DialogContent, Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropertyField from "../../../../components/PropertyField/PropertyField";
import {
  geoZonesOpenGeoZoneSelector,
  updateOpenGeoZone
} from "../../../GeoZone/geoZoneSlice";

export const Details: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openGeoZone = useSelector(geoZonesOpenGeoZoneSelector);

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <PropertyField
          label={t("Name")}
          value={openGeoZone?.name || ""}
          isEditing={true}
          xs={12}
          onChange={(value) => dispatch(updateOpenGeoZone({ name: value }))}
        />
      </Grid>
    </DialogContent>
  );
};
