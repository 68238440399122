import React from "react";
import useTitle from "../../common/hooks/useTitle";
import { makeStyles } from "@material-ui/core/styles";
import pageNotFound from "./page_not_found.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    bottom: 0,
    right: 0,
    maxWidth: "100%",
  },
}));

export const NotFound = () => {
  const { t } = useTranslation();
  useTitle(t("Page not found"));
  const classes = useStyles();

  return (
    <img className={classes.root} src={pageNotFound} alt="page not found" />
  );
};
