import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SwipeableDrawer,
  Tooltip,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useAtom } from "jotai";
import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { selectedTenantAtom } from "../../../../domains/Tenant/Tenants.state";
import { SecureComponent } from "../../../../providers/SecurityProvider";
import useScrollVisible from "../../../hooks/useScrollVisible";
import MenuButton from "../MenuButton/MenuButton";

const drawerWidth = 240;

export const toolbarStyles = (theme: Theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: "48px !important",
  [theme.breakpoints.down("xs")]: {
    justifyContent: "flex-start",
    minHeight: "56px !important",
  },
  color: theme.palette.primary.main,
});

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor: 'transparent',
    // border: 'none'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create(
      ["width", "border", "background-color"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }
    ),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create(
      ["width", "border", "background-color"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }
    ),
    overflowX: "hidden",
    overflowY: "hidden",
    width: theme.spacing(7) + 1,
    border: "none",
    backgroundColor: "transparent",
  },
  drawerCloseScrollable: {
    "&:hover": {
      overflowY: "auto",
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
  },
  drawerToolbarDivider: {
    marginBottom: -1,
  },
  drawerDontShow: {
    width: "0 !important",
  },
  menu: {
    transition: theme.transitions.create(
      ["backgroundColor", "color", "fontWeight"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      }
    ),

    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  menuIcon: {
    color: "inherit",
  },

  toolbar: {
    ...toolbarStyles(theme),
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    // paddingRight: 0
  },
}));


export declare type Menu = {
  title: string;
  icon: ReactElement;
  url: string;
  exact?: boolean;
  subheader?: string;
  hasPermission?: boolean;
  permissions?: string[];
  roles?: string[];
};

const Drawer: FunctionComponent<{
  open: boolean;
  logo?: ReactNode;
  onClose: () => void;
  onOpen: () => void;
  show: boolean;
  menus: Menu[];
  appTitle?: string;
  isLoadingMenu?: boolean;
  className?: string;
}> = ({
  open,
  logo,
  onClose,
  onOpen,
  show,
  menus,
  appTitle,
  isLoadingMenu,
  className,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
  const { t } = useTranslation();

  const [scrollableElementRef, setScrollableElementRef] = useState<
    HTMLElement
  >();
  const isDrawerCloseScrollVisible = useScrollVisible(scrollableElementRef);
  let [selectedTenant] = useAtom(selectedTenantAtom);

  const drawer = (
    <>
      {open && <Divider className={classes.drawerToolbarDivider} />}
      <SecureComponent permissions={["nee"]}>
        <div>test</div>
      </SecureComponent>
      <List dense={!isMobile}>
        {menus.map((menu) => (
          <SecureComponent key={menu.title} permissions={menu.permissions} roles={menu.roles}>
            <Fragment>
              {menu.subheader && (
                <Collapse in={open} mountOnEnter unmountOnExit>
                  <ListSubheader component="div">{menu.subheader}</ListSubheader>
                </Collapse>
              )}
              <Tooltip
                title={menu.title}
                aria-label={menu.title?.toLowerCase()}
                placement="right"
                disableFocusListener={isMobile || open}
                disableHoverListener={isMobile || open}
                disableTouchListener={isMobile || open}
              >
                <ListItem
                  button
                  className={classes.menu}
                  exact={menu.exact}
                  onClick={isMobile ? onClose : undefined}
                  component={!menu.url.startsWith("http") ? NavLink : Link}
                  to={!menu.url.startsWith("http") ? menu.url : undefined}
                  href={menu.url.startsWith("http") ? menu.url : undefined}
                  underline={menu.url.startsWith("http") ? "none" : undefined}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menu.title}
                    primaryTypographyProps={{ variant: "inherit" }}
                  />
                </ListItem>
              </Tooltip>
            </Fragment>
          </SecureComponent>
        ))}
        {isLoadingMenu && (
          <ListItem className={classes.menu}>
            <ListItemIcon className={classes.menuIcon}>
              <CircularProgress color={"inherit"} size={theme.spacing(3)} />
            </ListItemIcon>
            <ListItemText
              primary={<Skeleton variant={"text"} />}
              primaryTypographyProps={{ variant: "inherit" }}
            />
          </ListItem>
        )}
      </List>
      {selectedTenant && (
        <Box p={2}>
          <Typography variant="body2" color="textSecondary">
            {t("Tenant")}: {selectedTenant.company}
          </Typography>
        </Box>
      )}
    </>
  );

  return (
    <nav aria-label="navigation menu">
      <Hidden xsDown>
        <MuiDrawer
          variant="permanent"
          className={clsx(classes.drawer, className, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.drawerDontShow]: !show,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
              [classes.drawerDontShow]: !show,
              [classes.drawerCloseScrollable]: isDrawerCloseScrollVisible,
            }),
          }}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            ref: (ref: HTMLElement) => setScrollableElementRef(ref),
          }}
        >
          <div className={classes.toolbar}>
            <MenuButton
              isMobile={isMobile}
              open={open}
              onClick={onClose}
              location="drawer"
              show={show}
            />
          </div>
          {drawer}
        </MuiDrawer>
      </Hidden>
      <Hidden smUp>
        <SwipeableDrawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
          className={className}
        >
          <div className={classes.toolbar}>
            {logo}
            {appTitle && (
              <Typography variant="subtitle2" noWrap>
                {appTitle}
              </Typography>
            )}
          </div>
          {drawer}
        </SwipeableDrawer>
      </Hidden>
    </nav>
  );
};

export default Drawer;
