import { combineReducers } from "@reduxjs/toolkit";
import { alarmRulesReducer as alarmRules } from "../domains/AlarmRule/alarmRulesSlice";
import { assetMapReducer as assetMap } from "../domains/AssetMap/assetMapSlice";
import { assetsReducer as assets } from "../domains/Assets/assetsSlice";
import { assetStatusReducer as assetStatuses } from "../domains/Assets/assetStatusSlice";
import { openAssetReducer as openAsset } from "../domains/Assets/openAssetSlice";
import { authReducer as auth } from "../domains/Auth/authSlice";
import { customersReducer as customers } from "../domains/Customers/customersSlice";
import { devicesReducer as devices } from "../domains/Device/devicesSlice";
import { eventsReducer as events } from "../domains/Events/eventsSlice";
import { geoZonesReducer as geoZones } from "../domains/GeoZone/geoZoneSlice";
import { snackbarReducer as snackbar } from "../domains/Snackbar/snackbarSlice";
import { usersReducer as users } from "../domains/User/usersSlice";

const initialReducers = {
  auth,
  snackbar,
  events,
  assets,
  openAsset,
  devices,
  users,
  assetMap,
  alarmRules,
  geoZones,
  customers,
  assetStatuses,
};

export { initialReducers };

const rootReducers = combineReducers(initialReducers);

export default rootReducers;
