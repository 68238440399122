import { TableCell, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { OrderButton } from "../../common/components/Buttons/OrderButton";

export const TableCellWithOrdering: FunctionComponent<{ 
  title: string;
  inversed?: boolean;
  onOrder?: (order: string) => void;
}> = ({title, inversed, onOrder}) => {

  return (
    <TableCell>
      <Typography variant={"inherit"} noWrap>
        {title}
        {!!onOrder ? (
          <OrderButton inversed={inversed} onClick={onOrder} />
        ) : (
          <div>&nbsp;</div>
        )}
      </Typography>
    </TableCell>
  );
};
