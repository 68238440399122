import { Button, DialogActions, DialogContent, Divider, Fade, Grid, IconButton, InputBase, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Popper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import update from "immutability-helper";
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DeleteButton } from "../../../common/components/Buttons/DeleteButton";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { SecureComponent } from "../../../providers/SecurityProvider";
import { assetProfileDefinitionStore } from "../assetProfileDefinitionStore";
import { CableReelProfile } from "../AssetProfiles/CableReelProfile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "100%",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    definitionList: {
      maxHeight: theme.spacing(20),
      overflow: "scroll",
      marginTop: theme.spacing(1)
    },
    listDefinitionRoot: {
      marginBottom: theme.spacing(3)
    }
  }),
);

const ListDefinition: FunctionComponent<{
  definition: any;
  type: "number" | "text";
  onChange: (data: any[]) => void;
}> = ({definition, type, onChange}) => {
  const classes = useStyles();
  const [newItem, setNewItem] = useState<string>("");
  const [items, setItems] = useState<string[]>([]);
  const inputRef = useRef();
  const { t } = useTranslation();

  const addDefinitionItem = () => {
    if(!!newItem) {
      const updatedItems = items.concat([newItem]);
      setItems(updatedItems.sort((a: string, b: string) => (a >= b) ? 1 : -1));
      setNewItem("");

      onChange(updatedItems);
    }
  }

  useEffect(() => {
    if(!!definition?.data) {
      setItems(definition.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definition.data]);

  const handleDelete = (index: number) => {
    const updatedItems = update(items, {
      $splice: [[index, 1]]
    });
    setItems(updatedItems);

    onChange(updatedItems);
  }

  return (
    <div className={classes.listDefinitionRoot}>
      <Popper open={true} anchorEl={inputRef.current} placement="bottom-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography>The content of the Popper.</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Paper ref={inputRef} component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder={t("add new item")}
          inputProps={{ 'aria-label': 'add new item' }}
          value={newItem}
          type={type}
          onChange={(e) => setNewItem(e.target.value)}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton onClick={addDefinitionItem} color="primary" className={classes.iconButton} aria-label="directions">
          <AddIcon />
        </IconButton>
      </Paper>
      {/* <Paper> */}
        <List className={classes.definitionList}>
          {!!items && items.length > 0 ? (
            <>
              {items?.map((data: any, index: number) => (
                <ListItem key={index} divider>
                  <ListItemText primary={data} />
                  <ListItemSecondaryAction>
                    <DeleteButton title={data} onClick={() => handleDelete(index)} iconOnly />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem divider>
              <ListItemText primary={t("no items")} />
            </ListItem>
          )}
        </List>
      {/* </Paper> */}
    </div>
  )
}

const OpenAssetProfile: FunctionComponent = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openEntity = useSelector(assetProfileDefinitionStore.openEntitySelector);

  const handleClose = () => {
    dispatch(assetProfileDefinitionStore.clearOpenEntity());
  };
  
  const handleSave = () => {
    if (!!openEntity?.id) {
      dispatch(assetProfileDefinitionStore.update(openEntity));
    }
  };

  const updateDefinition = (index: number, data: any[]) => {
    const updatedEntity = update(openEntity, {
      definition: {
        [index]: {
          data: {
            $set: data
          }
        }
      }
    });

    dispatch(assetProfileDefinitionStore.setOpenEntity(updatedEntity));
  }

  return (
    <SidePanelModal
      title={!!openEntity?.id ? t(openEntity?.name!) : t("Asset profile")}
      open={!!openEntity}
      onClose={handleClose}
      width={theme.spacing(75)}
    >
      <DialogContent>
        <Grid container spacing={2}>
          {openEntity?.type === "cable_reel" && (
            <CableReelProfile
              assetProfile={openEntity}
            />
          )}
          <Grid item xs={12}>
           {openEntity?.definition?.map((definition: any, index: number) => (
              <Fragment key={index}>
                {definition.type === "number[]" && (
                  <>
                    <Typography variant="h6">{t(definition.name)}</Typography>
                    <ListDefinition type="number" definition={definition} onChange={(data) => updateDefinition(index, data)} />
                  </>
                )}
                {definition.type === "string[]" && (
                  <>
                    <Typography variant="h6">{t(definition.name)}</Typography>
                    <ListDefinition type="text" definition={definition} onChange={(data) => updateDefinition(index, data)} />
                  </>
                )}
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecureComponent permissions={["ASSET_PROFILE_EDIT"]}>
          <Button
            onClick={handleSave}
            color="primary"
            variant={"contained"}
            disableElevation
          >
            {t("Save")}
          </Button>
        </SecureComponent>
      </DialogActions>
    </SidePanelModal>
  );
};

export default OpenAssetProfile;
