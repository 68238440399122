import useTitle from "../../common/hooks/useTitle";
import React from "react";
import Table from "./Table/Table";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import { setFilter } from "./usersSlice";
import { Details } from "./Details/Details";

export const Users = () => {
  const { t } = useTranslation();
  useTitle(t("Users"));
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(setFilter({}));
  });

  return (
    <>
      <Table />

      <Details />
    </>
  );
};
