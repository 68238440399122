// noinspection SpellCheckingInspection
const de = {
  translation: {
    "Username or e-mail": "Benutzername oder e-mail",
    "Password": "Passwort",
    "Stay logged in": "Bleibe eingeloggt",
    "Forgot your password?": "Haben Sie Ihr Passwort vergessen?",
    "Sign in": "Anmelden",
    "Unknown": "Unbekannt",
    "English": "Englisch",
    "Dutch": "Niederländisch",
    "German": "Deutsch",
    "French": "Français",
    "is required": "ist nötig",
    "Home": "zu Hause",
    "logo": "Logo",
    "Sign out": "Ausloggen",
    "Powered by": "Unterstützt von",
    "Under construction": "In Bearbeitung",
    "Rows per page": "Zeilen pro Seite",
    "Assets": "Vermögenswerte",
    "Asset map": "Map der Vermögenswerte",
    "of": "von",
    "more than": "mehr als",
    "ID": "ID",
    "Name": "Name",
    "Street": "Straße",
    "Postal code": "Postleitzahl",
    "City": "Stadt",
    "Region": "Region",
    "Country": "Land",
    "Days still": "Tage noch",
    "Last communication": "Letzte Mitteilung",
    "Last location update": "Letzte Standortaktualisierung",
    "Device last communication": "Letzte Mitteilung",
    "Geo zone": "Geozone",
    "Client": "Kunde",
    "Asset group": "Asset Gruppe",
    "Description": "Beschreibung",
    "Days outside geo zone": "Leihtage",
    "No problems": "Keine Probleme",
    "User not found or wrong password":
      "Benutzer nicht gefunden oder falsches Passwort",
    "Address": "Adresse",
    "Asset": "Vermögenswert",
    "Last page": "Letzte Seite",
    "Next page": "Nächste Seite",
    "Previous page": "Vorherige Seite",
    "First page": "Erste Seite",
    "Search": "Suche",
    "Advanced filter": "Erweiterter Filter",
    "New": "Neu",
    "Events": "Standortaktualisierungen",
    "Devices": "Vorrichtungen",
    "Add new": "Neu hinzufügen",
    "Delete": "Löschen",
    "Link to Group": "Link zur Gruppe",
    "Link to Client": "Link zum Kunden",
    "Selected": "Ausgewählt",
    "There was an error while trying to fetch the events from the asset":
      "Beim Versuch, die Ereignisse aus dem Vermögenswert abzurufen, ist ein Fehler aufgetreten",
    "Not in a zone": "Nicht in einer Zone",
    "Interval-based": "Intervallbasiert",
    "Clients": "Kunden",
    "Asset groups": " Gruppen von Vermögenswerten",
    "Type": "Typ",
    "External ID": "Externe ID",
    "Empty": "Leer",
    "Device activation date": "Device Aktivierungsdatum",
    "Model": "Modell",
    "DEVEUI": "DEVEUI",
    "Barcode": "Barcode",
    "Notes": "Anmerkungen",
    "Device": "Device",
    "Page not found": "Seite nicht gefunden",
    "From": "Von",
    "Invalid date format": "Ungültiges Datumsformat",
    "To": "Zu",
    "Asset updated": "Vermögenswert aktualisiert",
    "Asset deleted": "Vermögenwert gelöscht",
    "Loading": "Wird geladen",
    "Try again": "Versuchen Sie es nochmal",
    "All": "Alle",
    "Start": "Start",
    "Moving": "umziehen",
    "Still": "Immer noch",
    "Stopped": "Gestoppt",
    "Interval-based-Local-Gateway": "Intervallbasiertes lokales Gateway",
    "Users": "Benutzer",
    "ROLE_USER": "Nutzer",
    "ROLE_ADMIN": "Administrator",
    "ROLE_CLIENT": "Kunde",
    "ROLE_TRANSPORTER": "Transporter",
    "ROLE_ACCOUNT_MANAGER": "Account Manager",
    "User": "Nutzer",
    "Username": "Nutzername",
    "Role": "Rolle",
    "critical": "Kritisch",
    "major": "Haupt",
    "minor": "Geringer",
    "warning": "Warnung",
    "none": "Keiner",
    "no items": "Keine Gegenstände",
    "add new item": "Neues element hinzufügen",
    "The file will be uploaded when you save the new asset":
      "Die Datei wird hochgeladen, wenn Sie das neue Asset speichern",
    "There was an error while trying to retrieve the attachment":
      "Beim Abrufen des Anhangs ist ein Fehler aufgetreten",
    "There was an error while trying to upload the attachment":
      "Beim Versuch, den Anhang hochzuladen, ist ein Fehler aufgetreten",
    "Drag and drop files here": "Ziehen Sie hier Dateien per Drag & Drop",
    "or": "oder",
    "Browse files": "Dateien durchsuchen",
    "Release the file to attach it":
      "Geben Sie die Datei frei, um sie anzuhängen",
    "User added": "Benutzer hinzugefügt",
    "Status": "Status",
    "There was an error while trying to fetch the files credentials":
      "Beim Abrufen der Anmeldeinformationen der Dateien ist ein Fehler aufgetreten",
    "Asset not found": "Asset nicht gefunden",
    "Save": "Sparen",
    "Save asset profile": "Möchten Sie die Änderungen wirklich speichern?",
    "Import devices": "Devices importieren",
    "Alarm rules": "Alarmregeln",
    "Alarm rule added": "Alarmregel hinzugefügt",
    "Email": "E-Mail",
    "Asset tag is sabotaged": "Asset-Tag wird sabotiert",
    "Asset is reported as missing": "Vermögenswert wird als fehlend gemeldet",
    "Asset is outside the Geo Zone":
      "Der Vermögenswert befindet sich außerhalb der Geozone",
      "Asset has battery level low": "Der Akkustand des Geräts ist niedrig",
    "Alarm rule": "Alarmregel",
    "The name is required": "Der Name ist erforderlich",
    "The email needs to be valid": "Die E-Mail muss gültig sein",
    "Sabotage has been detected with": "Sabotage wurde entdeckt mit",
    "Low battery has been detected for": "Schwache Batterie wurde erkannt für",
    "has been detected outside geozone": "wurde außerhalb der Geozone entdeckt",
    "Text": "Text",
    "Alarm rule updated": "Alarmregel aktualisiert",
    "Sensor name": "Sensorname",
    "Change": "Veränderung",
    "Active": "Aktiv",
    "Roles": "Rollen",
    "Change my password": "Ändere mein Passwort",
    "No result found": "Keine Einträge gefunden",
    "Edit": "Bearbeiten",
    "User deleted": "Benutzer gelöscht",
    "Filter": "Filter",
    "Must be a number": "Muss eine Nummer sein",
    "Must be a valid email": "Muss eine gültige E-Mail sein",
    "Are you sure you want to delete":
      "Sind Sie sicher, dass Sie löschen möchten",
    "Cancel": "Stornieren",
    "Fill all the required fields": "Füllen Sie alle erforderlichen Felder aus",
    "Devices imported": "Gerät importiert",
    "Select one type": "Wählen Sie einen Typ",
    "The text is required": "Der Text ist erforderlich",
    "Device deleted": "Gerät gelöscht",
    "Device updated": "Gerät aktualisiert",
    "You don't have any assets to show on the map":
      "Sie können keine Vermögenswerte auf der Karte anzeigen",
    "Drone no-fly areas": "Flugverbotsbereiche für Drohnen",
    "Fine dust": "Feiner Staub",
    "Foundation problems": "Gründungsprobleme",
    "Height map": "Höhenkarte",
    "Electricity pylons": "Strommasten",
    "Quality of life card": "Lebensqualitätskarte",
    "Premises": "Räumlichkeiten",
    "Parcels": "Grundstücke",
    "Map layers": "Kartenebenen",
    "Company": "Unternehmen",
    "Logical ID": "Logical ID",
    "Reference card": "Referenzkarte",
    "Map": "Karte",
    "Satelite": "Satellit",
    "Satelite with Labels": "Satellit mit Etiketten",
    "Clear": "Klar",
    "Asset is sabotaged": "Vermögenswert wird sabotiert",
    "Create": "Erstellen",
    "Switch to tenant {{name}}": "Wechseln Sie zum Mieter {{name}}",
    "#": "#",
    "Subdomain": "Subdomain",
    "Zip code": "Postleitzahl",
    "Phone": "Telefon",
    "Tenants": "Mieter",
    "Add role": "Rolle hinzufügen",
    "Enter the role name": "Geben Sie den Rollennamen ein",
    "Search for an ID": "Suchen Sie nach einer ID",
    "Create tenant": "Mieter erstellen",
    "Yes": "Ja",
    "No": "Nein",
    "Are you sure you want to delete?":
      "Sind Sie sicher, dass Sie löschen möchten?",
    "Edit tenant": "Mieter einfügen",
    "Drag and drop the icon here or click here to select the icon":
      "Ziehen Sie das Symbol hierher und legen Sie es ab oder klicken Sie hier, um das Symbol auszuwählen",
    "Tenant created": "Mieter erstellt",
    "Tenant updated": "Mieter aktualisiert",
    "Tenant deleted": "Mieter gelöscht",
    "Tenant not found": "Mieter nicht gefunden",
    "Create user": "Benutzer erstellen",
    "Preferences": "Einstellungen",
    "Employee number": "Mitarbeiternummer",
    "Full name": "Vollständiger Name",
    "New password": "Neues Passwort",
    "Edit user": "Benutzer bearbeiten",
    "Details": "Einzelheiten",
    "Select role": "Rolle auswählen",
    "Tenant roles": "Mieter rollen",
    "Add new tenant": "Neuen Mieter hinzufügen",
    "Select a tenant": "Wählen Sie einen Mieter aus",
    "Tenant": "Mieter",
    "User updated": "Benutzer aktualisiert",
    "Customers": "Kunden",
    "Customer": "Kunde",
    "New Customer": "Neukunde",
    "Not assigned to a client": "Nicht einem Kunden zugeordnet",
    "GeoZone added": "GeoZone hinzugefügt",
    "Report empty buck": "Bericht leer",
    "Asset is signed off": "Vermögenswert ist abgemeldet",
    "Open": "Öffnen",
    "Report delivered buck": "Geliefert",
    "Asset is delivered": "Asset wird geliefert",
    "Delivered": "Geliefert",
    "Schedule": "Zeitplan",
    "Scheduled": "Geplant",
    "Date": "Datum",
    "Reset password": "Passwort zurücksetzen",
    "An email is send to {{email}}": "Eine E-Mail wird an {{email}} gesendet",
    "Back to login": "Gehen Sie zurück zur Anmeldeseite",
    "Reference": "Referenz",
    "ASSET_CREATE": "Assets erstellen",
    "ASSET_READ": "Assets anzeigen",
    "ASSET_EDIT": "Assets bearbeiten",
    "ASSET_DELETE": "Assets löschen",
    "ALARM_CREATE": "Alarme erstellen",
    "ALARM_READ": "Alarme anzeigen",
    "ALARM_EDIT": "Alarme bearbeiten",
    "ALARM_DELETE": "Alarme löschen",
    "DEVICE_CREATE": "Geräte erstellen",
    "DEVICE_READ": "Geräte anzeigen",
    "DEVICE_EDIT": "Geräte bearbeiten",
    "DEVICE_DELETE": "Geräte löschen",
    "RESET_LOAN_DAYS": "Leihtage zurücksetzen",
    "reset_loan_days_message": "Möchten Sie die Ausleihtage wirklich zurücksetzen?",
    "VIEW_ASSET_MAP": "Asset-Map anzeigen",
    "USER_CREATE": "Benutzer erstellen",
    "USER_READ": "Benutzer anzeigen",
    "USER_EDIT": "Benutzer bearbeiten",
    "USER_DELETE": "Benutzer löschen",
    "PERMISSION_CREATE": "Rollen erstellen",
    "PERMISSION_READ": "Rollen ansehen",
    "PERMISSION_EDIT": "Rollen bearbeiten",
    "PERMISSION_DELETE": "Rollen löschen",
    "CUSTOMER_CREATE": "Kunden erstellen",
    "CUSTOMER_READ": "Kunden ansehen",
    "CUSTOMER_EDIT": "Kunde bearbeiten",
    "CUSTOMER_DELETE": "Kunden entfernen",
    "ASSET_PROFILE_READ": "Asset-Profil anzeigen",
    "ASSET_PROFILE_EDIT": "Asset-Profil lesen",
    "CABLE_REEL_RESET": "Kabellänge zurücksetzen",
    "Permissions": "Rollen und Rechte",
    "Battery status": "Batterie",
    "Battery value low": "Batteriewert niedrig",
    "Cable reel profile": "Kabeltrommelprofil",
    "cable_type": "Kabelart",
    "cable_reel_type": "Rollentyp",
    "cable_length": "Kabellänge (m)",
    "cable_diameter": "Kabeldurchmesser (mm)",
    "cable_reel_width": "Wickelbreite (mm)",
    "cable_reel_diameter_min": "Kerndurchmesser (mm)",
    "cable_reel_diameter_max": "Spulendurchmesser (mm)",
    "cable_residual_length": "Kabelrestlänge",
    "Device profiles": "Geräteprofile",
    "cable_length_reset_message": "Möchten Sie die Länge wirklich zurücksetzen?",
    "Account Manager": "Account Manager",
    "Not assigned to a account manager": "Keinem Account Manager zugewiesen",
    "The client will receive an email with login credentials.": "Der Kunde erhält eine E-Mail mit den Zugangsdaten.",
    "e.g.": "z.B.",
    "Asset profiles": "Asset profiles",
  },
};

export default de;
