import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import React, { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { AssetFilter } from "../Asset";
import {
  assetsFilterSelector,
  assetsIsLoadingSelector,
  setFilter
} from "../assetsSlice";

export const Filter: FunctionComponent<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });
  const dispatch = useDispatch();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const isLoading = useSelector(assetsIsLoadingSelector);
  const filter = useSelector(assetsFilterSelector);

  return (
    <SidePanelModal
      title={t("Filter")}
      open={open}
      onClose={onClose}
      width={theme.spacing(50)}
    >
      <DialogContent>
        <Formik
          initialValues={
            {
              id: "" as any,
              logicalId: "" as any,
              name: "",
              street: "",
              city: "",
              country: "",
              geo_zone: "",
              daysOutsideGeoZone: "",
              scheduled: null,
              ...filter,
            } as AssetFilter
          }
          validationSchema={Yup.object().shape<AssetFilter>({
            id: Yup.number().nullable(true).integer(t("Must be a number")),
            logicalId: Yup.number()
              .nullable(true)
              .integer(t("Must be a number")),
            name: Yup.string(),
            street: Yup.string(),
            city: Yup.string(),
            country: Yup.string(),
            geo_zone: Yup.string(),
            daysOutsideGeoZone: Yup.number(),
            scheduled: Yup.string().nullable(true),
          })}
          onSubmit={(values) => {
            if (values.scheduled instanceof Date) {
              values.scheduled = values.scheduled?.toISOString();
            }
            dispatch(setFilter(values));
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name={"logicalId"}
                    label={t("ID")}
                    type={isMobile ? "tel" : "number"}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="name"
                    label={t("Asset")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="['devices.events.street']"
                    label={t("Street")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="['devices.events.postalCode']"
                    label={t("Postal code")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="['devices.events.city']"
                    label={t("City")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="['devices.events.country']"
                    label={t("Country")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="reference"
                    label={t("Reference")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={(props) => (
                      <KeyboardDatePicker
                        {...props}
                        format="dd-MM-yyyy"
                        clearable={true}
                        inputVariant="filled"
                        required={false}
                        onAccept={(date) => {
                          date?.setHours(12);
                          date?.setMinutes(0);
                          date?.setSeconds(0);
                        }}
                      />
                    )}
                    variant="filled"
                    fullWidth
                    required={false}
                    name="['scheduled']"
                    label={t("Scheduled")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="['devices.events.geoZone']"
                    label={t("Geo zone")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="daysOutsideGeoZone"
                    label={t("Days outside geo zone")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="['client.company']"
                    label={t("Client")}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
              <button
                ref={submitButtonRef}
                type="submit"
                style={{ border: 0, padding: 0 }}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(setFilter({}));
            onClose();
          }}
          disabled={isLoading}
        >
          {t("Clear")}
        </Button>
        <Button
          onClick={() => {
            submitButtonRef.current?.click();
            // onClose();
          }}
          type="submit"
          color="primary"
          variant="contained"
          disableElevation
          disabled={isLoading}
        >
          {t("Filter")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};
