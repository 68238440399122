import { Api } from "../../store/Api";
import { User } from "./User";


class UserApi extends Api<User, User> {
  public constructor() {
    super("/api/users");
  }
}

export const userApi = new UserApi();