import React, { createElement, FunctionComponent, useState } from "react";
import { useDebounce } from "react-use";

const DebounceOnChange: FunctionComponent<{
  component?: React.ElementType;
  debounce?: number;
  onChange?: (event: React.ChangeEvent<any>) => void;
  value?: any;

  [key: string]: any;
}> = ({ debounce, onChange, value, component, ...props }) => {
  debounce = debounce || 300;
  const [val, setVal] = useState<React.ChangeEvent<HTMLInputElement>>();
  const [innerValue, setInnerVal] = useState<any>(value);

  useDebounce(
    () => {
      onChange && val && onChange(val);
    },
    debounce,
    [val]
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setVal(event);
    setInnerVal(event.target.value);
  };

  return createElement(component as any, {
    ...props,
    onChange: handleSearch,
    value: innerValue,
  });
};

export default DebounceOnChange;
