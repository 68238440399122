import { ObjectSchema } from "yup";

export default function getFieldsFromSchema<T extends { [key: string]: any }>(
  Schema: ObjectSchema<T>
) {
  return Object.entries(Schema.fields).map(([key, field]) => ({
    name: key,
    label: field.describe().label,
    required:
      field
        .describe()
        .tests.findIndex((test: { name: string }) => test.name === "required") >
      -1,
    meta: field.describe().meta,
  })) as {
    name: keyof typeof Schema.fields;
    label: string;
    required: boolean;
    meta: any;
  }[];
}
