import { ApiState, ApiStore, Entity, EntityFilter } from "../../store/ApiStore";

export declare type Permission = {
  "@id": string;
  "#type": string;
  id: number;
  permission: string;
};

export declare interface Role extends Entity {
  "@id"?: string;
  "#type"?: string;
  id?: number;
  name: string;
  role_permissions: Permission[];
  is_user_defined?: boolean;
}

export declare interface RoleFilter extends EntityFilter {
  id?: number | null;
  name?: string;
}

declare interface State extends ApiState<Role, RoleFilter> {}

export class RoleStore extends ApiStore<Role, RoleFilter, State> {
  public constructor() {
    super("/api/roles", ({} as unknown) as State, {});
  }
}

const roleStore = new RoleStore();

export { roleStore };
