export default function clearEmptyProperties<
  T extends { [key: string]: any } | null | undefined
>(object: T): T {
  object = object ? JSON.parse(JSON.stringify(object)) : object;
  if (object) {
    Object.entries(object!).forEach(([key, value]) => {
      if (value === null || value === undefined || value === "") {
        delete object![key];
      }
    });
  }
  return object;
}
