import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { setSelectedEvent } from "../Assets/openAssetSlice";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { Event, Type } from "./Event";

interface EventsState {
  isLoading: boolean;
  errorMessage: string | undefined;
  events: Event[];
  totalItems: number;
  page: number;

  fromDate: string | null;
  toDate: string | null;
  type: Type;
}

const initialState: EventsState = {
  isLoading: false,
  errorMessage: undefined,
  events: [],
  totalItems: 0,
  page: 0,

  fromDate: null,
  toDate: null,
  type: "",
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<EventsState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<EventsState["errorMessage"]>) {
      state.errorMessage = action.payload;
    },
    setEvents(state, action: PayloadAction<EventsState["events"]>) {
      state.events = action.payload;
    },

    setFromDate(state, action: PayloadAction<EventsState["fromDate"]>) {
      state.fromDate = action.payload;
    },
    setToDate(state, action: PayloadAction<EventsState["toDate"]>) {
      state.toDate = action.payload;
    },
    setType(state, action: PayloadAction<EventsState["type"]>) {
      state.type = action.payload;
    },
    reset() {
      return initialState;
    },
    setTotalItems(state, action: PayloadAction<EventsState["totalItems"]>) {
      state.totalItems = action.payload;
    },
    setPage(state, action: PayloadAction<EventsState["page"]>) {
      state.page = action.payload;
    },
  },
});

export const eventsReducer = eventsSlice.reducer;

const {
  setEvents,
  setLoading,
  setErrorMessage,
  setFromDate: setFromDateAction,
  setToDate: setToDateAction,
  setType: setTypeAction,
  reset,
  setTotalItems,
  setPage: setPageAction,
} = eventsSlice.actions;
export { reset };

export const setPage =
  (page: EventsState["page"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setPageAction(page));
      dispatch(fetchEvents());
    });
  };

export const setFromDate =
  (fromDate: EventsState["fromDate"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setFromDateAction(fromDate));
      dispatch(fetchEvents());
    });
  };

export const setToDate =
  (toDate: EventsState["toDate"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setToDateAction(toDate));
      dispatch(fetchEvents());
    });
  };

export const setType =
  (type: EventsState["type"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setTypeAction(type));
      dispatch(fetchEvents());
    });
  };

export const fetchEvents = (): AppThunk => async (dispatch, getState) => {
  const devices = getState().openAsset.openAsset?.devices ?? [];
  const device = devices.length > 0 ? devices[0] : undefined;

  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  try {
    let data: Event[] = [];
    let totalItems = 0;

    if (device !== undefined) {
      const response = await Axios.get<{
        "hydra:totalItems": number;
        "hydra:member": Event[];
      }>(
        `/api/events?device.id=${device?.id}&order[date]=desc&order[id]=desc`,
        {
          params: {
            fromDate: getState().events.fromDate,
            toDate: getState().events.toDate,
            type: getState().events.type || undefined,
            page: getState().events.page + 1,
          },
        }
      );
      totalItems = response.data["hydra:totalItems"];
      data = response.data["hydra:member"];
    }
    batch(() => {
      dispatch(setEvents(data));
      dispatch(setTotalItems(totalItems));
      const filteredEvents = data.filter(
        (event) => !!event.latitude && !!event.longitude
      );
      if (!!filteredEvents[0]) {
        dispatch(setSelectedEvent(filteredEvents[0]));
      }
      dispatch(setLoading(false));
    });
  } catch (err) {
    batch(() => {
      dispatch(setLoading(false));
      dispatch(
        setErrorMessage(
          "There was an error while trying to fetch the events from the asset"
        )
      );
    });
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const eventsSelector = (state: RootState) => state.events.events;
export const eventsIsLoadingSelector = (state: RootState) =>
  state.events.isLoading;
export const eventsErrorMessageSelector = (state: RootState) =>
  state.events.errorMessage;
export const eventsFromDateSelector = (state: RootState) =>
  state.events.fromDate;
export const eventsToDateSelector = (state: RootState) => state.events.toDate;
export const eventsTypeSelector = (state: RootState) => state.events.type;
export const eventsTotalItemsSelector = (state: RootState) =>
  state.events.totalItems;
export const eventsPageSelector = (state: RootState) => state.events.page;
