import { formatDistance } from "date-fns";
import React, { FunctionComponent } from "react";
import { useInterval, useUpdate } from "react-use";

const DateFnsDistanceNow: FunctionComponent<{
  date: Date | number | string;
  includeSeconds?: boolean;
  addSuffix?: boolean;
  locale?: Locale;
}> = ({ date, includeSeconds, addSuffix, locale }) => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  const update = useUpdate();
  useInterval(update, (includeSeconds ? 4 : 59) * 1000);

  if (date === null) {
    return <></>;
  }

  return (
    <>
      {formatDistance(date, new Date(), { includeSeconds, addSuffix, locale })}
    </>
  );
};

export default DateFnsDistanceNow;
