import {
  Button,
  LinearProgress,
  ListSubheader,
  MenuItem
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../../../../common/components/Menu/Menu";
import { User } from "../../User";
import {
  removeUser,
  usersIsLoadingSelector,
  usersOpenUserSelector
} from "../../usersSlice";

export const DeleteButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let openUser = useSelector(usersOpenUserSelector);
  const isLoading = useSelector(usersIsLoadingSelector);

  const deleteUser = () => {
    dispatch(removeUser(openUser as User));
  };

  return (
    <Menu
      // open={isLoading ? true : undefined}
      MenuListProps={{
        subheader: (
          <ListSubheader component="div">
            {isLoading && (
              <LinearProgress
                style={{
                  marginBottom: -4,
                  marginLeft: -16,
                  marginRight: -16,
                  zIndex: 3,
                }}
              />
            )}
            {t("Are you sure you want to delete?")}
          </ListSubheader>
        ),
      }}
      items={(closeMenu) => [
        <MenuItem disabled={isLoading} onClick={() => deleteUser()}>
          {t("Yes")}
        </MenuItem>,
        <MenuItem disabled={isLoading} onClick={closeMenu}>
          {t("No")}
        </MenuItem>,
      ]}
    >
      <Button startIcon={<DeleteRounded />}>{t("Delete")}</Button>
    </Menu>
  );
};
