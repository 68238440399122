import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { Event } from "../Events/Event";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { Asset, Attachment } from "./Asset";
import { setLoading } from "./assetsSlice";

interface OpenAssetState {
  openAsset?: Partial<Asset>;
  currentTab: number;
  selectedEvent?: Event;
  isUploadingAttachment: boolean;
  acceptedFiles: File[];
}

const initialState: OpenAssetState = {
  openAsset: undefined,
  currentTab: 0,
  selectedEvent: undefined,
  isUploadingAttachment: false,
  acceptedFiles: [],
};

export const openAssetSlice = createSlice({
  name: "openAsset",
  initialState,
  reducers: {
    setOpenAsset(state, action: PayloadAction<OpenAssetState["openAsset"]>) {
      if (state.openAsset?.id !== action.payload?.id) {
        state.currentTab = 0;
      }
      state.openAsset = action.payload;
      state.acceptedFiles = [];
    },
    setCurrentTab(state, action: PayloadAction<OpenAssetState["currentTab"]>) {
      state.currentTab = action.payload;
    },
    setSelectedEvent(
      state,
      action: PayloadAction<OpenAssetState["selectedEvent"]>
    ) {
      state.selectedEvent = action.payload;
    },
    updateOpenAsset(state, action: PayloadAction<OpenAssetState["openAsset"]>) {
      state.openAsset = { ...state.openAsset, ...action.payload };
    },
    setIsUploadingAttachment(
      state,
      action: PayloadAction<OpenAssetState["isUploadingAttachment"]>
    ) {
      state.isUploadingAttachment = action.payload;
    },
    setAcceptedFiles(
      state,
      action: PayloadAction<OpenAssetState["acceptedFiles"]>
    ) {
      state.acceptedFiles = action.payload;
    },
  },
});

export const openAssetReducer = openAssetSlice.reducer;

const {
  setOpenAsset,
  setCurrentTab,
  setSelectedEvent,
  updateOpenAsset,
  setIsUploadingAttachment,
  setAcceptedFiles,
} = openAssetSlice.actions;
export {
  setOpenAsset,
  setCurrentTab,
  setSelectedEvent,
  updateOpenAsset,
  setAcceptedFiles,
};

export const fetchAndOpenAsset = (id: Asset["id"]): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.get<Asset>(`/api/assets/${id}`);
    batch(() => {
      dispatch(setOpenAsset(response.data));
      dispatch(setLoading(false));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const addAttachment = (
  id: Asset["id"],
  attachment: Attachment
): AppThunk => async (dispatch) => {
  dispatch(setIsUploadingAttachment(true));
  try {
    await Axios.post(`/api/assets/${id}/attachments`, attachment);
    dispatch(fetchAndOpenAsset(id));
  } catch (err) {
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  } finally {
    dispatch(setIsUploadingAttachment(false));
  }
};

export const deleteAttachment = (
  id: Asset["id"],
  attachment: Attachment
): AppThunk => async (dispatch) => {
  dispatch(setIsUploadingAttachment(true));
  try {
    await Axios.delete(`/api/assets/${id}/attachments/${attachment.id}`);
    dispatch(fetchAndOpenAsset(id));
  } catch (err) {
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  } finally {
    dispatch(setIsUploadingAttachment(false));
  }
};

export const openAssetSelector = (state: RootState) =>
  state.openAsset.openAsset;
export const openAssetCurrentTabSelector = (state: RootState) =>
  state.openAsset.currentTab;
export const openAssetSelectedEventSelector = (state: RootState) =>
  state.openAsset.selectedEvent;
export const openAssetAcceptedFilesSelector = (state: RootState) =>
  state.openAsset.acceptedFiles;
