import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { Customer, CustomerFilter } from "./Customer";

interface CustomersState {
  isLoading: boolean;
  errorMessage: string | undefined;
  customers: Customer[];
  openCustomer?: Partial<Customer>;
  search: string;
  selectedCustomers: Customer["id"][];
  filter: CustomerFilter;
  totalItems: number;
  page: number;
}

const initialState: CustomersState = {
  isLoading: false,
  errorMessage: undefined,
  customers: [],
  openCustomer: undefined,
  search: "",
  selectedCustomers: [],
  filter: {},
  totalItems: 0,
  page: 0,
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<CustomersState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(
      state,
      action: PayloadAction<CustomersState["errorMessage"]>
    ) {
      state.errorMessage = action.payload;
    },
    setCustomers(state, action: PayloadAction<CustomersState["customers"]>) {
      state.customers = action.payload;
    },
    setOpenCustomer(
      state,
      action: PayloadAction<CustomersState["openCustomer"]>
    ) {
      state.openCustomer = action.payload;
    },
    updateOpenCustomer(
      state,
      action: PayloadAction<CustomersState["openCustomer"]>
    ) {
      state.openCustomer = { ...state.openCustomer, ...action.payload };
    },
    setSearch(state, action: PayloadAction<CustomersState["search"]>) {
      state.search = action.payload;
    },
    toggleSelectedCustomer(state, action: PayloadAction<Customer>) {
      if (state.selectedCustomers.includes(action.payload.id)) {
        state.selectedCustomers.splice(
          state.selectedCustomers.findIndex((a) => a === action.payload.id),
          1
        );
      } else {
        state.selectedCustomers.push(action.payload.id);
      }
    },
    setFilter(state, action: PayloadAction<CustomersState["filter"]>) {
      state.filter = action.payload;
    },
    setTotalItems(state, action: PayloadAction<CustomersState["totalItems"]>) {
      state.totalItems = action.payload;
    },
    setPage(state, action: PayloadAction<CustomersState["page"]>) {
      state.page = action.payload;
    },
  },
});

export const customersReducer = customersSlice.reducer;

const {
  setCustomers,
  setLoading,
  setOpenCustomer,
  setErrorMessage,
  updateOpenCustomer,
  setSearch: setSearchAction,
  toggleSelectedCustomer,
  setFilter: setFilterAction,
  setTotalItems,
  setPage: setPageAction,
} = customersSlice.actions;
export {
  toggleSelectedCustomer,
  setLoading,
  setOpenCustomer,
  updateOpenCustomer,
};

export const setPage = (page: CustomersState["page"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(page));
    dispatch(fetchCustomers());
  });
};

export const setSearch = (search: CustomersState["search"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(0));
    dispatch(setSearchAction(search));
    dispatch(fetchCustomers());
  });
};

export const setFilter = (filter: CustomersState["filter"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(0));
    dispatch(setFilterAction(filter));
    dispatch(fetchCustomers());
  });
};

export const fetchCustomers = (): AppThunk => async (dispatch, getState) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  try {
    const response = await Axios.get<{
      "hydra:totalItems": number;
      "hydra:member": Customer[];
    }>(`/api/clients`, {
      params: {
        search: getState().customers.search,
        page: getState().customers.page + 1,
        ...getState().customers.filter,
      },
    });
    const totalItems = response.data["hydra:totalItems"];
    const customers = response.data["hydra:member"];
    batch(() => {
      dispatch(setCustomers(customers));
      dispatch(setTotalItems(totalItems));
      dispatch(setLoading(false));
    });
  } catch (err) {
    batch(() => {
      dispatch(setLoading(false));
      dispatch(
        setErrorMessage(
          "There was an error while trying to fetch the customers list"
        )
      );
    });
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const addCustomer = (customer: Partial<Customer>): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  try {
    await Axios.post<Customer>(`/api/clients`, {
      ...customer,
    });
    batch(() => {
      dispatch(setOpenCustomer(undefined));
      dispatch(fetchCustomers());
      dispatch(
        enqueueSnackbar({
          message: "Customer added",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const updateCustomer = (customer: Customer): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.put(`/api/clients/${customer.id}`, {
      ...customer,
    });
    batch(() => {
      dispatch(fetchCustomers());
      dispatch(
        enqueueSnackbar({
          message: "Customer updated",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const removeCustomer = (customer: Customer): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.delete(`/api/clients/${customer.id}`);
    batch(() => {
      dispatch(fetchCustomers());
      dispatch(setOpenCustomer(undefined));
      dispatch(
        enqueueSnackbar({
          message: "Customer deleted",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const sendResetPassword = (customer: Customer): AppThunk => async (
  dispatch
) => {
  try {
    await Axios.post(`/api/clients/${customer.id}/resetpassword`, customer);
    batch(() => {
      dispatch(
        enqueueSnackbar({
          message: "Reset password notification is send",
        })
      );
    });
  } catch (err) {
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const customersSelector = (state: RootState) =>
  state.customers.customers;
export const customersErrorMessageSelector = (state: RootState) =>
  state.customers.errorMessage;
export const openCustomerSelector = (state: RootState) =>
  state.customers.openCustomer;
export const customersIsLoadingSelector = (state: RootState) =>
  state.customers.isLoading;
export const customersSearchSelector = (state: RootState) =>
  state.customers.search;
export const customersSelectedCustomerSelector = (state: RootState) =>
  state.customers.selectedCustomers;
export const customersFilterSelector = (state: RootState) =>
  state.customers.filter;
export const customersTotalItemsSelector = (state: RootState) =>
  state.customers.totalItems;
export const customersPageSelector = (state: RootState) => state.customers.page;
