import { Marker, MarkerProps } from "react-leaflet";
import React, { FunctionComponent } from "react";

export const ExtendedMarker: FunctionComponent<MarkerProps> = (props) => {
  const initMarker = (ref: Marker) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };

  return <Marker ref={initMarker} {...props} />;
};
