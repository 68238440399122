import {
  CombinedState,
  PayloadAction,
  SliceCaseReducers,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { batch } from "react-redux";
import axios from "axios";
import { enqueueSnackbar } from "../domains/Snackbar/snackbarSlice";
import { AppThunk, RootState } from "./store";
import { ReduxStore, State } from "./ReduxStore";

export declare type Entity = {
  id?: number | null;
};

export declare type EntityFilter = {
  id?: number | null;
  name?: string;
  groups?: string[];
  pagination?: boolean;
};

export declare interface ApiState<E extends Entity, EF extends EntityFilter>
  extends State {
  errorMessage: string | undefined;
  openEntity: E | undefined;
  entities: E[];
  filter: EF;
  totalItems: number;
  page: number;
  pagination: boolean;
  groups: string[];
  search: string;
}

export abstract class ApiStore<
  E extends Entity,
  EF extends EntityFilter,
  T extends ApiState<E, EF>
> extends ReduxStore<T> {
  protected endpoint: string;

  public readonly openEntitySelector = (rootState: RootState): E =>
    this.selector(rootState, "openEntity");
  public readonly entitiesSelector = (rootState: RootState): E[] =>
    this.selector(rootState, "entities");
  public readonly filterSelector = (rootState: RootState) =>
    this.selector(rootState, "filter");
  public readonly totalItemsSelector = (rootState: RootState) =>
    this.selector(rootState, "totalItems");
  public readonly pageSelector = (rootState: RootState) =>
    this.selector(rootState, "page");
  public readonly searchSelector = (rootState: RootState) =>
    this.selector(rootState, "search");

  protected constructor(
    endpoint: string,
    initialState?: ApiState<E, EF>,
    reducers?: SliceCaseReducers<any>
  ) {
    super(
      Object.assign(
        ({
          isLoading: false,
          errorMessage: undefined,
          openEntity: undefined,
          entities: [],
          filter: {} as EF,
          totalItems: 0,
          page: 0,
          pagination: true,
          groups: [],
          search: "",
        } as unknown) as T,
        initialState || {}
      )
    );

    this.endpoint = endpoint;

    const initialReducers = {
      setOpenEntity(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["openEntity"]>
      ) {
        state.openEntity = action.payload;
      },
      updateOpenEntity(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["openEntity"]>
      ) {
        state.openEntity = {
          ...(state.openEntity as E),
          ...(action.payload as E),
        };
      },
      setErrorMessage(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["errorMessage"]>
      ) {
        state.errorMessage = action.payload;
      },
      // setAllCategories(state, action: PayloadAction<State["allCategories"]>) {
      //   state.allCategories = action.payload;
      // },
      setEntities(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["entities"]>
      ) {
        state.entities = action.payload;
      },
      setFilter(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["filter"]>
      ) {
        state.filter = action.payload;
      },
      setTotalItems(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["totalItems"]>
      ) {
        state.totalItems = action.payload;
      },
      setPagination(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["pagination"]>
      ) {
        state.pagination = action.payload;
      },
      // setGroups(state, action: PayloadAction<State["groups"]>) {
      //   state.groups = action.payload;
      // },
      setSearch(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["search"]>
      ) {
        state.search = action.payload;
      },
      setPage(
        state: ApiState<E, EF>,
        action: PayloadAction<ApiState<E, EF>["page"]>
      ) {
        state.page = action.payload;
      },
      // setExists(state, action: PayloadAction<State["exists"]>) {
      //   state.exists = action.payload;
      // },
    };

    const storeReducers = Object.assign(initialReducers, reducers || {});
    this.addReducers(storeReducers);
  }

  public setOpenEntity = (entity: ApiState<E, EF>["openEntity"]) =>
    this.actions().setOpenEntity(entity);
  public setPagination = (pagination: ApiState<E, EF>["pagination"]) =>
    this.actions().setPagination(pagination);
  public setSearch = (search: ApiState<E, EF>["search"]): AppThunk => (
    dispatch
  ) => {
    dispatch(this.actions().setSearch(search));
    dispatch(this.fetch());
  };
  public setFilter = (filter: EF): AppThunk => (dispatch) => {
    filter = this.preFilter(filter);
    dispatch(this.actions().setFilter(filter));
  };
  public setPage = (page: ApiState<E, EF>["page"]): AppThunk => (dispatch) => {
    dispatch(this.actions().setPage(page));
    dispatch(this.fetch());
  };
  public clearEntities = () => this.actions().setEntities([]);
  public clearOpenEntity = () => this.actions().setOpenEntity(undefined);

  protected postAdd(
    dispatch: ThunkDispatch<any, any, any>,
    response?: AxiosResponse<E>
  ): void {}
  protected postUpdate(dispatch: ThunkDispatch<any, any, any>): void {}
  protected postDelete(dispatch: ThunkDispatch<any, any, any>): void {}
  protected preUpdate(entity: E): E {
    return entity;
  }

  protected preFilter(filter: EF): EF {
    return filter;
  }

  public add = (entity: E): AppThunk => async (dispatch) => {
    dispatch(this.actions().setLoading(true));
    try {
      const response = await axios.post<E>(this.endpoint, entity);

      batch(() => {
        dispatch(this.fetch());
        this.postAdd(dispatch, response);
      });
    } catch (err) {
      dispatch(this.actions().setLoading(false));
      const error: AxiosError = err;

      enqueueSnackbar(error.response?.data?.message || error.message);
    }
  };

  public fetch = (filter?: EF): AppThunk => async (dispatch, getState) => {
    try {
      if (Object.keys(getState()).includes(this.name)) {
        dispatch(this.actions().setLoading(true));
        const state: CombinedState<any> = getState();

        // deprecated state search, page, filter
        // make it work with filter parameter
        const params = !!filter
          ? filter
          : {
              search: state[this.name].search,
              page: state[this.name].page + 1,
              ...state[this.name].filter,
            };

        const response = await axios.get<{
          "hydra:totalItems": number;
          "hydra:member": T[];
        }>(`${this.endpoint}`, {
          params: params,
        });

        const entities = response.data["hydra:member"];
        const totalItems = response.data["hydra:totalItems"];

        batch(() => {
          dispatch(this.actions().setEntities(entities));
          dispatch(this.actions().setTotalItems(totalItems));
          dispatch(this.actions().setLoading(false));
        });
      }
    } catch (err) {
      batch(() => {
        dispatch(
          this.actions().setErrorMessage(
            "There was an error while trying to fetch the entity list"
          )
        );
      });
      const error: AxiosError = err;
      enqueueSnackbar(error.response?.data?.message || error.message);
    }
  };

  public update = (entity: E): AppThunk => async (dispatch) => {
    dispatch(this.actions().setLoading(true));
    try {
      entity = this.preUpdate(entity);

      await axios.put<E>(`${this.endpoint}/${entity.id}`, entity);

      batch(() => {
        dispatch(this.fetch());
        dispatch(this.actions().setOpenEntity(undefined));
        dispatch(this.actions().setLoading(false));
        this.postUpdate(dispatch);
        // enqueueSnackbar("", "Changed successfully");
      });
    } catch (err) {
      dispatch(this.actions().setLoading(false));
      const error: AxiosError = err;
      enqueueSnackbar(error.response?.data?.message || error.message);
    }
  };

  public delete = (entity: E): AppThunk => async (dispatch, getState) => {
    dispatch(this.actions().setLoading(true));
    try {
      await axios.delete(`${this.endpoint}/${entity.id}`);
      batch(() => {
        dispatch(this.fetch());
        dispatch(this.clearOpenEntity());
        this.postDelete(dispatch);
        // enqueueSnackbar("Deleted successfully");
      });
    } catch (err) {
      dispatch(this.actions().setLoading(false));
      const error: AxiosError = err;
      enqueueSnackbar(error.response?.data?.message || error.message);
    }
  };

  public fetchAndOpenEntity = (id: E["id"], filter?: EF): AppThunk => async (
    dispatch,
    getState
  ) => {
    dispatch(this.actions().setLoading(true));
    try {
      if (id !== undefined) {
        const response = await axios.get<T>(`${this.endpoint}/${id}`, {
          params: {
            groups: filter?.groups || [],
          },
        });
        batch(() => {
          dispatch(this.actions().setOpenEntity(response.data));
          dispatch(this.actions().setLoading(false));
        });
      }
    } catch (err) {
      dispatch(this.actions().setLoading(false));
      const error: AxiosError = err;
      enqueueSnackbar(error.response?.data?.message || error.message);
    }
  };
}
