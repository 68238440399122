import React, { FunctionComponent, ReactNode } from "react";
import {
  Box,
  DialogTitle,
  Drawer,
  DrawerProps,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialogModal: {
    //   pointerEvents: "none",
  },
  dialogPaper: {
    //   pointerEvents: "all",
  },
  dialogTitle: {
    display: "flex",
    "& h6": {
      textTransform: "initial",
    },
  },
  dialogCloseButton: {
    marginRight: -theme.spacing(2),
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
  },
}));

const SidePanelModal: FunctionComponent<
  DrawerProps & {
    icon?: ReactNode;
    title: string | ReactNode;
    width?: number;
    loading?: boolean;
  }
> = ({ children, icon, title, width, loading, ...props }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      ModalProps={{
        // hideBackdrop: true,
        // disableScrollLock: true,
        BackdropProps: {
          invisible: true,
        },
      }}
      classes={{
        modal: classes.dialogModal,
        paper: classes.dialogPaper,
      }}
      PaperProps={
        width !== undefined
          ? { style: { maxWidth: width, width: "100%" } }
          : undefined
      }
      {...props}
    >
      {loading && <LinearProgress style={{ marginBottom: -4 }} />}
      <DialogTitle className={classes.dialogTitle} disableTypography>
        {icon && (
          <Box display="inline-flex" alignItems="center" mr={2}>
            {icon}
          </Box>
        )}
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton
          className={classes.dialogCloseButton}
          onClick={(event) =>
            props.onClose ? props.onClose(event, "backdropClick") : undefined
          }
          autoFocus
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {children}
    </Drawer>
  );
};

export default SidePanelModal;
