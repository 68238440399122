export const ALARM_TYPES: {
  [key: string]: string;
} = {
  0: "Asset tag is sabotaged",
  1: "Asset is outside the Geo Zone",
  2: "Asset has battery level low",
};

export interface AlarmRule {
  id: number;
  name: string;
  type: keyof typeof ALARM_TYPES;
  text: string;
  email?: string;
}
