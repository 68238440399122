import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Link as MuiLink,
  OutlinedInputProps,
  Typography,
} from "@material-ui/core";
import {
  darken,
  fade,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { PersonRounded, SvgIconComponent } from "@material-ui/icons";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField, TextFieldProps } from "formik-material-ui";
import React, { FunctionComponent } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMount } from "react-use";
import useTitle from "../../../common/hooks/useTitle";
import LanguageSelect from "../../../components/LanguageSelect/LanguageSelect";
import { UserCredentials } from "../../User/User";
import {
  authIsLoadingSelector,
  resetPassword,
  resetPasswordSuccessSelector,
  setResetPasswordSuccess,
} from "../authSlice";
import background from "./../background.png";
import logo from "./../logo.svg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: "100%",
    maxWidth: theme.spacing(57),
    borderRadius: theme.spacing(1),
  },
  logo: {
    width: theme.spacing(29),
  },
  signInButton: {
    borderRadius: theme.spacing(3),
  },
}));

const useStylesRedditLabel = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(4.5),
    transform: "translate(12px, 21px) scale(1)",
  },
  focused: {
    transform: "translate(12px, 10px) scale(0.75)",
  },
}));

const useStylesRedditInput = makeStyles((theme: Theme) => {
  const fn = theme.palette.type === "light" ? darken : lighten;

  return {
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: fn(theme.palette.background.default, 0.01),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: fn(theme.palette.background.default, 0.03),
      },
      display: "flex",
      flexDirection: "row-reverse",
    },
    focused: {
      backgroundColor: fn(theme.palette.background.default, 0.03),
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    error: {
      boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.error.main,
    },
  };
});

const useStylesReddit = makeStyles((theme: Theme) => ({
  adornment: {
    marginLeft: theme.spacing(1.5),
  },
}));

const RedditTextField: FunctionComponent<
  TextFieldProps & {
    icon: SvgIconComponent;
  }
> = ({ icon: Icon, ...props }) => {
  const classesLabel = useStylesRedditLabel();
  const classesInput = useStylesRedditInput();
  const classes = useStylesReddit();

  return (
    <TextField
      InputLabelProps={{
        classes: classesLabel,
        shrink: undefined,
      }}
      InputProps={
        {
          classes: classesInput,
          disableUnderline: true,
          endAdornment: (
            <Icon className={classes.adornment} color={"inherit"} />
          ),
        } as Partial<OutlinedInputProps>
      }
      {...props}
    />
  );
};

export const ResetPassword = () => {
  const { t } = useTranslation();
  useTitle(t("Sign in"));
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoading = useSelector(authIsLoadingSelector);
  const resetPasswordSuccess = useSelector(resetPasswordSuccessSelector);

  useMount(() => {
    dispatch(setResetPasswordSuccess(undefined));
  });

  const formValidate = (values: UserCredentials) => {
    const errors: Partial<UserCredentials> = {};
    if (!values.email) {
      errors.email = (
        <>
          <Translation>{(t) => t("Email")}</Translation>&nbsp;
          <Translation>{(t) => t("is required")}</Translation>
        </>
      ) as any;
    }
    return errors;
  };

  const handleFormSubmit = async (
    values: UserCredentials,
    { setFieldError }: FormikHelpers<UserCredentials>
  ) => {
    if (!isLoading) {
      await dispatch(resetPassword(values));
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={4}>
        {isLoading && <LinearProgress style={{ marginBottom: -4 }} />}
        <Box display={"flex"} justifyContent={"center"} mt={8}>
          <img src={logo} alt="logo" className={classes.logo} />
        </Box>
        <Box px={6} pt={8} pb={4}>
          <Formik
            initialValues={
              {
                email: "",
              } as UserCredentials
            }
            validate={formValidate}
            onSubmit={handleFormSubmit}
          >
            {({ values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {!resetPasswordSuccess ? (
                      <Field
                        component={RedditTextField}
                        name="email"
                        type="email"
                        label={t("Email")}
                        variant="filled"
                        fullWidth
                        icon={PersonRounded}
                        required
                        disabled={isLoading}
                      />
                    ) : (
                      <Box>
                        <Typography variant="body1">
                          {t("An email is send to {{email}}", {
                            email: values.email,
                          })}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  {!resetPasswordSuccess ? (
                    <Grid item xs={12}>
                      <Box display={"flex"} justifyContent={"center"}>
                        <Button
                          variant={"contained"}
                          disableElevation
                          color={"primary"}
                          size={"large"}
                          className={classes.signInButton}
                          disabled={isLoading}
                          type="submit"
                        >
                          {t("Reset password")}
                        </Button>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Box display={"flex"} alignItems={"center"} mb={3}>
                        <MuiLink component={Link} to={"/sign-in"} type="button">
                          {t("Back to login")}
                        </MuiLink>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent={"flex-end"}
                      mt={-1}
                      mb={-3}
                      mr={-4}
                    >
                      <LanguageSelect />
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    </div>
  );
};
