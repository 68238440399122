import {
  Box,
  Button,
  DialogContent,
  Grid,
  Link as MuiLink,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { format } from "date-fns";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMount } from "react-use";
import PropertyField from "../../../../components/PropertyField/PropertyField";
import { authUserSelector } from "../../../Auth/authSlice";
import { UserRole } from "../../../User/User";
import { Device } from "../../Device";
import { DeviceProfile, deviceProfileStore } from "../../deviceProfileStore";
import {
  devicesOpenDeviceSelector,
  resetDeviceAlarm,
  updateOpenDevice,
} from "../../devicesSlice";

export const Details: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openDevice = useSelector(devicesOpenDeviceSelector);
  const deviceProfiles = useSelector(deviceProfileStore.entitiesSelector);
  const isDeviceProfilesLoading = useSelector(
    deviceProfileStore.isLoadingSelector
  );

  const user = useSelector(authUserSelector);
  const isAdmin = user?.roles.includes(UserRole.ROLE_ADMIN);
  const isClient = user?.roles.includes(UserRole.ROLE_CLIENT);
  const isTransporter = user?.roles.includes(UserRole.ROLE_TRANSPORTER);

  useMount(() => {
    dispatch(deviceProfileStore.fetch());
  });

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <PropertyField
          label={t("Sensor name")}
          value={openDevice?.name || ""}
          isEditing={true}
          xs={12}
          onChange={(value) => dispatch(updateOpenDevice({ name: value }))}
        />
        <PropertyField
          label={t("Model")}
          value={openDevice?.model || ""}
          isEditing={true}
          xs={12}
          onChange={(value) => dispatch(updateOpenDevice({ model: value }))}
        />
        {!!deviceProfiles && deviceProfiles.length > 0 && (
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={deviceProfiles}
              disableCloseOnSelect
              getOptionLabel={(option: DeviceProfile) =>
                option.name || "Unknown"
              }
              loading={isDeviceProfilesLoading}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option: DeviceProfile, { selected }) => (
                <>{option.name}</>
              )}
              fullWidth
              loadingText={`${t("Loading")}...`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("Device profiles")}`}
                  fullWidth
                  variant="filled"
                />
              )}
              ChipProps={{
                size: "small",
              }}
              onChange={(_, value) => {
                dispatch(
                  updateOpenDevice({
                    device_profiles:
                      value?.map((profile) => profile["@id"] || "") || [],
                  })
                );
              }}
              value={
                deviceProfiles.filter((profile) =>
                  openDevice?.device_profiles?.includes(profile["@id"]!)
                ) || []
              }
            />
          </Grid>
        )}
        <PropertyField
          label={t("Notes")}
          value={openDevice?.notes || ""}
          isEditing={true}
          xs={12}
          onChange={(value) => dispatch(updateOpenDevice({ notes: value }))}
        />
        <PropertyField
          label={t("Last location update")}
          value={
            openDevice?.last_event?.date
              ? format(new Date(openDevice?.last_event?.date), "Pp")
              : ""
          }
          xs={12}
        />
        <PropertyField
          label={t("DEVEUI")}
          value={openDevice?.dev_eui || ""}
          isEditing={!openDevice?.id}
          xs={12}
          onChange={(value) => dispatch(updateOpenDevice({ dev_eui: value }))}
        />
        <PropertyField
          label={t("Barcode")}
          value={openDevice?.barcode || ""}
          isEditing={!openDevice?.id}
          xs={12}
          onChange={(value) => dispatch(updateOpenDevice({ barcode: value }))}
        />
        <PropertyField
          label={t("Asset")}
          value={
            openDevice?.asset
              ? ((isAdmin ? (
                  openDevice.asset.name
                ) : (
                  <MuiLink
                    component={Link}
                    to={`/assets/${openDevice.asset.id}`}
                  >
                    {openDevice.asset.name}
                  </MuiLink>
                )) as any)
              : ""
          }
          xs={12}
        />
        <PropertyField
          label={t("Device activation date")}
          value={
            openDevice?.activation_date
              ? format(new Date(openDevice?.activation_date), "Pp")
              : ""
          }
          xs={12}
        />
        <PropertyField
          label={t("Device last communication")}
          value={
            openDevice?.last_communication
              ? format(new Date(openDevice?.last_communication), "Pp")
              : ""
          }
          xs={12}
        />

        {!isClient && !isTransporter && openDevice?.is_sabotaged && (
          <Grid item xs={12}>
            <Box mt={2}>
              <Button
                disableElevation
                color="secondary"
                variant="contained"
                onClick={() => dispatch(resetDeviceAlarm(openDevice as Device))}
              >
                {t("Reset alarm")}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </DialogContent>
  );
};
