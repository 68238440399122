import { Api } from "../../store/Api";
import { Entity } from "../../store/ApiStore";

declare interface FrontendSetting extends Entity {
    name?: string;
    data?: any;
}

class FrontendSettingsApi extends Api<FrontendSetting, FrontendSetting> {
  public constructor() {
    super("/api/frontend-settings");
  }
}

export const frontendSettingsApi = new FrontendSettingsApi();