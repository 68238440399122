// noinspection SpellCheckingInspection
const nl = {
  translation: {
    "Username or e-mail": "Gebruikersnaam of e-mail",
    "Password": "Wachtwoord",
    "Stay logged in": "Ingelogd blijven",
    "Forgot your password?": "Wachtwoord vergeten?",
    "Sign in": "Log in",
    "Unknown": "Onbekend",
    "English": "Engels",
    "Dutch": "Nederlands",
    "German": "Duits",
    "French": "Frans",
    "is required": "is vereist",
    "Home": "Huis",
    "logo": "logo",
    "Sign out": "Afmelden",
    "Powered by": "Powered by",
    "Under construction": "In opbouw",
    "Rows per page": "Rijen per pagina",
    "Assets": "Assets",
    "Asset map": "Asset map",
    "of": "van",
    "more than": "meer dan",
    "ID": "ID",
    "Name": "Naam",
    "Street": "Straat",
    "Postal code": "Postcode",
    "City": "Stad",
    "Region": "Regio",
    "Country": "Land",
    "Days still": "Dagen tot",
    "Last communication": "Communicatie",
    "Last location update": "Locatie update",
    "Device last communication": "Laatste communicatie",
    "Geo zone": "Geo-zone",
    "Client": "Klant",
    "Asset group": "Groep activa",
    "Description": "Omschrijving",
    "Days outside geo zone": "Uitleen dagen",
    "No problems": "Geen problemen",
    "User not found or wrong password":
      "Gebruiker niet gevonden of verkeerd wachtwoord",
    "Address": "Adres",
    "Asset": "Asset",
    "Last page": "Laatste pagina",
    "Next page": "Volgende pagina",
    "Previous page": "Vorige pagina",
    "First page": "Eerste pagina",
    "Search": "Zoeken",
    "Advanced filter": "Geavanceerd filter",
    "New": "Nieuw",
    "Events": "Locatie updates",
    "Devices": "Devices",
    "Add new": "Nieuw toevoegen",
    "Delete": "Verwijderen",
    "Link to Group": "Link naar groep",
    "Link to Client": "Link naar klant",
    "Selected": "Geselecteerd",
    "There was an error while trying to fetch the events from the asset":
      "Er is een fout opgetreden bij het ophalen van de gebeurtenissen uit het item",
    "Not in a zone": "Niet gekoppeld",
    "Interval-based": "Interval gebaseerd",
    "Clients": "Klanten",
    "Asset groups": "Activagroepen",
    "Type": "Type",
    "External ID": "Externe ID",
    "Empty": "Leeg",
    "Device activation date": "Activeringsdatum",
    "Model": "Model",
    "DEVEUI": "DEVEUI",
    "Barcode": "Barcode",
    "Notes": "Opmerkingen",
    "Device": "Apparaat",
    "Page not found": "Pagina niet gevonden",
    "From": "Van",
    "Invalid date format": "ongeldige datum",
    "To": "Naar",
    "Asset updated": "Item geüpdatet",
    "Asset deleted": "Item verwijderd",
    "Loading": "Bezig met laden",
    "Try again": "Probeer het opnieuw",
    "All": "Allemaal",
    "Start": "Begin",
    "Moving": "Uitstaand",
    "Still": "Nog steeds",
    "Stopped": "Leeg gemeld",
    "Interval-based-Local-Gateway": "Op interval gebaseerde lokale gateway",
    "Users": "Gebruikers",
    "ROLE_USER": "Gebruiker",
    "ROLE_ADMIN": "Beheerder",
    "ROLE_CLIENT": "Klant",
    "ROLE_TRANSPORTER": "Transporteur",
    "ROLE_ACCOUNT_MANAGER": "Account Manager",
    "User": "Gebruiker",
    "Username": "Gebruikersnaam",
    "Role": "Rol",
    "critical": "Kritiek",
    "major": "Majoor",
    "minor": "Minor",
    "warning": "Waarschuwing",
    "none": "Geen",
    "no items": "Geen items",
    "add new item": "Item toevoegen",
    "The file will be uploaded when you save the new asset":
      "Het bestand wordt geüpload wanneer u het nieuwe item opslaat",
    "There was an error while trying to retrieve the attachment":
      "Er is een fout opgetreden bij het ophalen van de bijlage",
    "There was an error while trying to upload the attachment":
      "Er is een fout opgetreden bij het uploaden van de bijlage",
    "Drag and drop files here": "Sleep bestanden hier naartoe",
    "or": "of",
    "Browse files": "Bestanden doorbladeren",
    "Release the file to attach it":
      "Laat het bestand los om het toe te voegen",
    "User added": "Gebruiker toegevoegd",
    "Status": "Status",
    "There was an error while trying to fetch the files credentials":
      "Er is een fout opgetreden bij het ophalen van de bestandsreferenties",
    "Asset not found": "Item niet gevonden",
    "Save": "Opslaan",
    "Save asset profile": "Weet je zeker dat je de wijzigingen wilt opslaan?",
    "Import devices": "Devices importeren",
    "Alarm rules": "Alarmregels",
    "Alarm rule added": "Alarmregel toegevoegd",
    "Email": "E-mail",
    "Asset tag is sabotaged": "Asset-tag is gesaboteerd",
    "Asset is reported as missing": "Asset als vermist opgegeven",
    "Asset is outside the Geo Zone": "Asset ingestelde Geo Zone verlaten",
    "Asset has battery level low": "Asset heeft een laag batterijniveau",
    "Alarm rule": "Alarmregel",
    "The name is required": "De naam is verplicht",
    "The email needs to be valid": "De e-mail moet geldig zijn",
    "Sabotage has been detected with": "Sabotage is gedetecteerd met",
    "Low battery has been detected for": "Batterij bijna leeg is gedetecteerd voor",
    "has been detected outside geozone": "is gedetecteerd buiten de geozone",
    "Text": "Tekst",
    "Alarm rule updated": "Alarmregel bijgewerkt",
    "Sensor name": "Sensor naam",
    "Change": "Verandering",
    "Active": "Actief",
    "Roles": "Rollen",
    "Change my password": "Verander mijn wachtwoord",
    "No result found": "Geen resultaat gevonden",
    "Edit": "Bewerk",
    "User deleted": "Gebruiker verwijderd",
    "Filter": "Filter",
    "Must be a number": "Moet een nummer zijn",
    "Must be a valid email": "Moet een geldig e-mailadres zijn",
    "Are you sure you want to delete": "Weet je zeker dat je wilt verwijderen",
    "Cancel": "Annuleren",
    "Fill all the required fields": "Vul alle verplichte velden in",
    "Devices imported": "Devices geïmporteerd",
    "Select one type": "Selecteer een type",
    "The text is required": "De tekst is verplicht",
    "Device deleted": "Device verwijderd",
    "Device updated": "Device geüpdatet",
    "You don't have any assets to show on the map":
      "Je hebt geen items om op de kaart weer te geven",
    "Drone no-fly areas": "Drone no-fly areas",
    "Fine dust": "Fijnstof",
    "Foundation problems": "Funderingsproblematiek",
    "Height map": "Hoogtekaart",
    "Electricity pylons": "Hoogspanningsmasten",
    "Quality of life card": "Leefbaarheidskaart",
    "Premises": "Panden",
    "Parcels": "Percelen",
    "Map layers": "Kaartlagen",
    "Company": "Bedrijf",
    "Logical ID": "Logical ID",
    "Reference card": "Referentiekaart",
    "Map": "Kaart",
    "Satelite": "Satelliet",
    "Satelite with Labels": "Satelliet met labels",
    "Clear": "Verwijder",
    "Asset is sabotaged": "Asset-tag is gesaboteerd",
    "Create": "Creëer",
    "Switch to tenant {{name}}": "Schakel over naar huurder {{name}}",
    "#": "#",
    "Subdomain": "Subdomein",
    "Zip code": "Postcode",
    "Phone": "Telefoon",
    "Tenants": "Huurders",
    "Add role": "Rol toevoegen",
    "Enter the role name": "Voer de rolnaam in",
    "Search for an ID": "Zoek naar een ID",
    "Create tenant": "Huurder aanmaken",
    "Yes": "Ja",
    "No": "Nee",
    "Are you sure you want to delete?":
      "Weet je zeker dat je wilt verwijderen?",
    "Edit tenant": "Bewerk huurder",
    "Drag and drop the icon here or click here to select the icon":
      "Sleep het pictogram hier naartoe of klik hier om het pictogram te selecteren",
    "Tenant created": "Huurder gemaakt",
    "Tenant updated": "Huurder bijgewerkt",
    "Tenant deleted": "Tenant verwijderd",
    "Tenant not found": "Huurder niet gevonden",
    "Create user": "Gebruiker aanmaken",
    "Preferences": "Voorkeuren",
    "Employee number": "Werknemersnummer",
    "Full name": "Voor-en achternaam",
    "New password": "Nieuw paswoord",
    "Edit user": "Bewerk gebruiker",
    "Details": "Details",
    "Select role": "Selecteer rol",
    "Tenant roles": "Huurder rollen",
    "Add new tenant": "Nieuwe huurder toevoegen",
    "Select a tenant": "Selecteer huurder",
    "Tenant": "Huurder",
    "User updated": "Gebruiker geüpdatet",
    "Customers": "Klanten",
    "Customer": "Klant",
    "New Customer": "Nieuwe klant",
    "Not assigned to a client": "Niet toegewezen aan een klant",
    "GeoZone added": "GeoZone toegevoegd",
    "Report empty buck": "Leegmelden",
    "Asset is signed off": "Asset is leeggemeld",
    "Open": "Open",
    "": "",
    "Report delivered buck": "Geleverd",
    "Asset is delivered": "Asset is afgeleverd",
    "Delivered": "Geleverd",
    "Schedule": "Inplannen",
    "Scheduled": "Levering",
    "Date": "Datum",
    "Reset password": "Reset wachtwoord",
    "An email is send to {{email}}": "Er is een email verstuurd naar {{email}}",
    "Back to login": "Ga terug naar de login pagina",
    "Reference": "Referentie",
    "ASSET_CREATE": "Asset aanmaken",
    "ASSET_READ": "Asset bekijken",
    "ASSET_EDIT": "Asset bewerken",
    "ASSET_DELETE": "Asset verwijderen",
    "ALARM_CREATE": "Alarm aanmaken",
    "ALARM_READ": "Alarm bekijken",
    "ALARM_EDIT": "Alarm bewerken",
    "ALARM_DELETE": "Alarm verwijderen",
    "DEVICE_CREATE": "Apparaten toevoegen",
    "DEVICE_READ": "Apparaten bekijken",
    "DEVICE_EDIT": "Apparaten bewerken",
    "DEVICE_DELETE": "Apparaten verwijderen",
    "RESET_LOAN_DAYS": "Reset uitleendagen",
    "reset_loan_days_message": "Weet je zeker dat je de uitleendagen wilt resetten?",
    "VIEW_ASSET_MAP": "Asset kaart bekijken",
    "USER_CREATE": "Gebruiker aanmaken",
    "USER_READ": "Gebruiker bekijken",
    "USER_EDIT": "Gebruiker bewerken",
    "USER_DELETE": "Gebruiker verwijderen",
    "PERMISSION_CREATE": "Rollen aanmaken",
    "PERMISSION_READ": "Rollen bekijken",
    "PERMISSION_EDIT": "Rollen bewerken",
    "PERMISSION_DELETE": "Rollen verwijderen",
    "CUSTOMER_CREATE": "Klant aanmaken",
    "CUSTOMER_READ": "Klant bekijken",
    "CUSTOMER_EDIT": "Klant bewerken",
    "CUSTOMER_DELETE": "Klant verwijderen",
    "ASSET_PROFILE_READ": "Asset profiel bekijken",
    "ASSET_PROFILE_EDIT": "Asset profiel bewerken",
    "CABLE_REEL_RESET": "Reset kabellengte",
    "Permissions": "Rollen en rechten",
    "Battery status": "Batterij",
    "Battery value low": "Batterij niveau te laag",
    "Cable reel profile": "Haspel profiel",
    "cable_type": "Kabeltype",
    "cable_reel_type": "Haspel type",
    "cable_length": "Initiële kabellengte (m)",
    "cable_diameter": "Kabeldiameter (mm)",
    "cable_reel_width": "Wikkelbreedte (mm)",
    "cable_reel_diameter_min": "Haspel kern diameter (mm)",
    "cable_reel_diameter_max": "Haspeldiameter (mm)",
    "cable_residual_length": "Restlengte kabel",
    "Device profiles": "Device profielen",
    "cable_length_reset_message": "Weet je zeker dat je de lengte opnieuw wilt instellen?",
    "Account Manager": "Account Manager",
    "Not assigned to a account manager": "Niet toegewezen aan een accountmanager",
    "The client will receive an email with login credentials.": "De klant ontvangt een e-mail met inloggegevens.",
    "e.g.": "bijv.",
    "Asset profiles": "Asset profielen",
    

  },
};

export default nl;
