import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AssetStatus } from "./Asset";

const AssetStatusComponent: FunctionComponent<{
  assetStatus: AssetStatus;
}> = ({ assetStatus }) => {
  const { t } = useTranslation();

  const mapStatus = (status: string) => {
    switch (status) {
      case "registered":
        return t("Open");
      case "moving":
        return t("Moving");
      case "deregistered":
        return t("Stopped");
      case "delivered":
        return t("Delivered");
    }
  };

  return <>{mapStatus(assetStatus?.status as string)}</>;
};

export default AssetStatusComponent;
