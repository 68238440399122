import {
  Badge,
  Box,
  Button,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AddRounded,
  FilterListRounded,
  SearchRounded
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount, useToggle } from "react-use";
import AutoHeight from "../../../common/components/AutoHeight/AutoHeight";
import DebounceOnChange from "../../../common/components/DebounceOnChange/DebounceOnChange";
import { TablePagination } from "../../../components/TablePagination/TablePagination";
import { User } from "../../User/User";
import { userApi } from "../../User/userApi";
import {
  customersFilterSelector,
  customersIsLoadingSelector,
  customersPageSelector,
  customersSearchSelector,
  customersSelector,
  customersTotalItemsSelector,
  fetchCustomers,
  openCustomerSelector,
  setOpenCustomer,
  setPage,
  setSearch
} from "../customersSlice";
import { Filter } from "../Filter/Filter";

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    marginTop: -2,
    marginLeft: -3,
    marginRight: 4,
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  tableContainer: {
    "@media print": {
      height: "auto !important",
      "& *": {
        color: "black",
      },
      "& td, & th": {
        padding: 0,
        paddingRight: 4,
      },
      "& span": {
        whiteSpace: "normal",
      },
    },
  },
}));

const COLUMN_COUNT = 7;

const AccountManagerComponent: FunctionComponent<{
  id?: string;
}> = ({id}) => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if(!!id) {
      userApi.get(id)
        .then((res) => {
          setUser(res);
        });
    }

  }, [id]);
  
  return (
    <>
      {user?.name || ""}
    </>
  );
}

const Table = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let customers = useSelector(customersSelector);
  const openCustomer = useSelector(openCustomerSelector);
  const isLoading = useSelector(customersIsLoadingSelector);
  const search = useSelector(customersSearchSelector);
  const [isFilterOpen, toggleFilterOpen] = useToggle(false);
  const filter = useSelector(customersFilterSelector);
  const totalItems = useSelector(customersTotalItemsSelector);
  const page = useSelector(customersPageSelector);

  useMount(async () => {
    await dispatch(fetchCustomers());
  });

  const tablePagination = (
    <TablePagination count={totalItems} page={page} setPage={setPage} />
  );
  const isFiltered = !!Object.keys(filter).find(
    (filterKey) => !!(filter as any)[filterKey]
  );

  return (
    <Paper square>
      {isLoading && !!customers.length && (
        <LinearProgress style={{ marginBottom: -4 }} />
      )}
      <AutoHeight autoHeightIndex={2}>
        <DebounceOnChange
          component={FilledInput}
          placeholder={t("Search")}
          startAdornment={
            <InputAdornment position="start">
              <IconButton>
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={toggleFilterOpen}>
                <Badge color="secondary" variant="dot" invisible={!isFiltered}>
                  <FilterListRounded />
                </Badge>
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          inputProps={{
            style: {
              paddingTop: 14,
              paddingBottom: 14,
            },
          }}
          style={{
            borderRadius: 0,
          }}
          value={search}
          onChange={({ target: { value } }) => dispatch(setSearch(value))}
          className="no-print"
        />
        <Box overflow={"hidden"} className="no-print">
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} sm={"auto"}>
              {tablePagination}
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={"auto"}>
                <Box p={1} display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    variant={"contained"}
                    disableElevation
                    startIcon={<AddRounded />}
                    color={"primary"}
                    onClick={() => dispatch(setOpenCustomer({}))}
                  >
                    {t("Add new")}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <TableContainer className={classes.tableContainer}>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("ID")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Company")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Name")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Email")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Account Manager")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow
                  key={customer.id!}
                  hover
                  tabIndex={-1}
                  onClick={() => dispatch(setOpenCustomer(customer))}
                  selected={openCustomer && customer.id === openCustomer.id}
                >
                  <TableCell>{customer.logical_id}</TableCell>
                  <TableCell>{customer.company}</TableCell>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell><AccountManagerComponent id={customer.account_manager} /></TableCell>
                </TableRow>
              ))}
              {!isLoading && !customers.length && (
                <TableRow>
                  <TableCell colSpan={COLUMN_COUNT}>
                    {t("No result found")}
                  </TableCell>
                </TableRow>
              )}
              {isLoading &&
                !customers.length &&
                [...Array(5)].map((_, i) => (
                  <TableRow key={i}>
                    {[...Array(COLUMN_COUNT)].map((_, j) => (
                      <TableCell key={j}>
                        <Skeleton variant={"text"} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {isMobile && <tr style={{ height: 88 }} />}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </AutoHeight>

      <Filter open={isFilterOpen} onClose={toggleFilterOpen} />
    </Paper>
  );
};

export default Table;
