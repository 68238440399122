import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import { Alert } from "@material-ui/lab";
import {
  Circle as LeafletCircle,
  LatLngExpression,
  LatLngTuple,
  Layer as LeafletLayer,
} from "leaflet";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureGroup,
  Map as LFMap,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useDispatch, useSelector } from "react-redux";
import DateFnsDistanceNow from "../../../../common/components/DateFnsDistanceNow/DateFnsDistanceNow";
import { ExtendedMarker } from "../../../../components/ExtendedMarker/ExtendedMarker";
import { format } from "../../../../utils/dateFnsInternationalization";
import { translateMovementType } from "../../../Events/Event";
import { eventsSelector } from "../../../Events/eventsSlice";
import {
  openAssetSelectedEventSelector,
  setSelectedEvent,
} from "../../openAssetSlice";

const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    "& .leaflet-popup-content-wrapper, & .leaflet-popup-tip": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  },
}));

declare type LatLngRadius = { latlng: LatLngTuple; radius: number };

export const Map = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();

  const selectedEvent = useSelector(openAssetSelectedEventSelector)!;
  const events = useSelector(eventsSelector);

  const selectPosition: LatLngRadius | undefined = selectedEvent
    ? {
        latlng: [selectedEvent.latitude, selectedEvent.longitude],
        radius: selectedEvent.radius || 3,
      }
    : undefined;

  // console.log("events: ", events)
  const lastPositions: LatLngTuple[] = useMemo(
    () =>
      events
        .filter((event) => !!event.latitude && event.longitude)
        .map((event) => [event.latitude, event.longitude]),
    [events]
  );

  const coordinateToLatLng = (
    rawCoordinate: Array<number>
  ): LatLngExpression => {
    const coordinates = { lat: rawCoordinate[1], lng: rawCoordinate[0] };
    return coordinates;
  };

  const createCircle = (
    coordinates: LatLngExpression,
    radius: number
  ): LeafletLayer => {
    let layer = new LeafletCircle(coordinates, {
      interactive: true,
      bubblingMouseEvents: true,
      // color: defaultGeoZoneColor,
    });
    layer.setRadius(radius);

    return layer;
  };

  const drawControlCallback = useCallback(
    (context: FeatureGroup) => {
      const layerContainer = context?.contextValue?.layerContainer;

      if (layerContainer !== null && layerContainer !== undefined) {
        // remove all layers
        context?.leafletElement?.eachLayer((layer) => {
          layerContainer.removeLayer(layer);
        });

        if (!!selectPosition) {
          console.log("selectedPosition: ", selectPosition);
          try {
            if (
              !!selectPosition?.radius &&
              selectPosition.radius > 0 &&
              !!selectPosition.latlng[0] &&
              !!selectPosition.latlng[1]
            ) {
              const lat: number = parseFloat(`${selectPosition.latlng[0]}`);
              const lng: number = parseFloat(`${selectPosition.latlng[1]}`);

              // if(!!lat)
              const coordinates = coordinateToLatLng([lng, lat]);
              const layer: LeafletLayer = createCircle(
                coordinates,
                selectPosition.radius
              );

              layerContainer.addLayer(layer, "");
            }

            lastPositions.forEach((lastPosition, i) => {
              const marker: LeafletLayer = createCircle(lastPosition, 3);
              marker.addEventListener("click", () => {
                dispatch(setSelectedEvent(events[i]));
              });
              layerContainer.addLayer(marker, "");
            });
          } catch (err) {
            // do nothing
          }
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectPosition]
  );

  return selectPosition?.latlng ? (
    <LFMap center={selectPosition.latlng} zoom={17} style={{ height: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <MarkerClusterGroup>
        <ExtendedMarker position={selectPosition.latlng}>
          <Popup className={classes.popup}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Tooltip
                  title={t(translateMovementType(selectedEvent.type)) as string}
                >
                  <Avatar>I</Avatar>
                </Tooltip>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <DateFnsDistanceNow
                      date={selectedEvent.date}
                      addSuffix
                      includeSeconds
                    />
                    {` (${format(new Date(selectedEvent.date), "Pp")})`}
                  </>
                }
                secondary={
                  <>
                    <span style={{ display: "block" }}>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ display: "inline" }}
                        color="textPrimary"
                      >
                        {`${selectedEvent.street}, ${
                          selectedEvent.postal_code || ""
                        }, ${selectedEvent.city || ""}, ${
                          selectedEvent.region || ""
                        }, ${selectedEvent.country || ""}`}
                      </Typography>
                      {` — ${selectedEvent.geo_zone || t("Not in a zone")}`}
                    </span>
                    <span style={{ display: "block" }}>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ display: "inline" }}
                        color="textPrimary"
                      >
                        {selectedEvent.latitude}, {selectedEvent.longitude}
                      </Typography>
                    </span>
                    <span style={{ display: "block" }}>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ display: "inline" }}
                        color="textPrimary"
                      >
                        Radius: {selectedEvent.radius?.toFixed(0)}m
                      </Typography>
                    </span>
                  </>
                }
              />
            </ListItem>
          </Popup>
        </ExtendedMarker>
      </MarkerClusterGroup>
      <FeatureGroup ref={drawControlCallback} />
      <Polyline positions={lastPositions} color={theme.palette.primary.main} />
    </LFMap>
  ) : (
    <Box p={2}>
      <Alert severity="warning">
        {t("You don't have any events to show on the map")}.
      </Alert>
    </Box>
  );
};
