import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";
import { Details } from "./Details/Details";
import { useDispatch, useSelector } from "react-redux";
import {
  alarmRulesOpenAlarmRuleSelector,
  setOpenAlarmRule,
} from "../alarmRulesSlice";

const OpenAlarmRule = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alarmRule = useSelector(alarmRulesOpenAlarmRuleSelector);

  useUnmount(() => {
    handleClose();
  });

  const handleClose = () => {
    dispatch(setOpenAlarmRule(undefined));
  };

  return (
    <SidePanelModal
      title={`${!alarmRule?.id ? `${t("New")} ` : ""}${t("Alarm rule")}`}
      open={!!alarmRule}
      onClose={handleClose}
      width={theme.spacing(50)}
    >
      <Details />
    </SidePanelModal>
  );
};

export default OpenAlarmRule;
