import { ALARM_TYPES, AlarmRule } from "./AlarmRule";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../store/store";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";

interface AlarmRulesState {
  isLoading: boolean;
  alarmRules: AlarmRule[];
  openAlarmRule?: Partial<AlarmRule>;
  totalItems: number;
  page: number;
  type: keyof typeof ALARM_TYPES;
}

const initialState: AlarmRulesState = {
  isLoading: false,
  alarmRules: [],
  openAlarmRule: undefined,
  totalItems: 0,
  page: 0,
  type: "",
};

export const alarmRulesSlice = createSlice({
  name: "alarmRules",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<AlarmRulesState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setAlarmRules(state, action: PayloadAction<AlarmRulesState["alarmRules"]>) {
      state.alarmRules = action.payload;
    },
    setOpenAlarmRule(
      state,
      action: PayloadAction<AlarmRulesState["openAlarmRule"]>
    ) {
      state.openAlarmRule = action.payload;
    },
    setType(state, action: PayloadAction<AlarmRulesState["type"]>) {
      state.type = action.payload;
    },
    updateOpenAlarmRule(
      state,
      action: PayloadAction<AlarmRulesState["openAlarmRule"]>
    ) {
      state.openAlarmRule = { ...state.openAlarmRule, ...action.payload };
    },
    setTotalItems(state, action: PayloadAction<AlarmRulesState["totalItems"]>) {
      state.totalItems = action.payload;
    },
    setPage(state, action: PayloadAction<AlarmRulesState["page"]>) {
      state.page = action.payload;
    },
  },
});

export const alarmRulesReducer = alarmRulesSlice.reducer;

const {
  setAlarmRules,
  setLoading,
  setOpenAlarmRule,
  updateOpenAlarmRule,
  setTotalItems,
  setPage: setPageAction,
  setType: setTypeAction,
} = alarmRulesSlice.actions;
export { setOpenAlarmRule, updateOpenAlarmRule };

export const setPage = (page: AlarmRulesState["page"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(page));
    dispatch(fetchAlarmRules());
  });
};

export const setType = (type: AlarmRulesState["type"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setTypeAction(type));
    dispatch(fetchAlarmRules());
  });
};
export const fetchAlarmRules = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.get<{
      "hydra:totalItems": number;
      "hydra:member": AlarmRule[];
    }>(`/api/alarms`, {
      params: {
        page: getState().alarmRules.page + 1,
      },
    });
    const totalItems = response.data["hydra:totalItems"];
    const alarms = response.data["hydra:member"];
    batch(() => {
      dispatch(setAlarmRules(alarms));
      dispatch(setTotalItems(totalItems));
      dispatch(setLoading(false));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const addAlarmRule = (alarmRule: AlarmRule): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.post(`/api/alarms`, alarmRule);
    batch(() => {
      dispatch(fetchAlarmRules());
      dispatch(setOpenAlarmRule(undefined));
      dispatch(
        enqueueSnackbar({
          message: "Alarm rule added",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const updateAlarmRule = (alarmRule: AlarmRule): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.put(`/api/alarms/${alarmRule.id}`, alarmRule);
    batch(() => {
      dispatch(fetchAlarmRules());
      dispatch(
        enqueueSnackbar({
          message: "Alarm rule updated",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const removeAlarmRule = (alarmRule: AlarmRule): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.delete(`/api/alarms/${alarmRule.id}`);
    batch(() => {
      dispatch(fetchAlarmRules());
      dispatch(setOpenAlarmRule(undefined));
      dispatch(
        enqueueSnackbar({
          message: "Alarm rule deleted",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const alarmRulesSelector = (state: RootState) =>
  state.alarmRules.alarmRules;
export const alarmRulesIsLoadingSelector = (state: RootState) =>
  state.alarmRules.isLoading;
export const alarmRulesOpenAlarmRuleSelector = (state: RootState) =>
  state.alarmRules.openAlarmRule;
export const alarmRulesTotalItemsSelector = (state: RootState) =>
  state.alarmRules.totalItems;
export const alarmRulesPageSelector = (state: RootState) =>
  state.alarmRules.page;
