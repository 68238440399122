import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem, TextField as MUITextField
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React, { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AlarmRule, ALARM_TYPES } from "../../AlarmRule";
import {
  addAlarmRule,
  alarmRulesIsLoadingSelector,
  alarmRulesOpenAlarmRuleSelector,
  removeAlarmRule, updateAlarmRule
} from "../../alarmRulesSlice";

export const Details: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<Partial<AlarmRule>>>(null);
  const openAlarmRule = useSelector(alarmRulesOpenAlarmRuleSelector);
  const isLoading = useSelector(alarmRulesIsLoadingSelector);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleChangeType = (event: any) => {
    switch(parseInt(event.target.value)) {
      case 0: // sabotaged
        formRef.current?.setFieldValue("text", `${t("Sabotage has been detected with")} {{Asset name}}.`, true);
        break;
      case 1: // geozone
        formRef.current?.setFieldValue("text", `{{Asset name}} ${t("has been detected outside geozone")}.`, true);
        break;
      case 2: // battery
        formRef.current?.setFieldValue("text", `${t("Low battery has been detected for")} {{Asset name}}.`, true);
        break;
      default:
        // do nothing
    }
  }

  return (
    <>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={{
            name: "",
            type: "",
            text: "",
            email: "",
            ...openAlarmRule,
          }}
          validationSchema={Yup.object<Partial<AlarmRule>>({
            name: Yup.string().required(t("The name is required")),
            type: Yup.string().required(t("Select one type")),
            text: Yup.string().required(t("The text is required")),
            email: Yup.string().email(t("The email needs to be valid")),
          })}
          onSubmit={(values) => {
            if (!openAlarmRule?.id) {
              dispatch(addAlarmRule(values as AlarmRule));
            } else {
              dispatch(updateAlarmRule(values as AlarmRule));
            }
          }}
        >
          {({setFieldValue}) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={MUITextField}
                    id="type"
                    name="type"
                    type="text"
                    label={t("Type")}
                    variant="filled"
                    fullWidth
                    select
                    required
                    disabled={isLoading}
                    onChange={(e: any) => {
                      handleChangeType(e);
                      setFieldValue("type", e.target.value, true)
                    }}
                  >
                    {Object.keys(ALARM_TYPES).map((key) => (
                      <MenuItem key={key} value={key}>
                        {t(ALARM_TYPES[key])}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="name"
                    label={t("Name")}
                    variant="filled"
                    fullWidth
                    disabled={isLoading}
                    required
                    onChange={() => {
                      console.log("change name")
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="text"
                    label={t("Text")}
                    variant="filled"
                    fullWidth
                    multiline
                    rows={3}
                    disabled={isLoading}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="email"
                    label={t("Email")}
                    variant="filled"
                    fullWidth
                    type="email"
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
              <button
                ref={submitButtonRef}
                type="submit"
                style={{ border: 0, padding: 0 }}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        {!!openAlarmRule?.id && (
          <Button
            variant="text"
            disableElevation
            onClick={() =>
              dispatch(removeAlarmRule(openAlarmRule as AlarmRule))
            }
            startIcon={<DeleteRounded />}
          >
            Delete
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => {
            submitButtonRef.current?.click();
          }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </>
  );
};
