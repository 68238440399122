import {
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  combineReducers,
} from "@reduxjs/toolkit";
import rootReducer, { initialReducers } from "./rootReducer";
import { Reducer } from "react";
import { State } from "./ReduxStore";

export const store = configureStore({
  reducer: rootReducer,
});

// @ts-ignore
if (process.env.NODE_ENV === "development" && module.hot) {
  // @ts-ignore
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

const injectedReducers: any = {};
const injectReducer = (key: string, reducer?: Reducer<State, AnyAction>) => {
  if (!key || !reducer) {
    return;
  }
  injectedReducers[key] = reducer;

  const reducers: any = combineReducers({
    ...initialReducers,
    ...injectedReducers,
  });

  store.replaceReducer(reducers);
};

export { injectReducer };

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
