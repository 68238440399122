import { Box, CircularProgress } from "@material-ui/core";
import { useUpdateAtom } from "jotai/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMount, useToggle } from "react-use";
import AppHeader from "../components/AppHeader/AppHeader";
import { AlarmRules } from "./AlarmRule/AlarmRules";
import { AssetMap } from "./AssetMap/AssetMap";
import { AssetProfiles } from "./AssetProfile/AssetProfiles";
import { Assets } from "./Assets/Assets";
import {
  authIsAuthenticatedSelector,
  authIsLoadingSelector,
  authUserSelector,
  authVerify,
  signOut
} from "./Auth/authSlice";
import { ResetPassword } from "./Auth/ResetPassword/ResetPassword";
import { SignIn } from "./Auth/SignIn/SignIn";
import { Customers } from "./Customers/Customers";
import { Devices } from "./Device/Devices";
import { NotFound } from "./NotFound/NotFound";
import { Roles } from "./Role/Roles";
import { Tenants } from "./Tenant/Tenants";
import { selectedTenantAtom } from "./Tenant/Tenants.state";
import { UserRole } from "./User/User";
import { Users } from "./User/Users";

export const Routes = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(authIsAuthenticatedSelector);
  const user = useSelector(authUserSelector);
  const isAdmin = user?.roles.includes(UserRole.ROLE_ADMIN);
  const isClient = user?.roles.includes(UserRole.ROLE_CLIENT);
  const isLoading = useSelector(authIsLoadingSelector);
  const [isVerified, toggleVerified] = useToggle(false);
  let setSelectedTenant = useUpdateAtom(selectedTenantAtom);

  useMount(async () => {
    if (isAuthenticated) {
      try {
        await dispatch(authVerify());
      } catch (e) {
        dispatch(signOut());
      }
    }
    toggleVerified(true);
  });
  useEffect(() => {
    if (!user) {
      setSelectedTenant(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (isAuthenticated && (!isVerified || isLoading)) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Switch>
      <Route path="/" exact>
        {isAuthenticated ? (
          <Redirect to={!isAdmin ? "/assets" : "/users"} />
        ) : (
          <Redirect to="/sign-in" />
        )}
      </Route>
      <Route path="/reset-password" exact>
        <ResetPassword />
      </Route>
      <Route path={["/assets", "/assets/:id"]} exact>
        {isAuthenticated ? (
          <AppHeader>
            <Assets />
          </AppHeader>
        ) : (
          <Redirect to="/sign-in" />
        )}
      </Route>
      <Route path={["/asset-profiles"]} exact>
        {isAuthenticated ? (
          <AppHeader>
            <AssetProfiles />
          </AppHeader>
        ) : (
          <Redirect to="/sign-in" />
        )}
      </Route>
      <Route path="/asset-map" exact>
        {isAuthenticated ? (
          <AppHeader>
            <AssetMap />
          </AppHeader>
        ) : (
          <Redirect to="/sign-in" />
        )}
      </Route>
      <Route path="/alarm-rules" exact>
        {isAuthenticated ? (
          <AppHeader>
            <AlarmRules />
          </AppHeader>
        ) : (
          <Redirect to="/sign-in" />
        )}
      </Route>
      <Route path="/devices" exact>
        {isAuthenticated ? (
          <AppHeader>
            <Devices />
          </AppHeader>
        ) : (
          <Redirect to="/sign-in" />
        )}
      </Route>
      <Route path="/customers" exact>
        {isAuthenticated ? (
          <AppHeader>
            <Customers />
          </AppHeader>
        ) : (
          <Redirect to="/sign-in" />
        )}
      </Route>
      <Route path="/users" exact>
        {isClient ? <Redirect to="/assets" /> : <></>}
        {isAdmin ? (
          <AppHeader>
            <Users />
          </AppHeader>
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route path="/tenants" exact>
        {isClient ? <Redirect to="/assets" /> : <></>}
        {isAdmin ? (
          <AppHeader>
            <Tenants />
          </AppHeader>
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route path="/permissions" exact>
        {isClient ? <Redirect to="/assets" /> : <></>}
        {isAuthenticated ? (
          <AppHeader>
            <Roles />
          </AppHeader>
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route path="/sign-in">
        {!isAuthenticated ? <SignIn /> : <Redirect to="/" />}
      </Route>
      <Route path="**">
        <AppHeader>
          <NotFound />
        </AppHeader>
      </Route>
    </Switch>
  );
};
