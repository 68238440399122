import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Axios from "axios";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr";
import nlLocale from "date-fns/locale/nl";
import i18n from "i18next";
import { Provider as JotaiProvider } from "jotai";
import { SnackbarProvider } from "notistack";
import React, { useMemo, useState } from "react";
import { setConfig } from "react-hot-loader";
import { hot } from "react-hot-loader/root";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { useLocalStorage, useMount } from "react-use";
import { getSelectedLanguage } from "../components/LanguageSelect/LanguageSelect";
import config from "../config";
import mockInit from "../mock/mock";
import KonneksieProvider from "../providers/KonneksieProvider";
import ServiceWorkerUpdater from "../ServiceWorkerUpdater";
import { store } from "../store/store";
import de from "../translations/de";
import en from "../translations/en";
import fr from "../translations/fr";
import nl from "../translations/nl";
import ls from "../utils/localStorageKey";
import { Routes } from "./Routes";
import { Notifier } from "./Snackbar/Notifier";

if (config.env !== "production") {
  const log = console.error.bind(console);
  console.error = (...args: Parameters<typeof console.error>) => {
    if (
      args[0] !==
        "Warning: %s is deprecated in StrictMode. %s was passed an instance of %s which is inside StrictMode. Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://fb.me/react-strict-mode-find-node%s" ||
      args[1] !== "findDOMNode"
    ) {
      log(...args);
    }
  };
}
const selectedTenantLocalStorage = localStorage.getItem("selectedTenant");
if (selectedTenantLocalStorage && selectedTenantLocalStorage !== "null") {
  Axios.defaults.baseURL = config.apiUrl.replace(
    /http(s)?:\/\//g,
    (matched) =>
      `${matched}${JSON.parse(selectedTenantLocalStorage).subdomain}.`
  );
} else {
  Axios.defaults.baseURL = config.apiUrl;
}
if (config.mock) {
  mockInit();
}

setConfig({
  reloadHooks: false,
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { en, nl, de, fr },
    lng: getSelectedLanguage(),
    fallbackLng: "en",
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      console.info("Missing translation", { lngs, ns, key, fallbackValue });
    },
  })
  .catch(console.error);

export const App = hot(() => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#04a7ff",
            contrastText: "#fff",
          },
          secondary: {
            main: "#ff5c04",
          },
        },
      }),
    [prefersDarkMode]
  );
  const [locale, setLocale] = useState(enLocale);

  const [selectedLanguage] = useLocalStorage<"en" | "nl" | "de" | "fr">(
    ls("selectedLanguage"),
    navigator.language.split("-")[0] as any
  );
  useMount(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage).catch(console.error);
    }

    let lang = "en";
    switch (selectedLanguage) {
      case "nl":
        setLocale(nlLocale);
        lang = "nl";
        break;
      case "de":
        setLocale(deLocale);
        lang = "de";
        break;
      case "fr":
        setLocale(frLocale);
        lang = "fr";
        break;
      default:
        setLocale(enLocale);
    }

    document.documentElement.lang = lang;
  });

  return (
    <JotaiProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            {/* check for updates every hour */}
            <ServiceWorkerUpdater updateInterval={3600} env={config.env}> 
              <SnackbarProvider maxSnack={5}>
              <KonneksieProvider>
                <>
                  <Notifier />
                  <CssBaseline />

                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>
                </>
              </KonneksieProvider>
              </SnackbarProvider>
            </ServiceWorkerUpdater>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </JotaiProvider>
  );
});
