import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { User } from "../User/User";
import { Device, DeviceFilter, OrderFilter } from "./Device";

interface DevicesState {
  isLoading: boolean;
  errorMessage: string | undefined;
  devices: Device[];
  search: string;
  openDevice?: Partial<Device>;
  filter: DeviceFilter;
  order: OrderFilter;
  totalItems: number;
  page: number;
}

const initialState: DevicesState = {
  isLoading: false,
  errorMessage: undefined,
  devices: [],
  search: "",
  openDevice: undefined,
  filter: {},
  order: {},
  totalItems: 0,
  page: 0,
};

export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<DevicesState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(
      state,
      action: PayloadAction<DevicesState["errorMessage"]>
    ) {
      state.errorMessage = action.payload;
    },
    setDevices(state, action: PayloadAction<DevicesState["devices"]>) {
      state.devices = action.payload;
    },
    setSearch(state, action: PayloadAction<DevicesState["search"]>) {
      state.search = action.payload;
    },
    setOpenDevice(state, action: PayloadAction<DevicesState["openDevice"]>) {
      state.openDevice = action.payload;
    },
    updateOpenDevice(state, action: PayloadAction<DevicesState["openDevice"]>) {
      state.openDevice = { ...state.openDevice, ...action.payload };
    },
    setFilter(state, action: PayloadAction<DevicesState["filter"]>) {
      state.filter = action.payload;
    },
    setOrder(state, action: PayloadAction<DevicesState["order"]>) {
      state.order = action.payload;
    },
    setTotalItems(state, action: PayloadAction<DevicesState["totalItems"]>) {
      state.totalItems = action.payload;
    },
    setPage(state, action: PayloadAction<DevicesState["page"]>) {
      state.page = action.payload;
    },
  },
});

export const devicesReducer = devicesSlice.reducer;

const {
  setDevices,
  setLoading,
  setSearch: setSearchAction,
  setOpenDevice,
  setErrorMessage,
  setFilter: setFilterAction,
  setOrder: setOrderAction,
  updateOpenDevice,
  setTotalItems,
  setPage: setPageAction,
} = devicesSlice.actions;
export { setOpenDevice, updateOpenDevice };

export const setPage =
  (page: DevicesState["page"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setPageAction(page));
      dispatch(fetchDevices());
    });
  };

export const setSearch =
  (search: DevicesState["search"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setPageAction(0));
      dispatch(setSearchAction(search));
      dispatch(fetchDevices());
    });
  };

export const setFilter =
  (filter: DevicesState["filter"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setPageAction(0));
      dispatch(setFilterAction(filter));
      dispatch(fetchDevices());
    });
  };

export const setOrder =
  (order: DevicesState["order"]): AppThunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setOrderAction(order));
      dispatch(fetchDevices());
    });
  };

export const fetchDevices =
  (withoutAsset?: boolean, withoutPagination?: boolean): AppThunk =>
  async (dispatch, getState) => {
    batch(() => {
      dispatch(setLoading(true));
      dispatch(setErrorMessage(undefined));
    });
    try {
      let route = `/api/devices`;

      let order: string = "";
      const orderState = getState().devices.order;

      if (!!orderState && Object.keys(orderState).length > 0) {
        const orderStateKeys = Object.keys(orderState);
        const orderKey = orderStateKeys[0];

        order += `order[${orderKey}]=${orderState[orderKey]}`;
      }

      const response = await Axios.get<{
        "hydra:totalItems": number;
        "hydra:member": Device[];
      }>(`${route}?${order}`, {
        params: {
          search: getState().devices.search,
          page: getState().devices.page + 1,
          pagination: !!withoutPagination ? false : true,
          "asset[exists]": withoutAsset ? false : undefined,
          ...getState().devices.filter,
        },
      });
      const totalItems = response.data["hydra:totalItems"];
      const devices = response.data["hydra:member"];
      batch(() => {
        dispatch(setDevices(devices));
        dispatch(setTotalItems(totalItems));
        dispatch(setLoading(false));
      });
    } catch (err) {
      batch(() => {
        dispatch(setLoading(false));
        dispatch(
          setErrorMessage(
            "There was an error while trying to fetch the devices list"
          )
        );
      });
      const error: AxiosError = err;
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: error.response?.data?.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  };

export const uploadDevicesCsv =
  (user: User, files: File[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const data = new FormData();
      for (const file of files) {
        data.append("files", file);
      }
      await Axios.post(`/api/users/${user.id}/devices/upload`, data);
      dispatch(
        enqueueSnackbar({
          message: "Devices imported",
        })
      );
    } catch (err) {
      const error: AxiosError = err;
      console.error(error);
      const messages: string | string[] = error.response?.data?.message;
      let message: string;
      if (Array.isArray(messages)) {
        message = messages.join(", ");
      } else {
        message = messages;
      }
      dispatch(
        enqueueSnackbar({
          message: message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const addDevice =
  (device: Device): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await Axios.post(`/api/devices`, device);
      batch(() => {
        dispatch(fetchDevices());
        dispatch(setOpenDevice(undefined));
        dispatch(setLoading(false));
        dispatch(
          enqueueSnackbar({
            message: "Device added",
          })
        );
      });
    } catch (err) {
      dispatch(setLoading(false));
      const error: AxiosError = err;
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: error.response?.data?.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  };

export const updateDevice =
  (device: Device): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await Axios.put(`/api/devices/${device.id}`, device);
      batch(() => {
        dispatch(fetchDevices());
        dispatch(setOpenDevice(undefined));
        dispatch(setLoading(false));
        dispatch(
          enqueueSnackbar({
            message: "Device updated",
          })
        );
      });
    } catch (err) {
      dispatch(setLoading(false));
      const error: AxiosError = err;
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: error.response?.data?.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  };

export const removeDevice =
  (device: Device): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await Axios.delete(`/api/devices/${device.id}`);
      batch(() => {
        dispatch(fetchDevices());
        dispatch(setOpenDevice(undefined));
        dispatch(
          enqueueSnackbar({
            message: "Device deleted",
          })
        );
      });
    } catch (err) {
      dispatch(setLoading(false));
      const error: AxiosError = err;
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: error.response?.data?.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  };

export const resetDeviceAlarm =
  (device: Device): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await Axios.post(`/api/devices/${device.id}/reset-sabotaged`, device);
      batch(() => {
        dispatch(fetchDevices());
        dispatch(setOpenDevice(undefined));
      });
    } catch (err) {
      dispatch(setLoading(false));
      const error: AxiosError = err;
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: error.response?.data?.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  };

export const devicesSelector = (state: RootState) => state.devices.devices;
export const devicesIsLoadingSelector = (state: RootState) =>
  state.devices.isLoading;
export const devicesSearchSelector = (state: RootState) => state.devices.search;
export const devicesErrorMessageSelector = (state: RootState) =>
  state.devices.errorMessage;
export const devicesOpenDeviceSelector = (state: RootState) =>
  state.devices.openDevice;
export const devicesFilterSelector = (state: RootState) => state.devices.filter;
export const devicesTotalItemsSelector = (state: RootState) =>
  state.devices.totalItems;
export const devicesPageSelector = (state: RootState) => state.devices.page;
