import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import {
  loadPersistedState,
  persistState
} from "../../common/utils/localStorage";
import { AppThunk, RootState } from "../../store/store";
import ls from "../../utils/localStorageKey";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { User, UserCredentials } from "../User/User";

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  stayLoggedIn: boolean;
  resetPasswordSuccess: boolean | undefined;
  s3Credentials:
    | {
        aws_s3_client_id: string;
        aws_s3_client_secret: string;
        aws_s3_client_region: string;
        aws_s3_client_bucket: string;
      }
    | undefined;
  isLoadingS3: boolean;
  s3ErrorMessage?: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  stayLoggedIn: true,
  resetPasswordSuccess: undefined,
  s3Credentials: undefined,
  isLoadingS3: false,
  s3ErrorMessage: undefined,
  ...loadPersistedState(ls("auth")),
};
if (initialState.user) {
  Axios.defaults.headers.Authorization = `Bearer ${initialState.user.token}`;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      Axios.defaults.headers.Authorization = `Bearer ${state.user.token}`;
      state.isAuthenticated = true;
      persistState(ls("auth"), {
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        stayLoggedIn: state.stayLoggedIn,
      });
    },
    signOut(state) {
      delete state.user;
      delete Axios.defaults.headers.Authorization;
      state.isAuthenticated = false;
      persistState(ls("auth"), {
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        stayLoggedIn: state.stayLoggedIn,
      });
    },
    setStayLoggedIn(state, action: PayloadAction<AuthState["stayLoggedIn"]>) {
      state.stayLoggedIn = action.payload;
      persistState(ls("auth"), {
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        stayLoggedIn: state.stayLoggedIn,
      });
    },
    setResetPasswordSuccess(
      state,
      action: PayloadAction<AuthState["resetPasswordSuccess"]>
    ) {
      state.resetPasswordSuccess = action.payload;
    },
    setS3Credentials(state, action: PayloadAction<AuthState["s3Credentials"]>) {
      state.s3Credentials = action.payload;
    },
    setIdLoadingS3(state, action: PayloadAction<AuthState["isLoadingS3"]>) {
      state.isLoadingS3 = action.payload;
    },
    setS3ErrorMessage(
      state,
      action: PayloadAction<AuthState["s3ErrorMessage"]>
    ) {
      state.s3ErrorMessage = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;

const {
  setUser,
  setLoading,
  setS3Credentials,
  setIdLoadingS3,
  setS3ErrorMessage,
} = authSlice.actions;
export const {
  signOut,
  setStayLoggedIn,
  setResetPasswordSuccess,
} = authSlice.actions;

export const signIn = (credentials: UserCredentials): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.post<{
      user: User;
      token: Required<User>["token"];
      refresh_token: Required<User>["refresh_token"];
    }>("/user/sign-in", credentials);
    const { user, token, refresh_token } = response.data;
    user.token = token;
    user.refresh_token = refresh_token;
    batch(() => {
      dispatch(setUser(user));
      dispatch(setLoading(false));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    if (error.response?.status === 401) {
      throw err;
    } else {
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: error.response?.data.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  }
};

export const resetPassword = (credentials: UserCredentials): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.post<{
      user: User;
    }>("/user/reset-password", credentials);

    const resetPasswordSuccess = response.status === 200;
    batch(() => {
      dispatch(setLoading(false));
      dispatch(setResetPasswordSuccess(resetPasswordSuccess));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    if (error.response?.status === 401) {
      throw err;
    } else {
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: error.response?.data.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  }
};

export const authVerify = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.post<{
      user: User;
      token: Required<User>["token"];
      refresh_token: Required<User>["refresh_token"];
    }>("/user/verify", { refresh_token: getState().auth.user!.refresh_token });
    const { token, refresh_token } = response.data;
    const user = { ...getState().auth.user! };
    user.token = token;
    user.refresh_token = refresh_token;
    batch(() => {
      dispatch(setUser(user));
      dispatch(setLoading(false));
    });
  } catch (err) {
    batch(() => {
      dispatch(signOut());
      dispatch(setLoading(false));
    });
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const fetchS3Credentials = (): AppThunk => async (dispatch) => {
  dispatch(setIdLoadingS3(true));
  try {
    const response = await Axios.get<AuthState["s3Credentials"]>("/api/aws/s3");
    dispatch(setS3Credentials(response.data));
  } catch (err) {
    const error: AxiosError = err;
    console.error(error);
    batch(() => {
      dispatch(
        enqueueSnackbar({
          message: error.response?.data.message || error.message,
          options: {
            variant: "error",
          },
        })
      );
      dispatch(
        setS3ErrorMessage(
          "There was an error while trying to fetch the files credentials"
        )
      );
    });
  } finally {
    dispatch(setIdLoadingS3(false));
  }
};

export const authIsAuthenticatedSelector = (state: RootState) =>
  state.auth.isAuthenticated;
export const authUserSelector = (state: RootState) => state.auth.user;
export const authIsLoadingSelector = (state: RootState) => state.auth.isLoading;
export const resetPasswordSuccessSelector = (state: RootState) =>
  state.auth.resetPasswordSuccess;
export const authStayLoggedInSelector = (state: RootState) =>
  state.auth.stayLoggedIn;
export const authS3CredentialsSelector = (state: RootState) =>
  state.auth.s3Credentials;
export const authIsLoadingS3Selector = (state: RootState) =>
  state.auth.isLoadingS3;
export const authS3ErrorMessageSelector = (state: RootState) =>
  state.auth.s3ErrorMessage;
