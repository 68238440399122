import { Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { AssetProfile, AssetProfileData } from "../../AssetProfile/assetProfile";
import { assetProfileApi } from "../../AssetProfile/assetProfileApi";
import { Asset } from "../Asset";

const CableReelProfile: FunctionComponent<{
  assetProfile: AssetProfile;
}> = ({assetProfile}) => {
  const [residualLength, setResidualLength] = useState<string>("");

  const getDataByName = (name: string): AssetProfileData => {
    const result = assetProfile?.data?.find((data) => {
      return data.name === name;
    });

    return !!result ? result : {name: name, values: undefined} as AssetProfileData;
  }

  useEffect(() => {
    try {
      const result = getDataByName("residual_length")?.values;
      if(!!result) {
        const floatValue = parseFloat(result) || 0;
        setResidualLength(`${floatValue.toFixed(0)}m`);
      } else {
        setResidualLength("");
      }
    } catch(err) {
      setResidualLength("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetProfile]);
  
  return (
    <Typography>{residualLength}</Typography>
  )
}

export const AssetProfileColumnDetails: FunctionComponent<{
  uri: string;
}> = ({uri}) => {
  const [isLoading, setIsLoading] = useToggle(false);
  const [assetProfile, setAssetProfile] = useState<AssetProfile>();

  useEffect(() => {
    if (!!uri) {
      setIsLoading(true);
      assetProfileApi.get(uri)
        .then((result: AssetProfile) => {
          // set default array
          if(!result.data) {
            result.data = [];
          }
          setAssetProfile(result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);
  
  return (
    <>
      {!isLoading && (
        <>
          {assetProfile?.type === "cable_reel" && (
            <CableReelProfile assetProfile={assetProfile} />
          )}
        </>
      )}
    </>
  );
}



export const AssetProfileColumn: FunctionComponent<{
  asset: Asset;
}> = ({asset}) => {
  return (
    <>
      {asset?.asset_profile_relations?.map((assetProfileRelation) => (
        <AssetProfileColumnDetails uri={assetProfileRelation} />
      ))}
    </>
  );
}