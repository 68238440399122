import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExitToApp as ExitToAppIcon,
  PersonRounded,
  EditRounded,
} from "@material-ui/icons";
import getInitials from "../../../../common/utils/getInitials";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, signOut } from "../../../../domains/Auth/authSlice";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../../LanguageSelect/LanguageSelect";
import kpn from "./kpn.png";
import OpenUser from "../../../../domains/User/OpenUser/OpenUser";
import { useLocation } from "react-router-dom";
import { setOpenUser } from "../../../../domains/User/usersSlice";

const CURRENT_YEAR = new Date().getFullYear();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: 18,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    "&:hover": {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText,
    },
  },
  madeBy: {
    fontSize: theme.typography.pxToRem(10),
    marginBottom: 4,
  },
  kpn: {
    marginLeft: theme.spacing(1),
    width: 50,
  },
}));

const UserMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const user = useSelector(authUserSelector)!;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        aria-label="user menu button"
        className={classes.root}
        variant="contained"
        disableElevation
        color="primary"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {user.email ? getInitials(user.email) : <PersonRounded />}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box mx={2} mt={1} mb={2}>
          <Typography variant="subtitle2">{user.email}</Typography>
        </Box>
        <MenuItem onClick={() => dispatch(setOpenUser(user))}>
          <ListItemIcon>
            <EditRounded fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t("Change my password")}</Typography>
        </MenuItem>
        <MenuItem onClick={() => dispatch(signOut())}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t("Sign out")}</Typography>
        </MenuItem>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box
          m={2}
          mt={3}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Box mb={2}>
            <LanguageSelect />
          </Box>
          <Typography variant="overline" className={classes.madeBy}>
            <Box display="flex" alignItems={"center"}>
              {t("Powered by")}{" "}
              <img src={kpn} alt="kpn" className={classes.kpn} />
            </Box>
          </Typography>
          <div />
          <Typography variant="overline">© {CURRENT_YEAR}</Typography>
        </Box>
      </Menu>

      {!location.pathname.includes("/users") && <OpenUser />}
    </>
  );
};

export default UserMenu;
