import Axios from "axios";

export default function mockInit() {
  // const mock = new MockAdapter(Axios, { onNoMatch: "passthrough" });
  Axios.interceptors.request.use((request) => {
    console.info("Starting Request", request);
    return request;
  });
  Axios.interceptors.response.use(
    function (response) {
      console.info("Responding with success", response);
      return response;
    },
    function (error) {
      console.info("Responding with error", error);
      return Promise.reject(error);
    }
  );
  // assetMapMock(mock);
}
