import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../store/store";
import { batch } from "react-redux";
import Axios, { AxiosError } from "axios";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { GeoZone } from "./GeoZone";

interface GeoZonesState {
  isLoading: boolean;
  errorMessage: string | undefined;
  geoZones: GeoZone[];
  openGeoZone?: Partial<GeoZone>;
  totalItems: number;
}

const initialState: GeoZonesState = {
  isLoading: false,
  errorMessage: undefined,
  geoZones: [],
  openGeoZone: undefined,
  totalItems: 0,
};

export type GeoZoneTransferObject = {
  id: number | null;
  name: string;
  geometry: string;
};

export const geoZonesSlice = createSlice({
  name: "geoZones",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<GeoZonesState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(
      state,
      action: PayloadAction<GeoZonesState["errorMessage"]>
    ) {
      state.errorMessage = action.payload;
    },
    setGeoZones(state, action: PayloadAction<GeoZonesState["geoZones"]>) {
      state.geoZones = action.payload;
    },
    setOpenGeoZone(state, action: PayloadAction<GeoZonesState["openGeoZone"]>) {
      state.openGeoZone = action.payload;
    },
    updateOpenGeoZone(
      state,
      action: PayloadAction<GeoZonesState["openGeoZone"]>
    ) {
      state.openGeoZone = { ...state.openGeoZone, ...action.payload };
    },
    setTotalItems(state, action: PayloadAction<GeoZonesState["totalItems"]>) {
      state.totalItems = action.payload;
    },
  },
});

export const geoZonesReducer = geoZonesSlice.reducer;

const {
  setGeoZones,
  setOpenGeoZone,
  updateOpenGeoZone,
  setLoading,
  setErrorMessage,
  setTotalItems,
} = geoZonesSlice.actions;
export { setOpenGeoZone, updateOpenGeoZone };

export const fetchGeoZones = (): AppThunk => async (dispatch, getState) => {
  batch(() => {
    dispatch(setLoading(true));
    dispatch(setErrorMessage(undefined));
  });
  try {
    const response = await Axios.get<{
      "hydra:totalItems": number;
      "hydra:member": GeoZoneTransferObject[];
    }>(`/api/geo-zones`);
    const totalItems = response.data["hydra:totalItems"];
    const rawGeoZones = response.data["hydra:member"];
    batch(() => {
      const geoZones = parseRawGeoZones(rawGeoZones);
      dispatch(setGeoZones(geoZones));
      dispatch(setTotalItems(totalItems));
      dispatch(setLoading(false));
    });
  } catch (err) {
    batch(() => {
      dispatch(setLoading(false));
      dispatch(
        setErrorMessage(
          "There was an error while trying to fetch the geo zones"
        )
      );
    });
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const addGeoZone = (geoZone: Partial<GeoZone>): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  try {
    const geoZoneTransferObject: GeoZoneTransferObject = mapGeoZoneToTransferObject(
      geoZone as GeoZone
    );
    await Axios.post<GeoZoneTransferObject>(`/api/geo-zones`, {
      ...geoZoneTransferObject,
    });
    batch(() => {
      dispatch(fetchGeoZones());
      dispatch(setOpenGeoZone(undefined));
      dispatch(
        enqueueSnackbar({
          message: "GeoZone added",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const updateGeoZone = (geoZone: GeoZone): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    const geoZoneTransferObject: GeoZoneTransferObject = mapGeoZoneToTransferObject(
      geoZone
    );
    await Axios.put(`/api/geo-zones/${geoZone.id}`, {
      ...geoZoneTransferObject,
    });
    batch(() => {
      dispatch(fetchGeoZones());
      dispatch(
        enqueueSnackbar({
          message: "Geo Zone updated",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const removeGeoZone = (geoZone: GeoZone): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    await Axios.delete(`/api/geo-zones/${geoZone.id}`);
    batch(() => {
      dispatch(fetchGeoZones());
      dispatch(setOpenGeoZone(undefined));
      dispatch(
        enqueueSnackbar({
          message: "Geo Zone deleted",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

function mapGeoZoneToTransferObject(geoZone: GeoZone): GeoZoneTransferObject {
  const transferObject: GeoZoneTransferObject = {
    id: geoZone.id,
    name: geoZone.name,
    geometry: JSON.stringify(geoZone.geometry),
  };

  return transferObject;
}

function parseRawGeoZones(rawGeoZones: GeoZoneTransferObject[]): GeoZone[] {
  const geoZones = rawGeoZones.map(parseRawGeoZone);
  return geoZones;
}

function parseRawGeoZone(rawGeoZone: GeoZoneTransferObject): GeoZone {
  const geoZone: GeoZone = {
    id: rawGeoZone.id,
    name: rawGeoZone.name,
    geometry: JSON.parse(rawGeoZone.geometry),
  };

  return geoZone;
}

export const geoZonesSelector = (state: RootState) => state.geoZones.geoZones;
export const geoZonesOpenGeoZoneSelector = (state: RootState) =>
  state.geoZones.openGeoZone;
export const geoZonesIsLoadingSelector = (state: RootState) =>
  state.geoZones.isLoading;
export const geoZonesErrorMessageSelector = (state: RootState) =>
  state.geoZones.errorMessage;
export const eventsTotalItemsSelector = (state: RootState) =>
  state.geoZones.totalItems;
