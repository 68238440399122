import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  InputLabel,
  Tab,
  Tabs,
  TextField as MuiTextField
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { TabContext } from "@material-ui/lab";
import { Field, Form, Formik, FormikProps } from "formik";
import { Switch, TextField } from "formik-material-ui";
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from "formik-material-ui-lab";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InferType } from "yup";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { TabPanelNoPadding } from "../../../components/TabPanelNoPadding/TabPanelNoPadding";
import { SecureComponent } from "../../../providers/SecurityProvider";
import clearEmptyProperties from "../../../utils/clearEmptyProperties";
import getFieldsFromSchema from "../../../utils/getFieldsFromSchema";
import { authUserSelector } from "../../Auth/authSlice";
import { Role, roleStore } from "../../Role/RoleState";
import { User, UserCredentials, UserTenantRole } from "../User";
import {
  addUser,
  setOpenUser,
  updateUser,
  usersIsLoadingSelector,
  usersOpenUserSelector
} from "../usersSlice";
import { DeleteButton } from "./DeleteButton/DeleteButton";
import { Tenants } from "./Tenants/Tenants";


export const Details = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();


  let openUser = useSelector(usersOpenUserSelector);
  const userRoles: Role[] = useSelector(roleStore.entitiesSelector);

  let currentUser = useSelector(authUserSelector);
  openUser = useMemo(() => clearEmptyProperties(openUser) || null, [openUser]);
  const [currentTab, setCurrentTab] = useState(0);

  const submitFormRef = useRef<HTMLButtonElement>(null);

  const isLoading = useSelector(usersIsLoadingSelector);


  const UserSchema = Yup.object<
    Partial<User> & {
      password?: string;
    }
  >({
    name: Yup.string().label("Name").default(""),
    username: Yup.string().label("Username").default(""),
    email: Yup.string().label("Email").default(""),
    password: Yup.string()
      .default("")
      .meta({
        type: "password",
        render(
          field: any,
          formikProps: FormikProps<any>,
          isFetching: boolean,
          isOpenUserTheCurrentUser: boolean,
          t: any
        ) {
          return (
            <Field
              component={TextField}
              variant="filled"
              fullWidth
              name={field.name}
              label={
                !isOpenUserTheCurrentUser ? t("Password") : t("New password")
              }
              disabled={isFetching}
              required={field.required}
              type={field.meta?.type}
            />
          );
        },
      }),
    roles: Yup.array<string>(
      Yup.mixed<string>().oneOf(userRoles.map((role) => role.name))
    )
      .label("Roles")
      .default([])
      .meta({
        hidden(
          field: any,
          formikProps: FormikProps<any>,
          isOpenUserTheCurrentUser: boolean
        ) {
          return isOpenUserTheCurrentUser;
        },
        render(
          field: any,
          formikProps: FormikProps<any>,
          isFetching: boolean,
          isOpenUserTheCurrentUser: boolean,
          t: any
        ) {
          return (
            <Field
              name={field.name}
              component={Autocomplete}
              options={userRoles.map((role) => role.name)}
              multiple
              getOptionLabel={(option: Role) => t(option)}
              renderTags={(value: any, getTagProps: any) =>
                value.map((option: any, index: any) => (
                  <Chip
                    variant="outlined"
                    label={t(option)}
                    size="small"
                    {...{ ...getTagProps({ index }) }}
                  />
                ))
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <MuiTextField
                  {...params}
                  error={
                    formikProps.touched[field.name] &&
                    !!formikProps.errors[field.name]
                  }
                  helperText={formikProps.errors[field.name]}
                  label={t(field.label)}
                  variant="filled"
                  placeholder={t("Select role")}
                />
              )}
              disableCloseOnSelect
            />
          );
        },
      })
      .default([]),
    active: Yup.boolean()
      .label("Status")
      .default(true)
      .meta({
        hidden(
          field: any,
          formikProps: FormikProps<any>,
          isOpenUserTheCurrentUser: boolean
        ) {
          return isOpenUserTheCurrentUser;
        },
        render(
          field: any,
          formikProps: FormikProps<any>,
          isFetching: boolean,
          isOpenUserTheCurrentUser: boolean,
          t: any
        ) {
          return (
            <Box p="9px 0 0 12px">
              <InputLabel shrink>{t(field.label)}</InputLabel>
              <FormControlLabel
                style={{ marginTop: -7 }}
                control={
                  <Field
                    component={Switch}
                    name={field.name}
                    type="checkbox"
                    disabled={isFetching}
                  />
                }
                label={t(formikProps.values[field.name] ? "Active" : "Inactive")}
              />
            </Box>
          );
        },
      }),
    user_tenant_roles: Yup.array<UserTenantRole>(
      Yup.object({
        tenant: Yup.string()
          .nullable()
          .required((<Trans i18nKey="Tenant is required" />) as any),
        roles: Yup.array<string>().default([]),
      }).required()
    ).meta({ hidden: () => true }),
  }).required();
  const UserFields = getFieldsFromSchema(UserSchema);
  type UserValues = InferType<typeof UserSchema>;

  const formRef = useRef<FormikProps<UserValues>>(null);

  const saveUser = (data: User) => {
    data = JSON.parse(JSON.stringify(data));
    data.user_tenant_roles?.forEach((tr) => {
      if (typeof (tr.id as any) === "string") {
        delete tr.id;
      } else {
        tr.id = tr["@id"] as any;
      }
    });

    if (!openUser?.id) {
      dispatch(addUser(data as User & UserCredentials));
    } else {
      dispatch(updateUser(data as User & UserCredentials));
    }
  };

  useEffect(() => {
    setCurrentTab(0);
    formRef.current?.resetForm();
  }, [openUser]);

  let isOpenUserTheCurrentUser = currentUser?.id === openUser?.id;
  
  return (
    <SidePanelModal
      title={`${
        !isOpenUserTheCurrentUser
          ? `${t(openUser?.id ? "Edit user" : "Create user")}${
              openUser?.id ? ` ${openUser.name || openUser?.id}` : ""
            }`
          : t("Preferences")
      }`}
      width={theme.spacing(50)}
      open={!!openUser}
      onClose={() => (!isLoading ? dispatch(setOpenUser(null)) : null)}
      loading={isLoading}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ ...UserSchema.default(), ...openUser }}
        onSubmit={(values) => void saveUser(values as User)}
        validationSchema={UserSchema}
      >
        {(formikProps) => (
          <>
            <TabContext value={currentTab.toString()}>
              {!isOpenUserTheCurrentUser && (
                <Tabs
                  variant="fullWidth"
                  value={currentTab}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={(_, newTab) => setCurrentTab(newTab)}
                >
                  <Tab
                    label={t("Details")}
                    disabled={JSON.stringify(formikProps.errors) !== "{}"}
                  />
                  <SecureComponent permissions={["DEPRECATED"]}>
                    <Tab
                      label={t("Tenant roles")}
                      disabled={JSON.stringify(formikProps.errors) !== "{}"}
                    />
                  </SecureComponent>
                </Tabs>
              )}
              <DialogContent>
                <Form>
                  <TabPanelNoPadding value="0">
                    <Grid container spacing={2}>
                      {UserFields.filter(
                        (field) =>
                          !field.meta?.hidden?.(
                            field,
                            formikProps,
                            isOpenUserTheCurrentUser
                          )
                      ).map((field) => (
                        <Grid item xs={12} key={field.name}>
                          {!field.meta?.render ? (
                            <Field
                              component={TextField}
                              variant="filled"
                              fullWidth
                              name={field.name}
                              label={t(field.label)}
                              disabled={isLoading}
                              required={field.required}
                              type={field.meta?.type}
                            />
                          ) : (
                            field.meta.render(
                              field,
                              formikProps,
                              isLoading,
                              isOpenUserTheCurrentUser,
                              t
                            )
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </TabPanelNoPadding>
                  <SecureComponent permissions={["DEPRECATED"]}>
                    <TabPanelNoPadding value="1">
                      <Tenants />
                    </TabPanelNoPadding>
                  </SecureComponent>
                  <button
                    ref={submitFormRef}
                    style={{ padding: 0, border: "none" }}
                    type="submit"
                  />
                </Form>
              </DialogContent>
            </TabContext>
          </>
        )}
      </Formik>
      <DialogActions>
        {openUser?.id && !isOpenUserTheCurrentUser && <DeleteButton />}
        <Button
          variant="contained"
          disableElevation
          color="primary"
          disabled={isLoading}
          onClick={() => submitFormRef.current?.click()}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};
