import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { FunctionComponent } from "react";


const useStyles = makeStyles(() => ({
    title: {
        fontWeight: "bold"
    }
}));

export const SubHeader: FunctionComponent<{
    title: string;
}> = ({title}) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.title}>{title}</Typography>
        </Grid>
    )
}