import { Button, Grid, Hidden, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import update from "immutability-helper";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount, useUpdateEffect } from "react-use";
import { ConfirmButton } from "../../../common/components/Buttons/ConfirmButton";
import PropertyField from "../../../components/PropertyField/PropertyField";
import { SecureComponent } from "../../../providers/SecurityProvider";
import { AssetProfile, AssetProfileCableReelCable, AssetProfileCableReelType, AssetProfileData, AssetProfileDataDefinition } from "../../AssetProfile/assetProfile";
import { assetProfileDefinitionApi } from "../../AssetProfile/assetProfileDefinitionStore";
import { SubHeader } from "./SubHeader";

const ListProperty: FunctionComponent<{
  definition: AssetProfileDataDefinition;
  data: AssetProfileData;
  isLoading: boolean;
  isEditing?: boolean;
  type: "number" | "text";
  onChange: (data: AssetProfileData) => void;
}> = ({definition, data, isLoading, isEditing, type = "text", onChange}) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState<any>("");

  return (
    <>
      {!!isEditing ? (
        <Autocomplete
          options={definition.data}
          getOptionLabel={(option: string) => {
            return option || t("Unknown")
          }}
          loading={isLoading}
          getOptionSelected={(option, value) => option === value}
          renderOption={(option, { selected }) => <>{option || t("Unknown")}</>}
          fullWidth
          loadingText={`${t("Loading")}...`}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t(definition.name)}`}
              fullWidth
              type={type}
              variant="filled"
              onChange={(event) => {
                setNewValue(event.target.value);
              }}
              onBlur={() => {
                if(!!newValue && !definition.data?.includes(newValue)) {
                  const updatedData = update(data, {
                    values: {$set: newValue}
                  });

                  onChange(updatedData);
                }
              }}
            />
          )}
          ChipProps={{
            size: "small",
          }}
          onChange={(_, values) => {
            const updatedData = update(data, {
              values: {$set: values}
            });

            onChange(updatedData);
          }}
          value={data.values || null}
        />
      ) : (
        <>
          <Typography variant={"subtitle2"}>{t(definition.name)}</Typography>
          <Typography variant={"body2"}>
            {data.values !== undefined && data.values !== "" && data.values !== null ? (
              data.values
            ) : (
              <Typography variant={"inherit"} color={"textSecondary"}>
                {t("Empty")}
              </Typography>
            )}
          </Typography>
        </>
      )}
    </>
  )
}

const DefaultListProperty: FunctionComponent<{
  isEditing?: boolean;
  data?: any;
  label?: string;
  selectedValue?: any;
  handleChange?: (values: any) => void;
  renderOption: (option: any) => string;
}> = ({isEditing, data, label, selectedValue, handleChange, renderOption}) => {
  const { t } = useTranslation();

  return (
    <>
      {!!isEditing ? (
        <Autocomplete
          options={data}
          getOptionLabel={renderOption}
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={renderOption}
          fullWidth
          loadingText={`${t("Loading")}...`}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth
              variant="filled"
            />
          )}
          ChipProps={{
            size: "small",
          }}
          onChange={(_, values) => {
            if(!!handleChange) {
              handleChange(values);
            }
          }}
          value={selectedValue || null}
        />
      ) : (
        <>
          <Typography variant={"subtitle2"}>{label}</Typography>
          <Typography variant={"body2"}>
            {!!selectedValue ? (
              renderOption(selectedValue)
            ) : (
              <Typography variant={"inherit"} color={"textSecondary"}>
                {t("Empty")}
              </Typography>
            )}
          </Typography>
        </>
      )}
    </>
  )
}

const Property: FunctionComponent<{
  definition: AssetProfileDataDefinition;
  data: AssetProfileData;
  isLoading: boolean;
  isEditing?: boolean;
  onChange: (data: AssetProfileData) => void;
}> = ({definition, data, isLoading, isEditing, onChange}) => {
  const { t } = useTranslation();


  if(definition.type === "number") {
    return (
      <>
        <PropertyField
          label={t(definition.name)}
          value={data.values}
          isEditing={isEditing}
          xs={12}
          type="number"
          onChange={(value) => {
            const updatedData = update(data, {
              values: {$set: value}
            });

            onChange(updatedData);
          }}
        />
      </>
    )
  }
  if(definition.type === "number[]") {
    return (
      <>
        <ListProperty 
          definition={definition}
          data={data}
          type="number" 
          isEditing={!!isEditing} 
          isLoading={!!isLoading} 
          onChange={onChange} 
        />
      </>
    )
  }
  if(definition.type === "string[]") {
    return (
      <>
        <ListProperty 
          definition={definition}
          data={data}
          type="text"
          isEditing={!!isEditing} 
          isLoading={!!isLoading} 
          onChange={onChange} 
        />
      </>
    )
  }

  return (<></>);
}
  
export const CableReelProfile: FunctionComponent<{
  isEditing: boolean;
  assetProfile: AssetProfile;
  onChange: (assetProfile: AssetProfile) => void;
}> = ({ isEditing, assetProfile, onChange }) => {
  const {t} = useTranslation();
  const [cableLength, setCableLength] = useState();

  const [profiles, setProfiles] = useState<AssetProfileCableReelType[]>([]);
  const [cables, setCables] = useState<AssetProfileCableReelCable[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<AssetProfileCableReelType>();
  const [selectedCable, setSelectedCable] = useState<AssetProfileCableReelCable>();
  

  useMount(() => {
    assetProfileDefinitionApi.fetch()
      .then((res) => {
        const items = res["hydra:member"];
        const cableReelDefinitions = items.filter((item) => item.type === "cable_reel");
        
        if(!!cableReelDefinitions && cableReelDefinitions.length > 0) {
          const cableReelDefinition = cableReelDefinitions[0];
          
          const cableReels = cableReelDefinition?.definition?.find((d) => {
            return d.name === "cable_reels";
          });

          const cables = cableReelDefinition?.definition?.find((d) => {
            return d.name === "cables";
          });

          setProfiles(cableReels?.data || []);
          setCables(cables?.data || []);
        }
      })
  });

  useEffect(() => {
    if(!!assetProfile?.data) {
      const cableReelData = getDataByName("cable_reel");
      if(!!cableReelData) {
        setSelectedProfile(cableReelData.values);
      }

      const cableData = getDataByName("cable");
      if(!!cableData) {
        setSelectedCable(cableData.values);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetProfile]);

  useUpdateEffect(() => {
    if(!!assetProfile?.data) {
      const cableLengthData = getDataByName("cable_length");
      if(!!cableLengthData) {
        if(cableLengthData.values !== cableLength) {
          handleResetResidualLength();
        }

        setCableLength(cableLengthData.values);
      }
    }

  }, [assetProfile]);

  const getDefinitionByName = (name: string) => {
    const result = assetProfile?.definition?.find((definition) => {
      return definition.name === name;
    });

    return !!result ? result : {} as AssetProfileDataDefinition;
  }

  const getDataByName = (name: string): AssetProfileData => {
    const result = assetProfile?.data?.find((data) => {
      return data.name === name;
    });

    return !!result ? result : {name: name, values: undefined} as AssetProfileData;
  }

  const handleChange = (profileData: AssetProfileData) => {
    const dataElement = assetProfile?.data?.find((data) => {
      return data.name === profileData.name;
    });
    const index = assetProfile?.data.indexOf(dataElement);
    
    if(index < 0) {
      const updatedAssetProfile = update(assetProfile, {
        data: {
          $push: [profileData]
        }
      });

      onChange(updatedAssetProfile);
    } else {
      const updatedAssetProfile = update(assetProfile, {
        data: {
          [index]: {
            $set: profileData
          }
        }
      });
      onChange(updatedAssetProfile);
    }
  }

  const handleResetResidualLength = () => {
    const cableLengthData = getDataByName("cable_length");
    setResidualLength(cableLengthData?.values);
  }

  const setResidualLength = (length: any) => {
    handleChange({name:"residual_length", values:length});
  }

  const getResidualLength = () => {
    const residualLength = getDataByName("residual_length")?.values;
    if(!!residualLength) {
      return `${residualLength}m`;
    }

    return '';
  }

  return (
    <>
      <SubHeader title={t("cable_reel_type")} />
      <Grid item xs={12} sm={6}>
        <DefaultListProperty 
          label={t("cable_reel_type")}
          data={profiles}
          isEditing={isEditing}
          selectedValue={selectedProfile}
          handleChange={(values) => {
            handleChange({name: "cable_reel", values: !!values ? values : undefined});
          }}
          renderOption={(option) => {
            return option.name || t("Unknown")
          }}
        />
      </Grid>
      <Hidden xsUp>
        <Grid item xs={12} sm={6} />
        <PropertyField
          label={t("cable_reel_width")}
          value={selectedProfile?.width}
          isEditing={false}
          xs={12}
          sm={6}
          type="number"
        />
        <PropertyField
          label={t("cable_reel_diameter_min")}
          value={selectedProfile?.diameterMin}
          isEditing={false}
          xs={12}
          sm={6}
          type="number"
        />
        <PropertyField
          label={t("cable_reel_diameter_max")}
          value={selectedProfile?.diameterMax}
          isEditing={false}
          xs={12}
          sm={6}
          type="number"
        />
      </Hidden>
      
      <Hidden xsUp>
        <SubHeader title={t("cable_type")} />
      </Hidden>
      <Grid item xs={12} sm={6}>
        <DefaultListProperty 
          label={t("cable_type")}
          data={cables}
          isEditing={isEditing}
          selectedValue={selectedCable}
          handleChange={(values) => {
            handleChange({name: "cable", values: !!values ? values : undefined});
          }}
          renderOption={(option) => {
            return `${option.name || ""} - ${option?.description || ""}` || t("Unknown")
          }}
        />
      </Grid>
      <Hidden xsUp>
        <Grid item xs={12} sm={6} />
      </Hidden>
      <Grid item xs={6}>
        <Property 
          definition={getDefinitionByName("cable_length")} 
          data={getDataByName("cable_length")} 
          isLoading={false} 
          onChange={handleChange}
          isEditing={isEditing}
        />
      </Grid>
      <Hidden xsUp>
        <PropertyField
          label={t("cable_diameter")}
          value={selectedCable?.diameter}
          isEditing={false}
          xs={12}
          sm={6}
          type="number"
        />
      </Hidden>
      <Grid item xs={6}></Grid>

      <SubHeader title={t("cable_residual_length")} />
      <Grid item xs={6}>
        <Typography variant={"subtitle2"}>{t("cable_residual_length")}</Typography>
        <Typography variant={"body2"}>
          {!!getDataByName("residual_length")?.values ? (
            getResidualLength()
          ) : (
            <Typography variant={"inherit"} color={"textSecondary"}>
              {t("Empty")}
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        {!!isEditing && (
          <SecureComponent permissions={["CABLE_REEL_RESET"]}>
            <Button disableElevation onClick={handleResetResidualLength} variant="contained" color="secondary">
              {t("CABLE_REEL_RESET")}
            </Button>
            <Hidden xsUp>
              <ConfirmButton
                title={t("cable_length_reset_message")}
                okText={t("Yes")}
                color="secondary"
                cancelText={t("No")}
                buttonText={t("CABLE_REEL_RESET")}
                variant="contained"
                onClick={handleResetResidualLength}
              />
            </Hidden>
          </SecureComponent>
        )}
      </Grid>
      <Grid item xs={6}></Grid>
    </>
  );
};
  