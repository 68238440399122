import {
  Backdrop,
  Badge,
  Box,
  Button,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AddRounded,
  FilterListRounded,
  SearchRounded
} from "@material-ui/icons";
import {
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon
} from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount, useToggle } from "react-use";
import AutoHeight from "../../../common/components/AutoHeight/AutoHeight";
import DebounceOnChange from "../../../common/components/DebounceOnChange/DebounceOnChange";
import { TablePagination } from "../../../components/TablePagination/TablePagination";
import { Filter } from "../Filter/Filter";
import { User, UserRole } from "../User";
import {
  fetchUsers,
  setOpenUser,
  setPage,
  setSearch,
  usersFilterSelector,
  usersIsLoadingSelector,
  usersOpenUserSelector,
  usersPageSelector,
  usersSearchSelector,
  usersSelector,
  usersTotalItemsSelector
} from "../usersSlice";

const useStyles = makeStyles((theme: Theme) => ({
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const COLUMN_COUNT = 4;

const Table = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const users = useSelector(usersSelector);
  const isLoading = useSelector(usersIsLoadingSelector);
  const [isSpeedDialOpen, toggleSpeedDialOpen] = useToggle(false);
  const openUser = useSelector(usersOpenUserSelector);
  const search = useSelector(usersSearchSelector);
  const [isFilterOpen, toggleFilterOpen] = useToggle(false);
  const filter = useSelector(usersFilterSelector);
  const totalItems = useSelector(usersTotalItemsSelector);
  const page = useSelector(usersPageSelector);

  useMount(() => {
    dispatch(fetchUsers());
  });

  const tablePagination = (
    <TablePagination count={totalItems} page={page} setPage={setPage} />
  );
  const isFiltered = !!Object.keys(filter).find(
    (filterKey) => !!(filter as any)[filterKey]
  );

  return (
    <Paper square>
      {isLoading && !!users.length && (
        <LinearProgress style={{ marginBottom: -4 }} />
      )}
      <AutoHeight autoHeightIndex={isMobile ? 1 : 2}>
        <DebounceOnChange
          component={FilledInput}
          placeholder={t("Search")}
          startAdornment={
            <InputAdornment position="start">
              <IconButton>
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={toggleFilterOpen}>
                <Badge color="secondary" variant="dot" invisible={!isFiltered}>
                  <FilterListRounded />
                </Badge>
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          inputProps={{
            style: {
              paddingTop: 14,
              paddingBottom: 14,
            },
          }}
          style={{
            borderRadius: 0,
          }}
          value={search}
          onChange={({ target: { value } }) => dispatch(setSearch(value))}
        />
        <Box overflow={"hidden"}>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} sm={"auto"}>
              {tablePagination}
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={"auto"}>
                <Box p={1} display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    variant={"contained"}
                    disableElevation
                    startIcon={<AddRounded />}
                    color={"primary"}
                    onClick={() =>
                      dispatch(
                        setOpenUser({
                          active: true,
                          roles: [UserRole.ROLE_USER],
                        } as User)
                      )
                    }
                  >
                    {t("Add new")}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <TableContainer>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("ID")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Company")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Name")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Username")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"inherit"} noWrap>
                    {t("Email")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.id}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={() => dispatch(setOpenUser(user))}
                  selected={!!(openUser && user.id === openUser.id)}
                >
                  <TableCell>{user.id}</TableCell>
                  <TableCell>
                    {user.user_tenant_roles?.length > 0
                      ? user.user_tenant_roles[0].tenant?.company
                      : ""}
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              ))}
              {!isLoading && !users.length && (
                <TableRow>
                  <TableCell colSpan={COLUMN_COUNT}>
                    {t("No result found")}
                  </TableCell>
                </TableRow>
              )}
              {isLoading &&
                !users.length &&
                [...Array(5)].map((_, i) => (
                  <TableRow key={i}>
                    {[...Array(COLUMN_COUNT)].map((_, j) => (
                      <TableCell key={j}>
                        <Skeleton variant={"text"} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {isMobile && <tr style={{ height: 88 }} />}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </AutoHeight>

      {isMobile && (
        <>
          <Backdrop
            open={isSpeedDialOpen}
            style={{
              zIndex: theme.zIndex.drawer + 1,
            }}
            onClick={() => toggleSpeedDialOpen(false)}
          />
          <SpeedDial
            ariaLabel=""
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={() => toggleSpeedDialOpen(false)}
            onOpen={() => toggleSpeedDialOpen(true)}
            open={isSpeedDialOpen}
            direction={"up"}
          >
            {[
              <SpeedDialAction
                key={0}
                icon={<AddRounded />}
                title={""}
                tooltipTitle={<Typography noWrap>{t("Add new")}</Typography>}
                tooltipOpen
                onClick={() => dispatch(setOpenUser({}))}
              />,
            ]}
          </SpeedDial>
        </>
      )}

      <Filter open={isFilterOpen} onClose={toggleFilterOpen} />
    </Paper>
  );
};

export default Table;
