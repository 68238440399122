import { Button, DialogActions, Tab, Tabs } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { DeleteRounded, EditRounded } from "@material-ui/icons";
import { S3 } from "aws-sdk";
import { ManagedUpload } from "aws-sdk/clients/s3";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMount, useToggle, useUnmount } from "react-use";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import {
  authS3CredentialsSelector,
  authUserSelector,
  fetchS3Credentials
} from "../../Auth/authSlice";
import { fetchEvents } from "../../Events/eventsSlice";
import { UserRole } from "../../User/User";
import { Asset, Attachment } from "../Asset";
import { assetApi } from "../assetApi";
import { addAsset, fetchAssets, removeAsset } from "../assetsSlice";
import {
  addAttachment, fetchAndOpenAsset, openAssetAcceptedFilesSelector,
  openAssetCurrentTabSelector,
  openAssetSelector,
  setCurrentTab,
  setOpenAsset
} from "../openAssetSlice";
import { Attachments } from "./Attachments/Attachments";
import { Details } from "./Details/Details";
import { Events } from "./Events/Events";
import { Map } from "./Map/Map";

const OpenAsset = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, toggleEditing] = useToggle(false);
  const asset = useSelector(openAssetSelector);
  let assetId = asset?.id;
  const [hasId, setHasId] = useState<boolean>(!!assetId);
  const currentTab = useSelector(openAssetCurrentTabSelector);
  const s3Credentials = useSelector(authS3CredentialsSelector);
  const acceptedFiles = useSelector(openAssetAcceptedFilesSelector);
  
  const user = useSelector(authUserSelector);
  const isClient = user?.roles.includes(UserRole.ROLE_CLIENT);
  const isTransporter = user?.roles.includes(UserRole.ROLE_TRANSPORTER);

  const canDelete = !isClient && !isTransporter;

  const bucketName = s3Credentials?.aws_s3_client_bucket;
  const s3 = useMemo(
    () =>
      new S3({
        region: s3Credentials?.aws_s3_client_region,
        accessKeyId: s3Credentials?.aws_s3_client_id,
        secretAccessKey: s3Credentials?.aws_s3_client_secret,
      }),
    [s3Credentials]
  );

  useEffect(() => {
    if (asset && !hasId) {
      toggleEditing(true);
    }
    if (assetId) {
      if (acceptedFiles.length) {
        uploadFiles(acceptedFiles);
        dispatch(setOpenAsset(undefined));
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    if (asset) {
      const hasId = !!asset?.id;
      if (hasId) {
        setHasId(hasId);
        toggleEditing(false);
        batch(() => {
          dispatch(fetchEvents());
        });
      } else {
        setHasId(hasId);
        toggleEditing(!hasId);
      }
    } else {
      setHasId(false);
      toggleEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useUnmount(() => {
    dispatch(setOpenAsset(undefined));
  });

  useMount(() => {
    if (!s3Credentials) {
      dispatch(fetchS3Credentials());
    }
  });

  const handleClose = () => {
    if (hasId) {
      history.push("/assets");
    }
    dispatch(setOpenAsset(undefined));
  };

  const handleSave = async () => {
    if (asset!.id) {
      assetApi.put(asset as Asset)
        .then(async (result) => {
          await assetApi.addProfiles(asset as Asset);
          dispatch(fetchAndOpenAsset(result.id));
        })
        .finally(() => {
          dispatch(fetchAssets());
          toggleEditing(false);
        });

    } else {
      dispatch(addAsset(asset!));
    }
  };
  
  const uploadFiles = (acceptedFiles: File[]) => {
    for (const file of acceptedFiles) {
      s3.upload(
        {
          Body: file,
          Bucket: bucketName!,
          Key: `asset/${assetId}/attachments/${file.name}`,
          ContentType: file.type,
        },
        (error: Error, data: ManagedUpload.SendData) => {
          if (error !== null) {
            enqueueSnackbar(
              t("There was an error while trying to upload the attachment"),
              {
                variant: "error",
              }
            );
            console.error(error);
            return;
          }

          const attachment: Attachment = {
            object_key: data.Key,
            url: data.Location,
            bucket: data.Bucket,
          };

          dispatch(addAttachment(assetId!, attachment));
        }
      );
    }
  };

  return (
    <SidePanelModal
      title={`${!hasId ? `${t("New")} ` : ""}${t("Asset")}`}
      open={!!asset}
      onClose={handleClose}
      width={hasId ? theme.spacing(100) : theme.spacing(50)}
    >
      {asset?.id && !isClient && (
        <Tabs
          value={currentTab}
          onChange={(_, value) => dispatch(setCurrentTab(value))}
        >
          <Tab label="Details" />
          <Tab label={t("Events")} />
          <Tab label="Map" />
          <Tab label="Attachments" />
        </Tabs>
      )}

      {currentTab === 0 && <Details isEditing={isEditing} hasId={hasId} />}
      {!isClient && (
        <>
          {currentTab === 1 && asset?.id && <Events />}
          {currentTab === 2 && asset?.id && <Map />}
          {currentTab === 3 && asset?.id && (
            <Attachments uploadFiles={uploadFiles} />
          )}
          {currentTab === 0 && (
            <DialogActions>
              {hasId && canDelete && (
                <Button
                  variant="text"
                  disableElevation
                  onClick={() => dispatch(removeAsset(asset as Asset))}
                  startIcon={<DeleteRounded />}
                >
                  {t("Delete")}
                </Button>
              )}
              {!isEditing && (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={toggleEditing}
                  startIcon={<EditRounded />}
                >
                  {t("Edit")}
                </Button>
              )}
              <Button
                onClick={handleSave}
                color="primary"
                variant={"contained"}
                disableElevation
              >
                {t("Save")}
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </SidePanelModal>
  );
};

export default OpenAsset;
