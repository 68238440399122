import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useMount, useToggle } from "react-use";
import * as serviceWorker from "./serviceWorker";



const ServiceWorkerUpdater: FunctionComponent<{
  updateInterval?: number;
  env?: string;
}> = ({updateInterval, env, children}) => {
  const [isDialogOpen, openDialog] = useState(false);
  const [registration, setServiceWorkerRegistration] = useState<ServiceWorkerRegistration>();
  const [isUpdated, setIsUpdated] = useToggle(false);
  const [intervalHandler, setIntervalHandler] = useState<any>();

  useMount(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onUpdate: (registration) => {
        openDialog(true);
        setServiceWorkerRegistration(registration);
      }, 
      onSuccess: (registration) => {
        setServiceWorkerRegistration(registration);
      }
    });

    navigator.serviceWorker.addEventListener('controllerchange', onControllerChange);
  });

  useEffect(() => {
    if(!!updateInterval && updateInterval > 0) {
      configureUpdateInterval(updateInterval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInterval]);

  const configureUpdateInterval = (interval: number) => {
    if(!!intervalHandler) {
      clearInterval();
    }
    if(!!registration) {
      const handler = setInterval(() => {
        registration.update();
      }, interval * 1000);
      setIntervalHandler(handler);
    }
  }

  const handleClose = () => {
    openDialog(false);
  };
  
  const handleUpdate = () => {
    openDialog(false);

    if (!!registration) {
      const serviceWorker = registration.installing || registration.waiting;
      if (serviceWorker == null) {
        return;
      }
      serviceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }

  const onControllerChange = () => {
    if (isUpdated) return;
    window.location.reload();
    setIsUpdated(true);
  }

  return (
    <>
      {children}
      <Dialog
        open={isDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update beschikbaar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Er is een update beschikbaar van Konneksie. Klik op updaten om de laatste versie te gebruiken.</Typography>
            <Typography variant="caption">(sluit dit venster om je wijzigingen eerst op te slaan en refresh de pagina)</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Sluiten
          </Button>
          <Button variant="contained" onClick={handleUpdate} color="primary" autoFocus>
            Updaten
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ServiceWorkerUpdater;
