import {
  format as formatDFns,
  formatDistance as formatDistanceDFns,
} from "date-fns";
import { enUS, nl } from "date-fns/locale";
import { getSelectedLanguage } from "../components/LanguageSelect/LanguageSelect";

const locales: {
  [key: string]: typeof enUS;
} = { en: enUS, nl };

export function format(
  date: Date | number | string,
  format: string = "Pp",
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
) {
  date = typeof date === "string" ? new Date(date) : date;
  return formatDFns(date, format, {
    ...options,
    locale: getLocale(),
  });
}

export const formatDistance: typeof formatDistanceDFns = (
  date,
  baseDate,
  options
) => {
  return formatDistanceDFns(date, baseDate, {
    ...options,
    locale: getLocale(),
  });
};

export const getLocale = () => {
  return locales[getSelectedLanguage()];
};
