import {
  Button,
  CircularProgress,
  DialogActions,
  Fade,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { DeleteRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useUnmount } from "react-use";
import Menu from "../../../common/components/Menu/Menu";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { authUserSelector } from "../../../domains/Auth/authSlice";
import { UserRole } from "../../../domains/User/User";
import { Device } from "../Device";
import {
  addDevice,
  devicesIsLoadingSelector,
  devicesOpenDeviceSelector,
  removeDevice,
  setOpenDevice,
  updateDevice,
} from "../devicesSlice";
import { Details } from "./Details/Details";

const OpenDevice = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const device = useSelector(devicesOpenDeviceSelector);
  const [hasId, setHasId] = useState<boolean>(!!device?.id);
  const isLoading = useSelector(devicesIsLoadingSelector);

  const user = useSelector(authUserSelector);
  const isAdmin = user?.roles.includes(UserRole.ROLE_ADMIN);

  useEffect(() => {
    if (device) {
      const hasId = !!device?.id;
      if (hasId) {
        setHasId(hasId);
      } else if (device) {
        setHasId(hasId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useUnmount(() => {
    handleClose();
  });

  const handleClose = () => {
    dispatch(setOpenDevice(undefined));
  };
  const handleSave = () => {
    if (!!device?.id) {
      dispatch(updateDevice(device as Device));
    } else {
      dispatch(addDevice(device as Device));
    }
  };

  return (
    <SidePanelModal
      title={`${!hasId ? `${t("New")} ` : ""}${t("Device")}`}
      open={!!device}
      onClose={handleClose}
      width={theme.spacing(50)}
    >
      <Details />
      <DialogActions>
        {isLoading && (
          <Fade in timeout={{ enter: 1000 }}>
            <CircularProgress
              size={theme.spacing(3)}
              style={{ marginRight: theme.spacing(1) }}
              color="inherit"
            />
          </Fade>
        )}

        {hasId && device && isAdmin && (
          <Menu
            loading={isLoading}
            items={(closeMenu) => [
              <ListSubheader key={0} component="div">
                {t("Are you sure you want to delete")} {'"'}
                {device.name || `#${device.id}`}
                {'"'}?
              </ListSubheader>,
              <MenuItem
                key={0}
                onClick={() => dispatch(removeDevice(device as Device))}
              >
                {t("Delete")}
              </MenuItem>,
              <MenuItem key={0} onClick={closeMenu}>
                {t("Cancel")}
              </MenuItem>,
            ]}
          >
            <Button
              variant="text"
              disableElevation
              startIcon={<DeleteRounded />}
            >
              {t("Delete")}
            </Button>
          </Menu>
        )}
        <Button
          onClick={handleSave}
          color="primary"
          variant={"contained"}
          disableElevation
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};

export default OpenDevice;
