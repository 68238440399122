import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField as MuiTextField,
  Typography
} from "@material-ui/core";
import { DeleteRounded, ExpandMore } from "@material-ui/icons";
import Axios from "axios";
import { Field, FormikErrors, useFormikContext } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from "formik-material-ui-lab";
import produce from "immer";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import LinearProgress from "../../../../components/LinearProgress/LinearProgress";
import clearEmptyProperties from "../../../../utils/clearEmptyProperties";
import { Role, roleStore } from "../../../Role/RoleState";
import { Tenant } from "../../../Tenant/Tenant";
import { User, UserTenantRole } from "../../User";

export const Tenants: FC = () => {
  const { t } = useTranslation();

  let formikProps = useFormikContext<User>();
  const roles: Role[] = useSelector(roleStore.entitiesSelector);

  let valueTenantRoles: UserTenantRole[] =
    formikProps.values["user_tenant_roles"];
  let setTenantRoles = (newValue: UserTenantRole[]) =>
    formikProps.setFieldValue("user_tenant_roles", newValue);

  let { data, isLoading } = useQuery<{
    "hydra:member": any[];
    "hydra:totalItems": number;
  }>(
    ["tenants", "dropdown"],
    (key, key2, search) =>
      Axios.get("/api/tenants", {
        params: {
          pagination: false,
          ...clearEmptyProperties({
            company: search,
          }),
        },
      }).then((res) => res.data),
    { keepPreviousData: true }
  );
  let tenants = data?.["hydra:member"] || [];

  const [expanded, setExpanded] = React.useState<UserTenantRole["id"] | false>(
    false
  );
  const handleChange = (panel: UserTenantRole["id"]) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {isLoading && (
        <Box mx={-3} mt={-3} mb={3}>
          <LinearProgress />
        </Box>
      )}
      {!isLoading &&
        valueTenantRoles?.map((tenantRole, i) => (
          <Accordion
            expanded={expanded === tenantRole.id}
            onChange={handleChange(tenantRole.id)}
            key={tenantRole.id}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box display="flex" width="100%">
                {tenantRole.tenant ? (
                  <>
                    <img
                      src={tenantRole.tenant.icon}
                      alt=""
                      style={{ maxHeight: 21, marginRight: 8 }}
                    />
                    <Typography>
                      {
                        tenants.find((t) => t["@id"] === tenantRole.tenant)
                          ?.company
                      }
                    </Typography>
                  </>
                ) : (
                  <Typography color="textSecondary">
                    {t("Select a tenant")}
                  </Typography>
                )}
                {expanded === tenantRole.id ? (
                  <>
                    <div style={{ flexGrow: 1, width: 8 }} />
                    <Box display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTenantRoles([
                            ...valueTenantRoles!.filter(
                              (v) => v !== tenantRole
                            ),
                          ]);
                        }}
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <Typography color="textSecondary">
                    {tenantRole?.roles?.map((role) => t(role)).join(", ")}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    id={`user_tenant_roles[${i}].tenant`}
                    name={`user_tenant_roles[${i}].tenant`}
                    onChange={(event: any, newValue: Tenant["@id"]) => {
                      setTenantRoles(
                        produce(valueTenantRoles!, (draft) => {
                          const toUpdate = draft.find(
                            (d) => d.id === tenantRole.id
                          );
                          if (toUpdate) {
                            toUpdate.tenant = newValue?.["@id"] || null;
                          }
                        })
                      );
                    }}
                    getOptionSelected={(
                      option: Tenant,
                      value: Tenant["@id"]
                    ) => {
                      return option["@id"] === value;
                    }}
                    component={Autocomplete}
                    options={tenants.filter(
                      (t) =>
                        !valueTenantRoles?.find(
                          (tr) =>
                            tr.tenant === t["@id"] &&
                            t["@id"] !== tenantRole.tenant
                        )
                    )}
                    getOptionLabel={(option: Tenant) =>
                      option.company ||
                      tenants.find((t) => t["@id"] === option)?.company ||
                      ""
                    }
                    renderTags={(value: any, getTagProps: any) =>
                      value.map((option: any, index: any) => (
                        <Chip
                          variant="outlined"
                          label={option.company}
                          size="small"
                          {...{ ...getTagProps({ index }) }}
                        />
                      ))
                    }
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <MuiTextField
                        {...params}
                        error={
                          formikProps.touched.user_tenant_roles?.[i]?.tenant &&
                          !!(formikProps.errors.user_tenant_roles?.[i] as
                            | FormikErrors<UserTenantRole>
                            | undefined)?.tenant
                        }
                        helperText={
                          formikProps.touched.user_tenant_roles?.[i]?.tenant &&
                          (formikProps.errors.user_tenant_roles?.[i] as
                            | FormikErrors<UserTenantRole>
                            | undefined)?.tenant
                        }
                        label={t("Tenant")}
                        variant="filled"
                        placeholder={t("Select a tenant")}
                        fullWidth
                      />
                    )}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    id={`user_tenant_roles[${i}].roles`}
                    name={`user_tenant_roles[${i}].roles`}
                    component={Autocomplete}
                    options={roles
                      .filter((role: Role) => valueTenantRoles[i]?.roles?.find((userRole: string) => userRole !== role.name))
                      .map((role: Role) => role.name)
                    }
                    multiple
                    getOptionLabel={(option: string) =>
                      t(option)
                    }
                    renderTags={(value: any, getTagProps: any) =>
                      value.map((option: any, index: any) => (
                        <Chip
                          variant="outlined"
                          label={t(option)}
                          size="small"
                          {...{ ...getTagProps({ index }) }}
                        />
                      ))
                    }
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <MuiTextField
                        {...params}
                        error={
                          formikProps.touched.user_tenant_roles?.[i]?.roles &&
                          !!(formikProps.errors.user_tenant_roles?.[i] as
                            | FormikErrors<UserTenantRole>
                            | undefined)?.roles
                        }
                        helperText={
                          formikProps.touched.user_tenant_roles?.[i]?.roles &&
                          (formikProps.errors.user_tenant_roles?.[i] as
                            | FormikErrors<UserTenantRole>
                            | undefined)?.roles
                        }
                        label={t("Roles")}
                        variant="filled"
                        placeholder={t("Select role")}
                        fullWidth
                      />
                    )}
                    fullWidth
                    disableCloseOnSelect
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}

      <Box mt={valueTenantRoles?.length ? 2 : 0}>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          onClick={() => {
            let existingEmpty = valueTenantRoles?.find((v) => !v.tenant);
            if (!existingEmpty) {
              let id = uuid() as any;
              setTenantRoles([
                ...(valueTenantRoles || []),
                { id, tenant: null, roles: [] },
              ]);
              setExpanded(id);
            } else {
              setExpanded(existingEmpty.id);
            }
          }}
          disabled={!!formikProps.errors.user_tenant_roles}
        >
          {t("Add new tenant")}
        </Button>
      </Box>
    </>
  );
};
