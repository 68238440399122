import { Button, IconButton, ListSubheader, MenuItem } from "@material-ui/core"
import { DeleteRounded } from "@material-ui/icons"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import Menu from "../Menu/Menu"


export const DeleteButton: FunctionComponent<{
  loading?: boolean;
  title: string;
  variant?: "text" | "outlined" | "contained" | undefined;
  iconOnly?: boolean;
  size?: "small" | "medium";
  disabled?: boolean;
  onClick: () => void;
}> = ({loading, iconOnly, variant, size, title, disabled, onClick}) => {
  const {t} = useTranslation();

  return (
    <Menu
      loading={!!loading}
      items={(closeMenu) => [
        <ListSubheader key={0} component="div">
          {t("Are you sure you want to delete")} {'"'}
          {title}
          {'"'}?
        </ListSubheader>,
        <MenuItem
          key={0}
          onClick={() => {
            onClick();
            closeMenu();
          }}
        >
          {t("Delete")}
        </MenuItem>,
        <MenuItem key={0} onClick={closeMenu}>
          {t("Cancel")}
        </MenuItem>,
      ]}
    >
      {!!iconOnly ? (
        <IconButton size={size} disabled={disabled}>
          <DeleteRounded />
        </IconButton>
      ) : (
        <Button
          size={size}
          variant={variant || "text"}
          disableElevation
          startIcon={<DeleteRounded />}
          disabled={disabled}
        >
          {t("Delete")}
        </Button>
      )}
    </Menu>
  )
}