export default {
  appTitle: process.env.REACT_APP_TITLE || "Konneksie",
  version: process.env.REACT_APP_VERSION || "1.0.0",
  env: process.env.REACT_APP_ENV || "development",
  apiUrl: process.env.REACT_APP_API_URL || "https://staging-api.konneksie.com/",
  socketUrl:
    process.env.REACT_APP_SOCKET_URL || "wss://staging-api.konneksie.com:1337",
  awsS3Bucket: process.env.REACT_APP_AWS_S3_BUCKET || "",
  awsS3Region: process.env.REACT_APP_AWS_S3_REGION || "",
  awsS3AccessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID || "",
  awsS3SecretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY || "",
  bingKey: "ArujDVi7myW16eX6dAStMue3VunvGgb9Ow9zie0D7K4BnKiawgWyj3uRJ8gN4D-K",
  mock: false,
};
