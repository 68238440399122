import {
  LinearProgressProps,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: -4,
    minHeight: 4,
  },
}));

const LinearProgress: FunctionComponent<LinearProgressProps> = ({
  className,
  ...props
}) => {
  const classes = useStyles();
  return (
    <MuiLinearProgress {...props} className={clsx(classes.root, className)} />
  );
};

export default LinearProgress;
