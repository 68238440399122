import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  AddAlertRounded,
  BusinessRounded,
  DevicesRounded,
  ListRounded,
  RoomRounded, Settings, SupervisedUserCircleRounded
} from "@material-ui/icons";
import DescriptionIcon from '@material-ui/icons/Description';
import GroupIcon from "@material-ui/icons/Group";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocalStorage } from "react-use";
import Drawer, {
  Menu,
  toolbarStyles
} from "../../common/components/AppHeader/Drawer/Drawer";
import {
  authIsAuthenticatedSelector
} from "../../domains/Auth/authSlice";
import ls from "../../utils/localStorageKey";
import AppBar from "./AppBar/AppBar";
import logoDarkSvg from "./logo-dark.svg";
import logoSvg from "./logo.svg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },

  logo: {
    display: "flex",
    color: "inherit",
    marginRight: theme.spacing(2),
    "& img": {
      height: theme.spacing(3),
    },
  },

  toolbar: {
    ...toolbarStyles(theme),
  },

  content: {
    flexGrow: 1,
    minWidth: 0,
  },
  loading: {
    height: theme.spacing(18),
    "&": {
      height:
        "calc(100vh - " +
        toolbarStyles(theme).minHeight.replace(" !important", "") +
        ")",
    },
    color: theme.palette.grey["400"],
  },
}));


const AppHeader: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const [isDrawerOpen, setDrawerOpen] = useLocalStorage<boolean>(
    ls("isDrawerOpen"),
    !isMobile
  );
  const isAuthenticated = useSelector(authIsAuthenticatedSelector);

  let menus: Menu[] = [];
  menus = [
    {
      title: t("Tenants"),
      icon: <BusinessRounded />,
      url: "/tenants",
      roles: ["SUPER_ADMIN", "ROLE_SUPER_ADMIN"],
    },
    {
      title: t("Users"),
      icon: <SupervisedUserCircleRounded />,
      url: "/users",
      permissions: ["USER_CREATE", "USER_READ", "USER_EDIT", "USER_DELETE"]
    },
    { title: t("Permissions"), icon: <Settings />, url: "/permissions", permissions: ["PERMISSION_CREATE", "PERMISSION_READ", "PERMISSION_EDIT", "PERMISSION_DELETE"] },
    { title: t("Assets"), icon: <ListRounded />, url: "/assets", permissions: ["ASSET_CREATE", "ASSET_READ", "ASSET_EDIT", "ASSET_DELETE"] },
    { title: t("Asset profiles"), icon: <DescriptionIcon />, url: "/asset-profiles", permissions: ["ASSET_PROFILE_READ", "ASSET_PROFILE_EDIT"] },
    { title: t("Asset map"), icon: <RoomRounded />, url: "/asset-map", permissions: ["VIEW_ASSET_MAP"] },
    { title: t("Devices"), icon: <DevicesRounded />, url: "/devices", permissions: ["DEVICE_CREATE", "DEVICE_READ", "DEVICE_EDIT", "DEVICE_DELETE"] },
    {
      title: t("Alarm rules"),
      icon: <AddAlertRounded />,
      url: "/alarm-rules",
      permissions: ["ALARM_CREATE", "ALARM_READ", "ALARM_EDIT", "ALARM_DELETE"]
    },
    { title: t("Customers"), icon: <GroupIcon />, url: "/customers", permissions: ["CUSTOMER_CREATE", "CUSTOMER_READ", "CUSTOMER_EDIT", "CUSTOMER_DELETE"] },
  ];

  const logo = (
    <Link to="/" className={classes.logo}>
      <img
        src={theme.palette.type === "light" ? logoSvg : logoDarkSvg}
        alt={t("logo")}
      />
    </Link>
  );

  return (
    <div className={classes.root}>
      <AppBar
        open={Boolean(isDrawerOpen)}
        logo={logo}
        handleDrawerOpen={() => setDrawerOpen(true)}
        className="no-print"
      />
      <Drawer
        open={Boolean(isDrawerOpen)}
        logo={logo}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        show={isAuthenticated}
        menus={menus}
        className="no-print"
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>{children}</div>
      </main>
    </div>
  );
};

export default AppHeader;
