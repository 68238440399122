import useTitle from "../../common/hooks/useTitle";
import React from "react";
import Table from "./Table/Table";
import OpenAlarmRule from "./OpenAlarmRule/OpenAlarmRule";
import { useTranslation } from "react-i18next";

export const AlarmRules = () => {
  const { t } = useTranslation();
  useTitle(t("Alarm rules"));

  return (
    <>
      <Table />

      <OpenAlarmRule />
    </>
  );
};
