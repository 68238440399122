import React, { FunctionComponent } from "react";
import { TableCell, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const TableCellHeadNoWrap: FunctionComponent<{ label?: string }> = ({
  label,
}) => {
  const { t } = useTranslation();

  return (
    <TableCell>
      {label && (
        <Typography noWrap variant="inherit">
          {t(label)}
        </Typography>
      )}
    </TableCell>
  );
};
