import { Button, Grid, Hidden, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import update from "immutability-helper";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useToggle, useUpdateEffect } from "react-use";
import { v4 as uuid } from "uuid";
import { DeleteButton } from "../../../common/components/Buttons/DeleteButton";
import { AssetProfileCableReelCable, AssetProfileCableReelType, AssetProfileDefinition, CableRealDetail } from "../../AssetProfile/assetProfile";
import { assetProfileDefinitionStore } from "../assetProfileDefinitionStore";
import CableDetails from "./CableDetails";
import CableReelDetails from "./CableReelDetails";
  
const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: "pointer",
  }
}))

const DetailTable: FunctionComponent<{
  headers: string[];
  title: string;
  onAdd: () => void;
}> = ({headers, title, onAdd, children}) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <Typography variant="h6">{title}</Typography>
        <div style={{flexGrow: 1}} />
        <Button onClick={onAdd} variant="contained" size="small" color="primary">{t("add new item")}</Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header: string, index: number) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
              <Hidden xsUp>
                <TableCell>{t("Actions")}</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export const CableReelProfile: FunctionComponent<{
  assetProfile: AssetProfileDefinition;
}> = ({ assetProfile }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedCable, setSelectedCable] = useState<AssetProfileCableReelCable>();
  const [selectedProfile, setSelectedProfile] = useState<AssetProfileCableReelType>();
  
  const [profiles, setProfiles] = useState<AssetProfileCableReelType[]>([]);
  const [cables, setCables] = useState<AssetProfileCableReelCable[]>([]);

  const [hasChanges, setHasChanges] = useToggle(false);

  useEffect(() => {
    if(!!assetProfile) {
      const cableReelsIndex = findDefinitionIndexByName("cable_reels");
      if(!!cableReelsIndex && cableReelsIndex >= 0) {
        setProfiles(assetProfile?.definition![cableReelsIndex].data);
      }

      const cablesIndex = findDefinitionIndexByName("cables");
      if(!!cablesIndex && cablesIndex >= 0) {
        setCables(assetProfile?.definition![cablesIndex].data);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetProfile]);

  const findDefinitionIndexByName = (name: string) => {
    return assetProfile?.definition?.findIndex((d) => d.name === name);
  }

  useUpdateEffect(() => {      
    if(!!hasChanges) {
      const cableReelsIndex = findDefinitionIndexByName("cable_reels");
      let updatedEntity = update(assetProfile, {});
      if(cableReelsIndex === -1) {
        updatedEntity = update(updatedEntity, {
          definition: {
            $push: [{name: "cable_reels", type: "cable_reels", data: profiles}]
          }
        });
      } else if(!!cableReelsIndex && cableReelsIndex >= 0) {
        updatedEntity = update(updatedEntity, {
          definition: {
            [cableReelsIndex]: {
              data: {$set: profiles}
            }
          }
        });
      }

      const cableIndex = findDefinitionIndexByName("cables");
      if(cableIndex === -1) {
        updatedEntity = update(updatedEntity, {
          definition: {
            $push: [{name: "cables", type: "cables", data: cables}]
          }
        });
      } else if(!!cableIndex && cableIndex >= 0) {
        updatedEntity = update(updatedEntity, {
          definition: {
            [cableIndex]: {
              data: {$set: cables}
            }
          }
        });
      }

      dispatch(assetProfileDefinitionStore.setOpenEntity(updatedEntity));
      setHasChanges(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles, cables]);

  const handleAddCableReel = () => {
    setHasChanges(true);
    setSelectedProfile({id: uuid().toString(), name: "", width: 0, diameterMax: 0, diameterMin: 0});
  }
  
  const handleAddCable = () => {
    setHasChanges(true);
    setSelectedCable({id: uuid().toString(), name: "", description: "", diameter: 0});
  }

  const handleChangeProfile = (profile: AssetProfileCableReelType) => {
    const items = handleChangeItem(profiles, profile);
    setProfiles(items as AssetProfileCableReelType[]);

    clearSelectedProfile();
  }

  const handleChangeCable = (cable: AssetProfileCableReelCable) => {
    const items = handleChangeItem(cables, cable);
    setCables(items as AssetProfileCableReelCable[]);

    clearSelectedCable();
  }

  const handleChangeItem = (items: CableRealDetail[], item: CableRealDetail) => {
    setHasChanges(true);
    const index = items?.findIndex((p) => p.id === item.id);
    if(index === -1) {
      return [...items, item];
    } else {
      const updatedItems = update(items, {
        [index]: {
          $set: item
        }
      });
      return updatedItems;
    }
  }
  
  const handleDeleteCableReel = (profile: AssetProfileCableReelType) => {
    const items = deleteItem(profiles, profile);
    setProfiles(items as AssetProfileCableReelType[]);
  }
  
  const handleDeleteCable = (cable: CableRealDetail) => {
    const items = deleteItem(cables, cable);
    setCables(items as AssetProfileCableReelCable[]);
  }

  const deleteItem = (items: CableRealDetail[], item: CableRealDetail): any => {
    setHasChanges(true);
    const index = items?.findIndex((p) => p.id === item.id);
    if(index >= 0) {
      const updatedItems = update(items, {
        $splice: [[index, 1]]
      });

      return updatedItems;
    }

    return items;
  }

  const clearSelectedProfile = () => setSelectedProfile(undefined);
  const clearSelectedCable = () => setSelectedCable(undefined);

  return (
    <>
      <Grid item xs={12}>
        <DetailTable headers={[t("Name")]} title={t("cable_reel_type")} onAdd={handleAddCableReel}>
          {profiles?.map((profile) => (
            <TableRow onClick={() => setSelectedProfile(profile)} className={classes.tableRow}>
              <TableCell>{profile.name}</TableCell>
              {/* TODO hide untill its requested by customer TKF*/}
              <Hidden xsUp>
                <TableCell size="small">
                  <DeleteButton disabled={true} size="small" title={profile.name} onClick={() => handleDeleteCableReel(profile)} iconOnly />
                </TableCell>
              </Hidden>
            </TableRow>
          ))}
        </DetailTable>
      </Grid>

      <Grid item xs={12}>
        <DetailTable headers={[t("Name"), t("Description")]} title={t("cable_type")} onAdd={handleAddCable}>
          {cables?.map((cable) => (
            <TableRow onClick={() => setSelectedCable(cable)} className={classes.tableRow}>
              <TableCell>{cable.name}</TableCell>
              <TableCell>{cable.description}</TableCell>
              {/* TODO hide untill its requested by customer TKF*/}
              <Hidden xsUp>
                <TableCell size="small">
                  <DeleteButton disabled={true} size="small" title={cable.name} onClick={() => handleDeleteCable(cable)} iconOnly />
                </TableCell>
              </Hidden>
            </TableRow>
          ))}
        </DetailTable>
      </Grid>

      <CableReelDetails profile={selectedProfile} onSave={handleChangeProfile} onClose={clearSelectedProfile} />
      <CableDetails cable={selectedCable} onSave={handleChangeCable} onClose={clearSelectedCable} />
    </>
  );
};
  