import React, { useEffect, useMemo, useRef } from "react";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { InferType } from "yup";
import getFieldsFromSchema from "../../../utils/getFieldsFromSchema";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { openTenantAtom } from "../Tenants.state";
import { DeleteButton } from "./DeleteButton/DeleteButton";
import clearEmptyProperties from "../../../utils/clearEmptyProperties";
import { queryCache, useMutation } from "react-query";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { Tenant } from "../Tenant";
import { useSnackbar } from "notistack";
import Dropzone from "react-dropzone";
import fileToBase64 from "../../../utils/fileToBase64";
import { useAtom } from "jotai";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Publish } from "@material-ui/icons";

const IconField = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 16px;
  min-height: 56px;
`;

const TenantSchema = Yup.object({
  subdomain: Yup.string().label("Subdomain").default(""),
  company: Yup.string().label("Company").default(""),
  address: Yup.string().label("Address").default(""),
  zipcode: Yup.string().label("Zip code").default(""),
  city: Yup.string().label("City").default(""),
  country: Yup.string().label("Country").default(""),
  phone: Yup.string().label("Phone").default(""),
  email: Yup.string().label("Email").default(""),
  icon: Yup.string()
    .label("Icon")
    .default("")
    .meta({
      render(field: any, formikProps: FormikProps<any>, isLoading: boolean) {
        return (
          <>
            <Dropzone
              disabled={isLoading}
              onDrop={async (acceptedFiles) => {
                if (acceptedFiles.length) {
                  formikProps.setFieldValue(
                    field.name,
                    await fileToBase64(acceptedFiles[0])
                  );
                } else {
                  formikProps.setFieldError(
                    field.name,
                    (
                      <Trans i18nKey="The icon must be an image with up to 50kb" />
                    ) as any
                  );
                }
              }}
              accept="image/*"
              multiple={false}
              maxSize={50 * 1000}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <IconField>
                      {!!formikProps.values[field.name] && (
                        <Box display="flex" justifyContent="center" mb={2}>
                          <img src={formikProps.values[field.name]} alt="" />
                        </Box>
                      )}
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        {!formikProps.values[field.name] ? (
                          <Trans i18nKey="Drag and drop the icon here or click here to select the icon" />
                        ) : (
                          <Trans i18nKey="Drag and drop the new icon here or click here to change the icon" />
                        )}
                      </Typography>
                    </IconField>
                  </div>
                </section>
              )}
            </Dropzone>
            {formikProps.errors[field.name] && (
              <FormHelperText error>
                {formikProps.errors[field.name]}
              </FormHelperText>
            )}
          </>
        );
      },
    }),
}).required();
const TenantFields = getFieldsFromSchema(TenantSchema);
export type TenantValues = InferType<typeof TenantSchema>;

export const Details = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  let [openTenant, setOpenTenant] = useAtom(openTenantAtom);
  openTenant = useMemo(() => clearEmptyProperties(openTenant), [openTenant]);

  const formRef = useRef<FormikProps<TenantValues>>(null);
  const submitFormRef = useRef<HTMLButtonElement>(null);

  const [saveTenant, { isLoading }] = useMutation<
    AxiosResponse,
    AxiosError,
    Tenant
  >(
    (data) => {
      return !openTenant?.id
        ? Axios.post("/api/tenants", data)
        : Axios.put(`/api/tenants/${openTenant.id}`, data);
    },
    {
      onSuccess() {
        enqueueSnackbar(
          t(!openTenant?.id ? "Tenant created" : "Tenant updated")
        );
        void queryCache.invalidateQueries("tenants");
        setOpenTenant(null);
      },
      onError(error) {
        enqueueSnackbar(error.response?.data.message || error.message);
      },
    }
  );

  useEffect(() => {
    formRef.current?.setValues({ ...TenantSchema.default(), ...openTenant });
  }, [openTenant]);

  let [uploadDevicesCsv, { isLoading: isUploading }] = useMutation<
    unknown,
    AxiosError,
    [Tenant, File[]]
  >(([tenant, files]) => {
    const data = new FormData();
    for (const file of files) {
      data.append("files", file);
    }
    return Axios.post(`/api/tenants/${tenant.id}/devices/upload`, data);
  });

  const handleImportDevices = (files: File[]) => {
    void uploadDevicesCsv([openTenant as Tenant, files]);
  };

  return (
    <SidePanelModal
      title={`${t(openTenant?.id ? "Edit tenant" : "Create tenant")}${
        openTenant?.id
          ? ` ${openTenant.descriptionShort || openTenant?.id}`
          : ""
      }`}
      width={theme.spacing(50)}
      open={!!openTenant}
      onClose={() => (!isLoading ? setOpenTenant(null) : null)}
      loading={isLoading || isUploading}
    >
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={{ ...TenantSchema.default(), ...openTenant }}
          onSubmit={(values) => void saveTenant(values)}
          validationSchema={TenantSchema}
        >
          {(formikProps) => (
            <Form>
              <Grid container spacing={2}>
                {TenantFields.map((field) => (
                  <Grid item xs={12} key={field.name}>
                    {!field.meta?.render ? (
                      <Field
                        component={TextField}
                        variant="filled"
                        fullWidth
                        name={field.name}
                        label={t(field.label)}
                        disabled={isLoading}
                        required={field.required}
                      />
                    ) : (
                      field.meta.render(field, formikProps, isLoading)
                    )}
                  </Grid>
                ))}
                {openTenant?.id && (
                  <Grid item xs={12}>
                    <Dropzone onDrop={handleImportDevices} accept=".csv">
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <input {...getInputProps()} />
                          <Button
                            variant="contained"
                            color={"default" as any}
                            disableElevation
                            startIcon={<Publish />}
                            {...getRootProps()}
                          >
                            {t("Import devices")}
                          </Button>
                        </>
                      )}
                    </Dropzone>
                  </Grid>
                )}
              </Grid>
              <button
                ref={submitFormRef}
                style={{ padding: 0, border: "none" }}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        {openTenant?.id && <DeleteButton />}
        <Button
          variant="contained"
          disableElevation
          color="primary"
          disabled={isLoading}
          onClick={() => submitFormRef.current?.click()}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};
