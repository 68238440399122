import { Api } from "../../store/Api";
import { ApiState, ApiStore } from "../../store/ApiStore";
import { AssetProfileDefinition } from "./assetProfile";


declare interface State extends ApiState<AssetProfileDefinition, AssetProfileDefinition> {}
export class AssetProfileDefinitionStore extends ApiStore<AssetProfileDefinition, AssetProfileDefinition, State> {
  public constructor() {
    super("/api/asset-profile-definitions", ({} as unknown) as State, {});
  }
}

const assetProfileDefinitionStore = new AssetProfileDefinitionStore();
export { assetProfileDefinitionStore };


class AssetProfileDefinitionApi extends Api<AssetProfileDefinition, AssetProfileDefinition> {
  public constructor() {
    super("/api/asset-profile-definitions");
  }
}

export const assetProfileDefinitionApi = new AssetProfileDefinitionApi();