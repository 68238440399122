// noinspection SpellCheckingInspection
const fr = {
  translation: {
    "Username or e-mail": "Nom d'utilisateur ou email",
    "Password": "Mot de passe",
    "Stay logged in": "Rester connecté",
    "Forgot your password?": "Avez-vous oublié votre mot de passe?",
    "Sign in": "S'inscrire",
    "Unknown": "Inconnu",
    "English": "Anglais",
    "Dutch": "Néerlandais",
    "German": "Allemand",
    "French": "Français",
    "is required": "est nécessaire",
    "Home": "à la Maison",
    "logo": "Logo",
    "Sign out": "Se déconnecter",
    "Powered by": "Supporté par",
    "Under construction": "En préparation",
    "Rows per page": "Lignes par page",
    "Assets": "Actifs financiers",
    "Asset map": "Liste des actifs",
    "of": "de",
    "more than": "plus que",
    "ID": "ID",
    "Name": "Nom de famille",
    "Street": "Rue",
    "Postal code": "Code postal",
    "City": "Ville",
    "Region": "Région",
    "Country": "Pays",
    "Days still": "Jours restants",
    "Last communication": "Dernier message",
    "Last location update": "Dernière mise à jour de l'emplacement ",
    "Device last communication": "Dernier message",
    "Geo zone": "Géozone",
    "Client": "Client",
    "Asset group": "Groupe d'actifs",
    "Description": "La description",
    "Days outside geo zone": "Jours de prêt",
    "No problems": "Aucun problème",
    "User not found or wrong password":
      "Utilisateur introuvable ou mot de passe incorrect",
    "Address": "Adresse",
    "Asset": "Atout ",
    "Last page": "Dernière page",
    "Next page": "Page suivante",
    "Previous page": "Page précédente",
    "First page": "Première page",
    "Search": "Recherche",
    "Advanced filter": "Filtre avancé",
    "New": "Nouveau",
    "Events": "Location updates",
    "Devices": "Dispositif",
    "Add new": "Ajouter un nouveau",
    "Delete": "Supprimer",
    "Link to Group": "Lien vers le groupe",
    "Link to Client": "Lien vers le client",
    "Selected": "Choisi",
    "There was an error while trying to fetch the events from the asset":
      "Une erreur s'est produite lors de la tentative d'obtention des événements de l'élément",
    "Not in a zone": "Pas dans une zone",
    "Interval-based": "Intervalle de temps",
    "Clients": "Clients",
    "Asset groups": "Groupes d'actifs",
    "Type": "Type",
    "External ID": "ID externe",
    "Empty": "Vide",
    "Device activation date": "Date d'activation du dispositif",
    "Model": "Modèle",
    "DEVEUI": "DEVEUI",
    "Barcode": "Code barre",
    "Notes": "Notes",
    "Device": "Dispositif",
    "Page not found": "Page non trouvée",
    "From": "De",
    "Invalid date format": "Format de date non valide",
    "To": "À ",
    "Asset updated": "Atout mis à jour",
    "Asset deleted": "Atout  supprimé",
    "Loading": "Chargement",
    "Try again": "Essayez à nouveau",
    "All": "Tous",
    "Start": "Démarrer",
    "Moving": "déplacer",
    "Still": "Toujours",
    "Stopped": "Arrêté",
    "Interval-based-Local-Gateway": "Passerelle locale basée sur l'intervalle",
    "Users": "Utilisateurs",
    "ROLE_USER": "Utilisateur",
    "ROLE_ADMIN": "Administrateur",
    "ROLE_CLIENT": "Client",
    "ROLE_TRANSPORTER": "Transporteur",
    "ROLE_ACCOUNT_MANAGER": "Gestionnaire de compte",
    "User": "Utilisateur",
    "Username": "Nom d'utilisateur",
    "Role": "Rôle",
    "critical": "Critique",
    "major": "Principale",
    "minor": "Moins",
    "warning": "Avertissement",
    "none": "Aucune",
    "no items": "Pas d'objet",
    "add new item": "Ajouter un nouvel élément",
    "The file will be uploaded when you save the new asset":
      "Le fichier sera téléchargé lorsque vous sauvegarderez le nouvel actif",
    "There was an error while trying to retrieve the attachment":
      "Une erreur s'est produite lors de la récupération de la pièce jointe",
    "There was an error while trying to upload the attachment":
      "Une erreur s'est produite lors de la tentative de téléchargement de la pièce jointe",
    "Drag and drop files here": "Glisser-déposer des fichiers ici",
    "or": "ou",
    "Browse files": "Recherche de fichiers",
    "Release the file to attach it": "Partager le fichier pour le joindre",
    "User added": "Utilisateur ajouté",
    "Status": "Statut",
    "There was an error while trying to fetch the files credentials":
      "Une erreur s'est produite lors de la récupération des informations d'identification des fichiers",
    "Asset not found": "Actif non trouvé",
    "Save": "Économiser",
    "Save asset profile": "Êtes-vous sûr de vouloir enregistrer les modifications?",
    "Import devices": "Importation de dispositifs",
    "Alarm rules": "Règles d'alerte",
    "Alarm rule added": "Ajout d'une règle d'alarme",
    "Email": "Email",
    "Asset tag is sabotaged": "La balise de l'actif est sabotée",
    "Asset is reported as missing": "Un bien est déclaré disparu",
    "Asset is outside the Geo Zone":
      "l'actif est en dehors de la zone géographique",
    "Asset has battery level low": "L'actif a un niveau de batterie faible",
    "Alarm rule": "Règle d'alerte",
    "The name is required": "Le nom est obligatoire",
    "The email needs to be valid": "L'email doit être valide",
    "Sabotage has been detected with": "Un sabotage a été détecté avec",
    "Low battery has been detected for": "Une batterie faible a été détectée pendant",
    "has been detected outside geozone": "a été détecté en dehors de la géozone",
    "Text": "Texte",
    "Alarm rule updated": "Mise à jour de la règle d'alarme",
    "Sensor name": "Nom du capteur",
    "Change": "Changement",
    "Active": "Actif",
    "Roles": "Rouleaux",
    "Change my password": "Changer mon mot de passe",
    "No result found": "Aucune entrée trouvée",
    "Edit": "Editer",
    "User deleted": "Utilisateur supprimé",
    "Filter": "Filtre",
    "Must be a number": "Doit être un numéro",
    "Must be a valid email": "Doit être une adresse électronique valide",
    "Are you sure you want to delete": "Êtes-vous sûr de vouloir supprimer?",
    "Cancel": "Annuler",
    "Fill all the required fields": "Remplissez tous les emplacements requis",
    "Devices imported": "Dispositif importé",
    "Select one type": "Sélectionnez un type",
    "The text is required": "Le texte est obligatoire",
    "Device deleted": "Dispositif supprimé",
    "Device updated": "Dispositif mis à jour",
    "You don't have any assets to show on the map":
      "Vous ne pouvez pas visualiser les biens sur la carte",
    "Drone no-fly areas": "Pas de zones de vol pour les drones",
    "Fine dust": "Poussières fines",
    "Foundation problems": "Problèmes de fondation",
    "Height map": "Carte d'élévation",
    "Electricity pylons": "Poteaux électriques",
    "Quality of life card": "Carte de la qualité de vie",
    "Premises": "Locaux",
    "Parcels": "Terrains",
    "Map layers": "Couches de la carte",
    "Company": "Société",
    "Logical ID": "Identification logique",
    "Reference card": "Carte de référence",
    "Map": "Carte",
    "Satelite": "Satellite",
    "Satelite with Labels": "Satellite avec étiquettes",
    "Clear": "Clair",
    "Asset is sabotaged": "Un bien est saboté",
    "Create": "Créer",
    "Switch to tenant {{name}}": "Passer au locataire {{nom}}",
    "#": "#",
    "Subdomain": "Sous-domaine",
    "Zip code": "Code postal",
    "Phone": "Téléphone",
    "Tenants": "Locataire",
    "Add role": "Ajouter un rôle",
    "Enter the role name": "Entrez le nom du rôle",
    "Search for an ID": "Recherche d'une pièce d'identité",
    "Create tenant": "Créer un locataire",
    "Yes": "Oui",
    "No": "Non",
    "Are you sure you want to delete?": "Êtes-vous sûr de vouloir supprimer ?",
    "Edit tenant": "insérer le locataire",
    "Drag and drop the icon here or click here to select the icon":
      "Faites glisser l'icône ici ou cliquez ici pour sélectionner l'icône",
    "Tenant created": "Locataire créé",
    "Tenant updated": "Locataire mis à jour",
    "Tenant deleted": "Locataire supprimé",
    "Tenant not found": "Locataire non trouvé",
    "Create user": "Créer un utilisateur",
    "Preferences": "Paramètres",
    "Employee number": "Numéro d'employé",
    "Full name": "Nom et prénom",
    "New password": "Nouveau mot de passe",
    "Edit user": "Modifier l'utilisateur",
    "Details": "Détails",
    "Select role": "Choisir un rôle",
    "Tenant roles": "Rôles de locataire",
    "Add new tenant": "Ajouter un nouveau locataire",
    "Select a tenant": "Sélectionner un locataire",
    "Tenant": "Locataire",
    "User updated": "Utilisateur mis à jour",
    "Customers": "Clients",
    "Customer": "Client",
    "New Customer": "Nouveau client",
    "Not assigned to a client": "Non attribué à un client",
    "GeoZone added": "GeoZone a ajouté",
    "Report empty buck": "Rapport vide",
    "Asset is signed off": "l'actif est déconnecté",
    "Open": "Ouvrir",
    "Report delivered buck": "Fourni",
    "Asset is delivered": "l'actif va être livré",
    "Delivered": "Livré",
    "Schedule": "horaire",
    "Scheduled": "Prévu",
    "Date": "Date",
    "Reset password": "Réinitialiser le mot de passe",
    "An email is send to {{email}}": "Un email est envoyé à {{email}}",
    "Back to login": "Revenir à la page de connexion",
    "Reference": "Référence",
    "ASSET_CREATE": "Créer des actifs",
    "ASSET_READ": "Lire les actifs",
    "ASSET_EDIT": "Modifier les éléments",
    "ASSET_DELETE": "Supprimer des éléments",
    "ALARM_CREATE": "Créer des alarmes",
    "ALARM_READ": "Lire les alarmes",
    "ALARM_EDIT": "Modifier les alarmes",
    "ALARM_DELETE": "Supprimer les alarmes",
    "DEVICE_CREATE": "Créer des appareils",
    "DEVICE_READ": "Lire les appareils",
    "DEVICE_EDIT": "Modifier les appareils",
    "DEVICE_DELETE": "Supprimer des appareils",
    "RESET_LOAN_DAYS": "Réinitialiser les jours de prêt",
    "reset_loan_days_message": "Êtes-vous sûr de vouloir réinitialiser les jours de prêt?",
    "VIEW_ASSET_MAP": "Voir la carte des actifs",
    "USER_CREATE": "Créer un utilisateur",
    "USER_READ": "Afficher l'utilisateur",
    "USER_EDIT": "Modifier l'utilisateur",
    "USER_DELETE": "Supprimer l'utilisateur",
    "PERMISSION_CREATE": "Créer des rôles",
    "PERMISSION_READ": "Afficher les rôles",
    "PERMISSION_EDIT": "Modifier les rôles",
    "PERMISSION_DELETE": "Supprimer des rôles",
    "CUSTOMER_CREATE": "Créer un client",
    "CUSTOMER_READ": "Voir le client",
    "CUSTOMER_EDIT": "Modifier le client",
    "CUSTOMER_DELETE": "Supprimer le client",
    "ASSET_PROFILE_READ": "Afficher le profil de l'actif",
    "ASSET_PROFILE_EDIT": "Lire le profil de l'actif",
    "CABLE_REEL_RESET": "Réinitialiser la longueur du câble",
    "Permissions": "Rôles et droits",
    "Battery status": "État de la batterie",
    "Battery value low": "Valeur de la batterie faible",
    "Cable reel profile": "Profil d'enrouleur de câble",
    "cable_type": "Type de câble",
    "cable_reel_type": "Type de bobine",
    "cable_length": "Longueur de câble (m)",
    "cable_diameter": "Diamètre du câble (mm)",
    "cable_reel_width": "Largeur d'enroulement (mm)",
    "cable_reel_diameter_min": "Diamètre du noyau (mm)",
    "cable_reel_diameter_max": "Diamètre de la bobine (mm)",
    "cable_residual_length": "Longueur résiduelle du câble",
    "Device profiles": "Profils d'appareils",
    "cable_length_reset_message": "Êtes-vous sûr de vouloir réinitialiser la longueur?",
    "Account Manager": "Gestionnaire de compte",
    "Not assigned to a account manager": "Non affecté à un gestionnaire de compte",
    "The client will receive an email with login credentials.": "Le client recevra un e-mail avec les identifiants de connexion.",
    "e.g.": "par exemple",
    "Asset profiles": "Profils d'actifs",
  },
};

export default fr;
