import {
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { Publish } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropertyField from "../../../../components/PropertyField/PropertyField";
import { authUserSelector } from "../../../Auth/authSlice";
import { uploadDevicesCsv } from "../../../Device/devicesSlice";
import { ROLES, User, UserRole } from "../../User";
import { updateOpenUser, usersOpenUserSelector } from "../../usersSlice";

export const Details: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openUser = useSelector(usersOpenUserSelector);
  const authUser = useSelector(authUserSelector);
  const isAdmin = authUser?.roles.includes(UserRole.ROLE_ADMIN);
  const hasId = openUser?.id;

  const handleImportDevices = (files: File[]) => {
    dispatch(uploadDevicesCsv(openUser as User, files));
  };

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <PropertyField
          label={t("Company")}
          value={openUser?.company}
          isEditing={true}
          xs={12}
          autoComplete="off"
          onChange={(value) => dispatch(updateOpenUser({ company: value }))}
        />
        <PropertyField
          label={t("Name")}
          value={openUser?.name}
          isEditing={true}
          required
          xs={12}
          autoComplete="off"
          onChange={(value) => dispatch(updateOpenUser({ name: value }))}
        />
        <PropertyField
          label={t("Username")}
          value={openUser?.username}
          isEditing={isAdmin}
          xs={12}
          autoComplete="off"
          onChange={(value) => dispatch(updateOpenUser({ username: value }))}
        />
        <PropertyField
          label={t("Email")}
          value={openUser?.email}
          isEditing={isAdmin}
          required
          xs={12}
          autoComplete="off"
          onChange={(value) => dispatch(updateOpenUser({ email: value }))}
        />
        <PropertyField
          label={`${openUser?.id ? `${t("Change")} ` : ""}${t("Password")}`}
          value={openUser?.password}
          isEditing={true}
          required={!openUser?.id}
          xs={12}
          onChange={(value) => dispatch(updateOpenUser({ password: value }))}
        />
        {isAdmin && !openUser?.roles?.includes(UserRole.ROLE_ADMIN) && (
          <>
            <Grid item xs={12}>
              <TextField
                label={t("Roles")}
                variant="filled"
                fullWidth
                select
                value={openUser?.roles || []}
                onChange={({ target: { value } }) =>
                  dispatch(
                    updateOpenUser({
                      roles: (value as unknown) as User["roles"],
                    })
                  )
                }
                SelectProps={{
                  multiple: true,
                }}
                required
              >
                {ROLES.map((role) => (
                  <MenuItem key={role} value={role}>
                    {t(role)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{t("Status")}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          openUser?.active !== undefined
                            ? Boolean(openUser?.active)
                            : false
                        }
                        onChange={({ target: { checked } }) =>
                          dispatch(updateOpenUser({ active: checked }))
                        }
                        name="enabled"
                      />
                    }
                    label={t("Active")}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {hasId && isAdmin && (
              <Grid item xs={12}>
                <Dropzone onDrop={handleImportDevices} accept=".csv">
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <input {...getInputProps()} />
                      <Button
                        variant="contained"
                        color={"default" as any}
                        disableElevation
                        startIcon={<Publish />}
                        {...getRootProps()}
                      >
                        {t("Import devices")}
                      </Button>
                    </>
                  )}
                </Dropzone>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </DialogContent>
  );
};
