import {
  Button, DialogContent, Grid,
  TextField, Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import PropertyField from "../../../../components/PropertyField/PropertyField";
import { User } from "../../../User/User";
import { userApi } from "../../../User/userApi";
import { Customer } from "../../Customer";
import {
  openCustomerSelector,
  sendResetPassword, updateOpenCustomer
} from "../../customersSlice";

export const Details: FunctionComponent<{
  isEditing: boolean;
}> = ({isEditing}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [users, setUsers] = useState<User[]>([]);
  const [accountManager, setAccountManager] = useState<User>();

  const openCustomer = useSelector(openCustomerSelector);

  useMount(() => {
    userApi.fetch()
      .then((res) => {
        setUsers(res["hydra:member"]);
        console.log("result: ", res);
      });
  });

  useEffect(() => {
    if(!!openCustomer?.account_manager) {
      userApi.get(openCustomer?.account_manager)
        .then((res) => {
          setAccountManager(res);
          console.log("accountManager: ", res);
        });
    }
  }, [openCustomer]);

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <PropertyField
          label={t("Company")}
          value={openCustomer?.company}
          isEditing={isEditing}
          xs={12}
          autoComplete="off"
          onChange={(value) => dispatch(updateOpenCustomer({ company: value }))}
        />
        <PropertyField
          label={t("Name")}
          value={openCustomer?.name}
          isEditing={isEditing}
          required
          xs={12}
          autoComplete="off"
          onChange={(value) => dispatch(updateOpenCustomer({ name: value }))}
        />
        <PropertyField
          label={t("Email")}
          value={openCustomer?.email}
          isEditing={isEditing}
          required
          xs={12}
          autoComplete="off"
          onChange={(value) => dispatch(updateOpenCustomer({ email: value }))}
        />
        {isEditing ? (
          <Grid item xs={12}>
            <Autocomplete
              options={users}
              disableCloseOnSelect
              getOptionLabel={(option: User) => {
                return option.name || t("Unknown")
              }}
              // loading={isLoading}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option, { selected }) => <>{option.name || t("Unknown")}</>}
              fullWidth
              loadingText={`${t("Loading")}...`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("Account Manager")}`}
                  fullWidth
                  variant="filled"
                  // className={classes.textField}
                  // error={!isCustomersLoading && !!isCustomersLoading}
                />
              )}
              ChipProps={{
                size: "small",
              }}
              onChange={(_, value) => {
                if(!!value) {
                  dispatch(updateOpenCustomer({account_manager: value["@id"]})); 
                }
              }}
              // noOptionsText={customersErrorMessage}
              value={accountManager || null}
            />
          </Grid>
        ) : (
          <PropertyField
            label={t("Account Manager")}
            value={
              !!accountManager?.name ?
              accountManager?.name || t("Unknown")
              : t("Not assigned to a account manager")
               
            }
            xs={12}
          />
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              dispatch(sendResetPassword(openCustomer as Customer))
            }
          >
            {t("Reset password")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            {t("The client will receive an email with login credentials.")}
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
