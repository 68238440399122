import { Button, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import { ConfirmButton } from "../../../../common/components/Buttons/ConfirmButton";
import { AssetProfile } from "../../../AssetProfile/assetProfile";
import { assetProfileApi } from "../../../AssetProfile/assetProfileApi";
import { CableReelProfile } from "../../AssetProfiles/CableReelProfile";


declare type AssetProfileDetailsProps = {
  uri: string;
}
export const AssetProfileDetails: FunctionComponent<AssetProfileDetailsProps> = ({ uri }) => {
  const [isLoading, setIsLoading] = useToggle(false);
  const [assetProfile, setAssetProfile] = useState<AssetProfile>();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useToggle(false);

  const handleSave = () => {
    if(!!assetProfile) {
      assetProfileApi.put(assetProfile)
        .then((result: AssetProfile) => {
          setAssetProfile(result);
        })
        .finally(() => {
          setIsLoading(false);
          setIsEditing(false);
        });
    }
  }
  
  useEffect(() => {
    if (!!uri) {
      setIsLoading(true);
      assetProfileApi.get(uri)
        .then((result: AssetProfile) => {
          // set default array
          if(!result.data) {
            result.data = [];
          }
          setAssetProfile(result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);

  const handleChange = (assetProfile: AssetProfile) => {
    setAssetProfile(assetProfile);
  }
  
  return (
    <Grid container spacing={2}>
      {!isLoading && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t(assetProfile?.name!)}
            </Typography>
            {!isEditing ? (
              <Button 
                onClick={() => setIsEditing(!isEditing)}
                color="secondary"
                variant="contained"
                disableElevation
              >
                {t("Edit")}
              </Button>
            ) : (
              <ConfirmButton
                title={t("Save asset profile")}
                variant="contained"
                okText={t("Yes")}
                color="primary"
                cancelText={t("No")}
                buttonText={t("Save")}
                onClick={handleSave}
              />
            )}
          </Grid>
          {assetProfile?.type === "cable_reel" && (
            <CableReelProfile isEditing={isEditing} assetProfile={assetProfile} onChange={handleChange} />
          )}
        </>
      )}
    </Grid>
  );
};
  