import {
  Avatar,
  Box,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import DateFnsDistanceNow from "../../../../common/components/DateFnsDistanceNow/DateFnsDistanceNow";
import { format } from "../../../../utils/dateFnsInternationalization";
import { Event, translateMovementType } from "../../../Events/Event";
import {
  eventsErrorMessageSelector,
  eventsIsLoadingSelector,
  eventsSelector,
  fetchEvents,
  reset,
} from "../../../Events/eventsSlice";
import { setCurrentTab, setSelectedEvent } from "../../openAssetSlice";

export const Events: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const events = useSelector(eventsSelector);
  const errorMessage = useSelector(eventsErrorMessageSelector);
  const isLoading = useSelector(eventsIsLoadingSelector);
  // const type = useSelector(eventsTypeSelector);

  useMount(() => {
    if (!events.length) {
      batch(() => {
        dispatch(reset());
        dispatch(fetchEvents());
      });
    }
  });
  // useUnmount(() => {
  //   dispatch(reset());
  // });

  const handleEventSelect = (event: Event) => {
    batch(() => {
      dispatch(setSelectedEvent(event));
      dispatch(setCurrentTab(2));
    });
  };

  // const tablePagination = (
  //   <TablePagination count={totalItems} page={page} setPage={setPage} />
  // );

  return (
    <>
      <Box px={2} pt={2}>
        <Grid container spacing={2}>
          {/*<Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              label={t("From")}
              variant="inline"
              fullWidth
              disabled={isLoading}
              inputVariant="filled"
              format="dd/MM/yyyy"
              invalidDateMessage={t("Invalid date format")}
              onChange={(date) =>
                dispatch(setFromDate(date ? date.toISOString() : date))
              }
              value={fromDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              label={t("To")}
              variant="inline"
              fullWidth
              disabled={isLoading}
              inputVariant="filled"
              format="dd/MM/yyyy"
              invalidDateMessage={t("Invalid date format")}
              onChange={(date) =>
                dispatch(setToDate(date ? date.toISOString() : date))
              }
              value={toDate}
            />
          </Grid>*/}
          {/* <Grid item xs={12} sm={4}>
            <TextField
              label={t("Type")}
              variant="filled"
              fullWidth
              select
              disabled={isLoading}
              value={type}
              onChange={({ target: { value } }) =>
                dispatch(setType(value as Type))
              }
            >
              {MOVEMENT_TYPES.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label ? t(type.label) : null}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
        </Grid>
      </Box>
      <Box overflow={"auto"} className="no-print">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {events.length > 0 && (
              <List>
                {isLoading && !!events.length && (
                  <LinearProgress style={{ marginBottom: -4 }} />
                )}
                {errorMessage && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ padding: 16 }}
                  >
                    {t(errorMessage)}
                  </Typography>
                )}
                {events.map((event, i) => (
                  <Fragment key={event.id}>
                    <ListItem
                      alignItems="flex-start"
                      button
                      onClick={() => handleEventSelect(event)}
                    >
                      <ListItemAvatar>
                        <Tooltip
                          title={t(translateMovementType(event.type)) as string}
                        >
                          <Avatar>I</Avatar>
                        </Tooltip>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <DateFnsDistanceNow
                              date={event.date}
                              addSuffix
                              includeSeconds
                            />
                            {` (${format(new Date(event.date), "Pp")})`}
                          </>
                        }
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              style={{ display: "inline" }}
                              color="textPrimary"
                            >
                              {`${event.street}, ${event.postal_code || ""}, ${
                                event.city || ""
                              }, ${event.region || ""}, ${event.country || ""}`}
                            </Typography>
                            {` — geozone: ${
                              event.geo_zone?.name || t("Not in a zone")
                            }`}
                          </>
                        }
                      />
                    </ListItem>
                    {i !== events.length - 1 && <Divider component="li" />}
                  </Fragment>
                ))}
                {isLoading &&
                  !events.length &&
                  [...Array(5)].map((_, i) => (
                    <Fragment key={i}>
                      <ListItem alignItems="flex-start" button>
                        <ListItemAvatar>
                          <Skeleton variant={"circle"} height={56} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Skeleton variant={"text"} />}
                          secondary={<Skeleton variant={"text"} />}
                        />
                      </ListItem>
                      {i !== events.length - 1 && <Divider component="li" />}
                    </Fragment>
                  ))}
              </List>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            {tablePagination}
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
