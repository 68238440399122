import {
  CaseReducerActions,
  CombinedState,
  createSlice,
  CreateSliceOptions,
  PayloadAction,
  Reducer,
  Slice,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { injectReducer, RootState } from "./store";

export declare interface State {
  isLoading: boolean;
}

export abstract class ReduxStore<T extends State> {
  public readonly name: string;

  // private uniqueIdentifier: string;
  private initialState: T;
  private initialReducers: SliceCaseReducers<any>;
  private internalSlice: Slice;

  protected constructor(initialState: T) {
    this.name = uuid().toString();
    this.initialState = initialState;

    this.initialReducers = {
      setLoading(state: T, action: PayloadAction<T["isLoading"]>) {
        state.isLoading = action.payload;
      },
    };

    const options: CreateSliceOptions<T, SliceCaseReducers<any>, string> = {
      name: this.name,
      initialState: this.initialState,
      reducers: this.initialReducers,
    };

    this.internalSlice = createSlice(options);
    injectReducer(this.name, this.internalSlice.reducer);
  }

  public readonly isLoadingSelector = (rootState: RootState) =>
    this.selector(rootState, "isLoading");

  protected selector = (rootState: RootState, item: string) => {
    const state: CombinedState<any> = rootState;

    if (Object.keys(state).includes(this.name)) {
      return state[this.name][item];
    }

    return undefined;
  };

  protected addReducers = (reducers: SliceCaseReducers<any>) => {
    const storeReducers = Object.assign(this.initialReducers, reducers);

    const options: CreateSliceOptions<T, SliceCaseReducers<any>, string> = {
      name: this.name,
      initialState: this.initialState,
      reducers: storeReducers,
    };

    this.internalSlice = createSlice(options);
    injectReducer(this.name, this.internalSlice.reducer);
  };

  protected slice(): Slice {
    return this.internalSlice;
  }

  protected actions(): CaseReducerActions<SliceCaseReducers<any>> {
    return this.internalSlice.actions;
  }

  public reducer(): Reducer<State> {
    return this.internalSlice.reducer;
  }
}
