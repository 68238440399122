import { ApiState, ApiStore } from "../../store/ApiStore";

export declare type DeviceProfile = {
  "@id"?: string;
  "@type"?: string;
  id?: number;
  name?: string;
  properties?: DeviceProfileProperty[];

}
export declare interface DeviceProfileProperty {
  name: string;
  type?: string;
  tag?: string;
}

declare interface State extends ApiState<DeviceProfile, DeviceProfile> {}
class DeviceProfileStore extends ApiStore<DeviceProfile, DeviceProfile, State> {
  public constructor() {
    super("/api/device-profiles", ({} as unknown) as State, {});
  }
}
  
const deviceProfileStore = new DeviceProfileStore();
export { deviceProfileStore };
