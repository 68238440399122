import React, {
  cloneElement,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import {
  LinearProgress,
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
} from "@material-ui/core";

interface MenuProps extends Omit<MuiMenuProps, "open"> {
  items: (closeMenu: () => void) => ReactNode[];
  loading?: boolean;
  open?: boolean;
}

const Menu: FunctionComponent<MenuProps> = ({
  children,
  items,
  loading,
  open,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {cloneElement(children as ReactElement, {
        onClick: handleClick,
      })}
      <MuiMenu
        anchorEl={anchorEl}
        open={open ?? Boolean(anchorEl)}
        onClose={handleClose}
        {...props}
      >
        {loading && (
          <LinearProgress
            style={{ position: "absolute", left: 0, top: 0, right: 0 }}
          />
        )}
        {items(handleClose).map((item, key) =>
          cloneElement(item as ReactElement, { key })
        )}
      </MuiMenu>
    </>
  );
};

// noinspection JSUnusedGlobalSymbols
export default Menu;
