import { Tooltip } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import ls from "../../utils/localStorageKey";
import french from "./assets/france.svg";
import germany from "./assets/germany.svg";
import netherlands from "./assets/netherlands.svg";
import unitedStatesOfAmerica from "./assets/united-states-of-america.svg";

type LanguageName = "en" | "nl" | "de" | "fr";

interface Language {
  title: string;
  name: LanguageName;
  flag: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  flag: {
    width: theme.spacing(3),
    opacity: 0.8,
    filter: "grayscale(100%)",
    transition: theme.transitions.create(["filter", "opacity"]),
    cursor: "pointer",
    "&:hover": {
      filter: "none",
      opacity: 1,
    },
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  flagActive: {
    filter: "none",
    opacity: 1,
  },
}));

const LanguageSelect = () => {
  const classes = useStyles();
  const navigatorLanguage: any = navigator.language.split("-")[0];
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage<LanguageName>(
    ls("selectedLanguage"),
    navigatorLanguage
  );
  const { t } = useTranslation();

  const languages: Language[] = [
    { title: t("Dutch"), name: "nl", flag: netherlands },
    { title: t("English"), name: "en", flag: unitedStatesOfAmerica },
    { title: t("German"), name: "de", flag: germany },
    { title: t("French"), name: "fr", flag: french },
  ];

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage).catch(console.error);
    }
  }, [selectedLanguage]);

  return (
    <div>
      {languages.map((language) => (
        <Tooltip key={language.name} title={language.title}>
          <img
            src={language.flag}
            alt={language.title}
            className={clsx(classes.flag, {
              [classes.flagActive]: selectedLanguage === language.name,
            })}
            onClick={() => setSelectedLanguage(language.name)}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export const getSelectedLanguage = () => {
  let lang = "en";
  switch (navigator.language.split("-")[0]) {
    case "nl":
      lang = "nl";
      break;
    case "de":
      lang = "de";
      break;
    case "fr":
      lang = "fr";
      break;
    default:
      lang = "en";
  }

  const navigatorLanguage = lang;
  try {
    return (
      JSON.parse(localStorage.getItem(ls("selectedLanguage"))!) ||
      navigatorLanguage
    );
  } catch (e) {
    return navigatorLanguage;
  }
};

export default LanguageSelect;
