import {
  Button,
  CircularProgress,
  DialogActions,
  Fade,
  ListSubheader,
  MenuItem
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { DeleteRounded, EditRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useToggle, useUnmount } from "react-use";
import Menu from "../../../common/components/Menu/Menu";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { Customer } from "../Customer";
import {
  addCustomer,
  customersIsLoadingSelector,
  openCustomerSelector,
  removeCustomer,
  setOpenCustomer,
  updateCustomer
} from "../customersSlice";
import { Details } from "./Details/Details";

const OpenUser = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customer = useSelector(openCustomerSelector);
  const customerId = customer?.id;
  const [hasId, setHasId] = useState<boolean>(!!customer?.id);
  const isLoading = useSelector(customersIsLoadingSelector);
  const [isEditing, toggleEditing] = useToggle(false);

  useEffect(() => {
    if (customer) {
      const hasId = !!customerId;
      if (hasId) {
        setHasId(hasId);
      } else {
        setHasId(hasId);
      }
    } else {
      setHasId(!!customerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);
  useUnmount(() => {
    handleClose();
  });

  const handleClose = () => {
    dispatch(setOpenCustomer(undefined));
  };
  const handleSave = () => {
    if (hasId) {
      dispatch(updateCustomer(customer as Customer));
    } else {
      dispatch(addCustomer(customer as Customer));
    }
  };

  return (
    <SidePanelModal
      title={`${!hasId ? t("New Customer") : t("Customer")}`}
      open={!!customer}
      onClose={handleClose}
      width={theme.spacing(50)}
    >
      <Details isEditing={isEditing} />
      <DialogActions>
        {isLoading && (
          <Fade in timeout={{ enter: 1000 }}>
            <CircularProgress
              size={theme.spacing(3)}
              style={{ marginRight: theme.spacing(1) }}
              color="inherit"
            />
          </Fade>
        )}
        {hasId && customer && (
          <Menu
            loading={isLoading}
            items={(closeMenu) => [
              <ListSubheader key={0} component="div">
                {t("Are you sure you want to delete")} {'"'}
                {customer.name || `#${customer.id}`}
                {'"'}?
              </ListSubheader>,
              <MenuItem
                key={0}
                onClick={() => dispatch(removeCustomer(customer as Customer))}
              >
                {t("Delete")}
              </MenuItem>,
              <MenuItem key={0} onClick={closeMenu}>
                {t("Cancel")}
              </MenuItem>,
            ]}
          >
            <Button
              variant="text"
              disableElevation
              startIcon={<DeleteRounded />}
            >
              {t("Delete")}
            </Button>
          </Menu>
        )}
        {!isEditing && (
          <Button
            variant="contained"
            disableElevation
            onClick={toggleEditing}
            startIcon={<EditRounded />}
          >
            {t("Edit")}
          </Button>
        )}
        <Button
          onClick={handleSave}
          color="primary"
          variant={"contained"}
          disableElevation
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};

export default OpenUser;
