import {
  Box,
  CircularProgress,
  Link as MuiLink,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { format, parseISO } from "date-fns";
import {
  Circle as LeafletCircle,
  LatLngTuple,
  Layer as LeafletLayer,
  Polygon as LeafletPolygon,
} from "leaflet";
import React, { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureGroup,
  LayersControl,
  Map,
  Marker,
  Popup,
  TileLayer,
  WMSTileLayer,
} from "react-leaflet";
import { BingLayer } from "react-leaflet-bing";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMount } from "react-use";
import AutoHeight from "../../common/components/AutoHeight/AutoHeight";
import DateFnsDistanceNow from "../../common/components/DateFnsDistanceNow/DateFnsDistanceNow";
import useTitle from "../../common/hooks/useTitle";
import config from "../../config";
import { GeoZone } from "../GeoZone/GeoZone";
import {
  addGeoZone,
  fetchGeoZones,
  geoZonesSelector,
  setOpenGeoZone,
} from "../GeoZone/geoZoneSlice";
import {
  assetMapAssetsSelector,
  assetMapIsLoadingSelector,
  fetchAssetMap,
} from "./assetMapSlice";
import { EditControl } from "./Draw";
import { MAP_LAYERS } from "./mapLayers";
import OpenGeoZone from "./OpenGeoZone/OpenGeoZone";

const { BaseLayer, Overlay } = LayersControl;

const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    "& .leaflet-popup-content-wrapper, & .leaflet-popup-tip": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  },
  map: {
    "& .leaflet-marker-icon:not(.marker-cluster)": {
      filter: "hue-rotate(260deg)",
    },
  },
}));

export const AssetMap = () => {
  const { t, i18n } = useTranslation();
  useTitle(t("Asset map"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const defaultGeoZoneColor: string = "#3388ff";

  // assets
  const assets = useSelector(assetMapAssetsSelector);
  const isLoading = useSelector(assetMapIsLoadingSelector);
  const selectPosition: LatLngTuple | undefined = assets.length
    ? [assets[0].latitude, assets[0].longitude]
    : undefined;

  // geozone
  const geoZones = useSelector(geoZonesSelector);

  useMount(() => {
    dispatch(fetchAssetMap());
    dispatch(fetchGeoZones());
  });

  const drawControlCallback = useCallback(
    (context: FeatureGroup) => {
      const layerContainer = context?.contextValue?.layerContainer;

      if (layerContainer !== null && layerContainer !== undefined) {
        // remove all layers
        context?.leafletElement?.eachLayer((layer) => {
          layerContainer.removeLayer(layer);
        });

        geoZones?.map((geoZone: GeoZone) => {
          let layer: LeafletLayer;
          switch (geoZone.geometry?.type) {
            case "Polygon":
              layer = createPolygon(geoZone);
              break;
            case "Point":
              layer = createCircle(geoZone);
              break;
            default:
              return;
          }

          if (geoZone.name !== undefined && geoZone.name !== "") {
            layer.bindTooltip(geoZone.name, {
              permanent: true,
            });
          }

          layer.on("click", () => dispatch(setOpenGeoZone(geoZone)));
          layerContainer.addLayer(layer, geoZone.name);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [geoZones]
  );

  function createPolygon(geoZone: GeoZone): LeafletLayer {
    const rawCoordinates = geoZone.geometry.coordinates[0] as Array<
      Array<number>
    >;
    let coordinates: Array<any> = [];
    for (let i = 0; i < rawCoordinates.length; i++) {
      const coordinate = coordinateToLatLng(rawCoordinates[i]);
      coordinates.push(coordinate);
    }

    let layer = new LeafletPolygon(coordinates, {
      interactive: true,
      bubblingMouseEvents: true,
      color: geoZone.geometry.color ?? defaultGeoZoneColor,
    });

    return layer;
  }

  function createCircle(geoZone: GeoZone): LeafletLayer {
    const rawCoordinates = geoZone.geometry.coordinates as Array<number>;
    const coordinates = coordinateToLatLng(rawCoordinates);
    const defaultRadius: number = 30;

    let layer = new LeafletCircle(coordinates, {
      interactive: true,
      bubblingMouseEvents: true,
      color: geoZone.geometry.color ?? defaultGeoZoneColor,
    });
    layer.setRadius(geoZone.geometry.radius ?? defaultRadius);

    return layer;
  }

  function coordinateToLatLng(rawCoordinate: Array<number>) {
    const coordinates = { lat: rawCoordinate[1], lng: rawCoordinate[0] };
    return coordinates;
  }

  function onDrawComponentCreated(evt: {
    layer: any;
    layerType: string;
    type: string;
    target: any;
    sourceTarget: any;
  }) {
    // save geofences in database
    const shape: any = evt.layer.toGeoJSON();
    shape.geometry.radius = evt.layer.options.radius;
    if (shape.geometry?.radius < 15) {
      shape.geometry.radius = 15;
    }

    const geometry = shape.geometry;

    let geoZone: GeoZone = {
      id: null,
      name: `geo zone`,
      geometry: geometry,
    };

    dispatch(addGeoZone(geoZone!));
  }

  return (
    <>
      {!isLoading ? (
        selectPosition ? (
          <AutoHeight>
            <Map
              center={selectPosition}
              zoom={6}
              style={{ height: "100vh" }}
              className={classes.map}
            >
              <LayersControl position="topright">
                <BaseLayer checked name={t("Map")} key={"Map" + i18n.language}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                </BaseLayer>
                <BaseLayer
                  name={t("Satelite")}
                  key={"Satelite" + i18n.language}
                >
                  <BingLayer bingkey={config.bingKey} />
                </BaseLayer>
                <BaseLayer
                  name={t("Satelite with Labels")}
                  key={"Satelite with Labels" + i18n.language}
                >
                  <BingLayer bingkey={config.bingKey} type="AerialWithLabels" />
                </BaseLayer>
                {MAP_LAYERS.map((mapLayer) => (
                  <Overlay
                    key={mapLayer.label + i18n.language}
                    name={t(mapLayer.label)}
                  >
                    <WMSTileLayer
                      layers={mapLayer.layers}
                      url={mapLayer.url}
                      opacity={0.6}
                      format="image/png"
                      transparent
                    />
                  </Overlay>
                ))}
                <MarkerClusterGroup>
                  {assets.map((asset) => (
                    <Fragment key={asset.id!}>
                      {/*<AssetMapSubscription assetMap={asset} />*/}
                      {/* <AssetMapSubscription assetMap={asset} /> */}
                      <Marker
                        key={asset.id!}
                        position={[asset.latitude, asset.longitude]}
                      >
                        <Popup className={classes.popup}>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={
                                <>
                                  <MuiLink
                                    component={Link}
                                    to={`/assets/${asset.id}`}
                                  >
                                    {asset.name}
                                  </MuiLink>
                                </>
                              }
                              secondary={
                                <>
                                  <span style={{ display: "block" }}>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      style={{ display: "inline" }}
                                      color="textPrimary"
                                    >
                                      {asset.latitude}, {asset.longitude}
                                    </Typography>
                                  </span>
                                  <span style={{ display: "block" }}>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      style={{ display: "inline" }}
                                      color="textPrimary"
                                    >
                                      {t("Last location update")}
                                    </Typography>
                                    {asset.date && (
                                      <>
                                        {" — "}
                                        <DateFnsDistanceNow
                                          date={parseISO(asset.date.toString())}
                                          addSuffix
                                          includeSeconds
                                        />
                                        {` (${format(
                                          parseISO(asset.date.toString()),
                                          "Pp"
                                        )})`}
                                      </>
                                    )}
                                  </span>
                                </>
                              }
                            />
                          </ListItem>
                        </Popup>
                      </Marker>
                    </Fragment>
                  ))}
                </MarkerClusterGroup>
              </LayersControl>
              <FeatureGroup ref={drawControlCallback}>
                <EditControl
                  position="topright"
                  onCreated={onDrawComponentCreated}
                  draw={{
                    polyline: false,
                    rectangle: false,
                    circlemarker: false,
                    marker: false,
                  }}
                  edit={{
                    edit: false,
                    remove: false,
                  }}
                />
              </FeatureGroup>
            </Map>
          </AutoHeight>
        ) : (
          <Box p={2}>
            <Alert severity="warning">
              {t("You don't have any assets to show on the map")}.
            </Alert>
          </Box>
        )
      ) : (
        <AutoHeight>
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        </AutoHeight>
      )}
      <OpenGeoZone />
    </>
  );
};
