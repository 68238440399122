import { Tenant } from "../Tenant/Tenant";

export const ROLES = ["ROLE_USER", "ROLE_ADMIN"] as const;

export interface User {
  id?: number;
  "@id"?: string;
  email?: string;
  active?: boolean;
  name?: string;
  company?: string;
  roles: string[];
  permissions?: string[];
  username?: string;
  user_tenant_roles: UserTenantRole[];
  stayLoggedIn: boolean;
  token?: string;
  refresh_token?: string;
}

export enum UserRole {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_USER = "ROLE_USER",
  // ROLE_EMPLOYEE = "ROLE_EMPLOYEE",
  // ROLE_FINANCE = "ROLE_FINANCE",
  // ROLE_PROJECT_MANAGER = "ROLE_PROJECT_MANAGER",
  // ROLE_CONTROLLER = "ROLE_CONTROLLER",
  // ROLE_CREATE_INVOICES = "ROLE_CREATE_INVOICES",
  ROLE_TRANSPORTER = "ROLE_TRANSPORTER",
  ROLE_CLIENT = "ROLE_CLIENT",
}

export interface UserTenantRole {
  id?: number;
  "@id"?: string;
  user?: User | User["@id"];
  tenant?: Tenant | Tenant["@id"];
  roles?: string[];
}

export interface UserCredentials {
  email: User["email"];
  password: string;
  confirmPassword?: string;
}

export interface UserFilter {
  id?: number | null;
  email?: string;
  name?: string;
}
