import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const StyledTableAutoHeightContainerGridItem = styled(Grid).attrs({
  item: true,
  xs: 12,
})`
  height: calc(100vh - 48px);
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;
