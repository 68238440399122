import React, { FunctionComponent, ReactNode } from "react";
import { default as CAppBar } from "../../../common/components/AppHeader/AppBar/AppBar";
import { Box, Button, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu/UserMenu";
import config from "../../../config";
import { useSelector } from "react-redux";
import { authIsAuthenticatedSelector } from "../../../domains/Auth/authSlice";

const AppBar: FunctionComponent<{
  open: boolean;
  logo: ReactNode;
  handleDrawerOpen: () => void;
  className?: string;
}> = ({ open, logo, handleDrawerOpen, className }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });

  const isAuthenticated = useSelector(authIsAuthenticatedSelector);

  return (
    <CAppBar
      open={open}
      logo={logo}
      onOpenDrawer={handleDrawerOpen}
      appTitle={config.appTitle}
      showDrawer={isAuthenticated}
      className={className}
    >
      <div style={{ flexGrow: 1 }} />
      <div id="appBarPortal" />
      <Box display="flex" alignItems="center">
        {isAuthenticated ? (
          <UserMenu />
        ) : (
          <>
            <Button
              color="inherit"
              variant="outlined"
              disableElevation
              component={Link}
              to="/sign-in"
            >
              Sign in
            </Button>
            {!isMobile && (
              <Box ml={1}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  component={Link}
                  to="/sign-up"
                >
                  Sign up
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </CAppBar>
  );
};

export default AppBar;
