import React from "react";
import { useTranslation } from "react-i18next";
import useTitle from "../../common/hooks/useTitle";
import OpenAssetProfile from "./OpenAssetProfile/OpenAssetProfile";
import Table from "./Table/Table";

export const AssetProfiles = () => {
  const { t } = useTranslation();
  useTitle(t("Asset profiles"));

  return (
    <>
      <Table />
      <OpenAssetProfile />
    </>
  );
};
