import { Api } from "../../store/Api";
import { AssetProfile } from "./assetProfile";


class AssetProfileApi extends Api<AssetProfile, AssetProfile> {
  public constructor() {
    super("/api/asset-profiles");
  }
}

export const assetProfileApi = new AssetProfileApi();