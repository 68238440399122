import React, { FunctionComponent, useMemo } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AttachmentRounded, CloudUploadOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import clsx from "clsx";
import { S3 } from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";
import { Attachment } from "../../Asset";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAttachment,
  openAssetAcceptedFilesSelector,
  openAssetSelector,
  setAcceptedFiles,
} from "../../openAssetSlice";
import { useMount } from "react-use";
import {
  authIsLoadingS3Selector,
  authS3CredentialsSelector,
  authS3ErrorMessageSelector,
  fetchS3Credentials,
} from "../../../Auth/authSlice";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme: Theme) => ({
  fileUpload: {
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    transition: theme.transitions.create(["background-color", "border-color"]),
    height: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  fileUploadDragOver: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderStyle: "dotted",
  },
}));

export const Attachments: FunctionComponent<{
  uploadFiles: (acceptedFiles: File[]) => void;
}> = ({ uploadFiles }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const openAsset = useSelector(openAssetSelector);
  const attachments: Attachment[] = openAsset?.attachments || [];
  const acceptedFiles = useSelector(openAssetAcceptedFilesSelector);
  const s3Credentials = useSelector(authS3CredentialsSelector);
  const isLoadingS3 = useSelector(authIsLoadingS3Selector);
  const s3ErrorMessage = useSelector(authS3ErrorMessageSelector);

  const s3 = useMemo(
    () =>
      new S3({
        region: s3Credentials?.aws_s3_client_region,
        accessKeyId: s3Credentials?.aws_s3_client_id,
        secretAccessKey: s3Credentials?.aws_s3_client_secret,
      }),
    [s3Credentials]
  );

  useMount(() => {
    if (!s3Credentials) {
      dispatch(fetchS3Credentials());
    }
  });

  const handleAttachmentClick = (attachment: Attachment) => {
    s3.getObject(
      {
        Bucket: attachment.bucket,
        Key: attachment.object_key,
      },
      (error, response) => {
        if (error != null) {
          enqueueSnackbar(
            t("There was an error while trying to retrieve the attachment"),
            {
              variant: "error",
            }
          );
          console.error(error);
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.Body as any])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            attachment.object_key.replace(/^.*[\\/]/, "")
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    );
  };

  const handleAttachmentDelete = (attachment: Attachment) => {
    s3.deleteObject(
      {
        Bucket: attachment.bucket,
        Key: attachment.object_key,
      },
      (error, response) => {
        if (error != null) {
          enqueueSnackbar(
            t("There was an error while trying to delete the attachment"),
            {
              variant: "error",
            }
          );
        }

        dispatch(deleteAttachment(openAsset?.id!, attachment));
      }
    );
  };

  return (
    <DialogContent>
      {isLoadingS3 && (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      )}
      {!isLoadingS3 && !s3ErrorMessage && (
        <>
          {(openAsset?.id || acceptedFiles.length > 0) && (
            <Box mx={-3}>
              {!!attachments.length && (
                <List>
                  {openAsset?.id
                    ? attachments.map((attachment) => (
                        <ListItem
                          key={attachment.id}
                          button
                          onClick={handleAttachmentClick.bind(null, attachment)}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <AttachmentRounded />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography noWrap>
                                {attachment.url.split("/").pop()}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={handleAttachmentDelete.bind(
                                null,
                                attachment
                              )}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    : acceptedFiles.map((a) => (
                        <Tooltip
                          key={a.name}
                          title={
                            t(
                              "The file will be uploaded when you save the new issue"
                            ) as any
                          }
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <CloudUploadOutlined />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Typography noWrap>{a.name}</Typography>}
                            />
                          </ListItem>
                        </Tooltip>
                      ))}
                </List>
              )}
            </Box>
          )}
          <Box py={2}>
            <Dropzone
              onDrop={(files) =>
                openAsset?.id
                  ? uploadFiles(files)
                  : dispatch(setAcceptedFiles(files))
              }
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  className={clsx(classes.fileUpload, {
                    [classes.fileUploadDragOver]: isDragActive,
                  })}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <Typography align="center" gutterBottom>
                    <CloudUploadOutlined fontSize="large" />
                  </Typography>
                  {!isDragActive ? (
                    <>
                      <Typography align="center" gutterBottom>
                        {t("Drag and drop files here")}
                      </Typography>
                      <Typography align="center" gutterBottom>
                        {t("or")}
                      </Typography>
                      <Typography align="center" gutterBottom>
                        <Button
                          variant="contained"
                          disableElevation
                          color="primary"
                        >
                          {t("Browse files")}
                        </Button>
                      </Typography>
                    </>
                  ) : (
                    <Typography align="center" gutterBottom>
                      {t("Release the file to attach it")}
                    </Typography>
                  )}
                </div>
              )}
            </Dropzone>
          </Box>
        </>
      )}
      {s3ErrorMessage && (
        <Typography
          variant="overline"
          color="textSecondary"
          align="center"
          component="p"
        >
          {t(s3ErrorMessage)}
        </Typography>
      )}
    </DialogContent>
  );
};
