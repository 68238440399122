import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React, { FunctionComponent } from "react";

const ExportMenu: FunctionComponent<{
  onExportPdf?: () => void;
  onExportExcel?: () => void;
  onExportCsv?: () => void;
}> = ({ onExportPdf, onExportExcel, onExportCsv }) => {
  const popupState: any = usePopupState({
    variant: "popover",
    popupId: "menu-assets",
  });

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        {...bindTrigger(popupState)}
      >
        <MoreVertIcon />
      </Button>
      <Menu {...bindMenu(popupState)}>
        {!!onExportPdf && (
          <MenuItem
            onClick={() => {
              onExportPdf();
              popupState.close();
            }}
          >
            Export PDF
          </MenuItem>
        )}
        {!!onExportExcel && (
          <MenuItem
            onClick={() => {
              onExportExcel();
              popupState.close();
            }}
          >
            Export Excel
          </MenuItem>
        )}
        {!!onExportCsv && (
          <MenuItem
            onClick={() => {
              onExportCsv();
              popupState.close();
            }}
          >
            Export CSV
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ExportMenu;
