import { createGlobalState } from "react-use";
import { useEffect } from "react";

const useGlobalState = createGlobalState<string>("");

export default function useTitle(newTitle?: string): string {
  const [title, setTitle] = useGlobalState();

  useEffect(() => {
    if (newTitle !== undefined && title !== newTitle) {
      setTitle(newTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTitle]);

  useEffect(() => {
    if (title) {
      window.document.title = title;
    }
  }, [title]);

  return title!;
}
