import { Button, DialogActions, DialogContent, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import { CustomerFilter } from "../Customer";
import { customersFilterSelector, customersIsLoadingSelector, setFilter } from "../customersSlice";

export const Filter: FunctionComponent<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });
  const dispatch = useDispatch();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const isLoading = useSelector(customersIsLoadingSelector);
  const filter = useSelector(customersFilterSelector);

  return (
    <SidePanelModal title={t("Filter")} open={open} onClose={onClose} width={theme.spacing(50)}>
      <DialogContent>
        <Formik
          initialValues={
            {
              id: "" as any,
              logical_id: "" as any,
              name: "",
              street: "",
              postal_code: "",
              city: "",
              ...filter,
            } as CustomerFilter
          }
          validationSchema={Yup.object().shape<CustomerFilter>({
            id: Yup.number().nullable(true).integer(t("Must be a number")),
            logical_id: Yup.number().nullable(true).integer(t("Must be a number")),
            name: Yup.string(),
            street: Yup.string(),
            postal_code: Yup.string(),
            city: Yup.string(),
          })}
          onSubmit={(values) => {
            dispatch(setFilter(values));
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field component={TextField} variant="filled" fullWidth name="logicalId" label={t("ID")} type={isMobile ? "tel" : "number"} disabled={isLoading} />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="filled" fullWidth name="name" label={t("Customer")} disabled={isLoading} />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="filled" fullWidth name="street" label={t("Street")} disabled={isLoading} />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="filled" fullWidth name="postalCode" label={t("Postal code")} disabled={isLoading} />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="filled" fullWidth name="city" label={t("City")} disabled={isLoading} />
                </Grid>
              </Grid>
              <button ref={submitButtonRef} type="submit" style={{ border: 0, padding: 0 }} />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(setFilter({}));
            onClose();
          }}
          disabled={isLoading}
        >
          {t("Clear")}
        </Button>
        <Button
          onClick={() => {
            submitButtonRef.current?.click();
            onClose();
          }}
          type="submit"
          color="primary"
          variant="contained"
          disableElevation
          disabled={isLoading}
        >
          {t("Filter")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};
