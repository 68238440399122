import TablePaginationActions from "../../common/components/TablePaginationActions/TablePaginationActions";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { TablePagination as MuiTablePagination } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const TablePagination: FunctionComponent<{
  count: number;
  page: number;
  setPage: (action: any) => any;
}> = ({ count, page, setPage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <MuiTablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={count}
      rowsPerPage={20}
      page={page}
      onPageChange={(_, page) => dispatch(setPage(page))}
      labelRowsPerPage={t("Rows per page")}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to === -1 ? count : to} ${t("of")} ${
          count !== -1 ? count : `${t("more than")} ${to}`
        }`
      }
      ActionsComponent={(props) => (
        <TablePaginationActions
          {...props}
          lastIconButtonText={t("Last page")}
          nextIconButtonText={t("Next page")}
          backIconButtonText={t("Previous page")}
          firstIconButtonText={t("First page")}
        />
      )}
    />
  );
};
