import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import useTitle from "../../common/hooks/useTitle";
import { setFilter } from "./assetsSlice";
import OpenAsset from "./OpenAsset/OpenAsset";
import Table from "./Table/Table";

export const Assets = () => {
  const { t } = useTranslation();
  useTitle(t("Assets"));
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(setFilter({}));
  });

  return (
    <>
      <Table />

      <OpenAsset />
    </>
  );
};
