import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";
import { Details } from "./Details/Details";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  DialogActions,
  Fade,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import {
  geoZonesIsLoadingSelector,
  geoZonesOpenGeoZoneSelector,
  removeGeoZone,
  setOpenGeoZone,
  updateGeoZone,
} from "../../GeoZone/geoZoneSlice";
import { GeoZone } from "../../GeoZone/GeoZone";

const OpenGeoZone = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const geoFence = useSelector(geoFencesOpenGeoFenceSelector);
  const geoZone = useSelector(geoZonesOpenGeoZoneSelector);
  const [hasId, setHasId] = useState<boolean>(!!geoZone?.id);
  const isLoading = useSelector(geoZonesIsLoadingSelector);

  useEffect(() => {
    if (geoZone) {
      const hasId = !!geoZone?.id;
      if (hasId) {
        setHasId(hasId);
      } else if (geoZone) {
        setHasId(hasId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoZone]);

  useUnmount(() => {
    handleClose();
  });

  const handleClose = () => {
    dispatch(setOpenGeoZone(undefined));
  };
  const handleSave = () => {
    dispatch(updateGeoZone(geoZone as GeoZone));
    dispatch(setOpenGeoZone(undefined));
  };

  return (
    <SidePanelModal
      title={`Geo Fencing`}
      open={!!geoZone}
      onClose={handleClose}
      width={theme.spacing(50)}
    >
      <Details />
      <DialogActions>
        {isLoading && (
          <Fade in timeout={{ enter: 1000 }}>
            <CircularProgress
              size={theme.spacing(3)}
              style={{ marginRight: theme.spacing(1) }}
              color="inherit"
            />
          </Fade>
        )}

        {hasId && (
          <Button
            variant="text"
            disableElevation
            onClick={() => dispatch(removeGeoZone(geoZone as GeoZone))}
            startIcon={<DeleteRounded />}
          >
            {t("Delete")}
          </Button>
        )}
        <Button
          onClick={handleSave}
          color="primary"
          variant={"contained"}
          disableElevation
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};

export default OpenGeoZone;
