import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosError } from "axios";
import { batch } from "react-redux";
import { AppThunk, RootState } from "../../store/store";
import { enqueueSnackbar } from "../Snackbar/snackbarSlice";
import { Asset, AssetFilter, OrderFilter } from "./Asset";
import { setOpenAsset } from "./openAssetSlice";

interface Order {
  key: string;
  order: string;
}

interface AssetsState {
  isLoading: boolean;
  assets: Asset[];
  search: string;
  selectedAssets: Asset["id"][];
  filter: AssetFilter;
  order: OrderFilter;
  totalItems: number;
  page: number;
}

const initialState: AssetsState = {
  isLoading: false,
  assets: [],
  search: "",
  selectedAssets: [],
  filter: {},
  order: {},
  totalItems: 0,
  page: 0,
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<AssetsState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setAssets(state, action: PayloadAction<AssetsState["assets"]>) {
      state.assets = action.payload;
    },
    setSearch(state, action: PayloadAction<AssetsState["search"]>) {
      state.search = action.payload;
    },
    toggleSelectedAsset(state, action: PayloadAction<Asset>) {
      if (state.selectedAssets.includes(action.payload.id)) {
        state.selectedAssets.splice(
          state.selectedAssets.findIndex((a) => a === action.payload.id),
          1
        );
      } else {
        state.selectedAssets.push(action.payload.id);
      }
    },
    setFilter(state, action: PayloadAction<AssetsState["filter"]>) {
      state.filter = action.payload;
    },
    setOrder(state, action: PayloadAction<AssetsState["order"]>) {
      state.order = action.payload;
    },
    setTotalItems(state, action: PayloadAction<AssetsState["totalItems"]>) {
      state.totalItems = action.payload;
    },
    setPage(state, action: PayloadAction<AssetsState["page"]>) {
      state.page = action.payload;
    },
  },
});

export const assetsReducer = assetsSlice.reducer;

const {
  setAssets,
  setLoading,
  setSearch: setSearchAction,
  toggleSelectedAsset,
  setFilter: setFilterAction,
  setOrder: setOrderAction,
  setTotalItems,
  setPage: setPageAction,
} = assetsSlice.actions;
export { toggleSelectedAsset, setLoading };

export const setPage = (page: AssetsState["page"]): AppThunk => (dispatch) => {
  batch(() => {
    dispatch(setPageAction(page));
    dispatch(fetchAssets());
  });
};

export const setSearch = (search: AssetsState["search"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(0));
    dispatch(setSearchAction(search));
    dispatch(fetchAssets());
  });
};

export const setFilter = (filter: AssetsState["filter"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setPageAction(0));
    dispatch(setFilterAction(filter));
    dispatch(fetchAssets());
  });
};

export const setOrder = (order: AssetsState["order"]): AppThunk => (
  dispatch
) => {
  batch(() => {
    dispatch(setOrderAction(order));
    dispatch(fetchAssets());
  });
};

export const fetchAssets = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    let order = "";
    let scheduledFilter = "";
    let filter = { ...getState().assets.filter };

    const orderState = getState().assets.order;
    if(!!orderState && Object.keys(orderState).length > 0) {
      const orderStateKeys = Object.keys(orderState);
      const orderKey = orderStateKeys[0];

      order += `order[${orderKey}]=${
        orderState[orderKey]
      }`;
    }
    if (
      getState().assets.filter.scheduled !== undefined &&
      getState().assets.filter.scheduled !== null
    ) {
      let scheduledValue = getState().assets.filter.scheduled;
      scheduledFilter = `&scheduled[after]=${scheduledValue}&scheduled[before]=${scheduledValue}&`;
      filter.scheduled = undefined;
    }

    const response = await Axios.get<{
      "hydra:totalItems": number;
      "hydra:member": Asset[];
    }>(`/api/assets?${order}${scheduledFilter}`, {
      params: {
        search: getState().assets.search,
        page: getState().assets.page + 1,
        ...filter,
      },
    });
    const totalItems = response.data["hydra:totalItems"];
    const assets = response.data["hydra:member"];
    batch(() => {
      dispatch(setAssets(assets));
      dispatch(setTotalItems(totalItems));
      dispatch(setLoading(false));
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const addAsset = (asset: Partial<Asset>): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.post<Asset>(`/api/assets`, {
      ...asset,
      devices: asset.devices?.map((d) => d),
    });
    batch(() => {
      dispatch(
        setOpenAsset({
          ...getState().openAsset.openAsset,
          id: response.data.id,
        })
      );
      dispatch(fetchAssets());
      dispatch(
        enqueueSnackbar({
          message: "Asset added",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const updateAsset = (asset: Asset): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await Axios.put(`/api/assets/${asset.id}`, {
      ...asset,
      devices: asset.devices.map((d) => d),
    });
    batch(() => {
      dispatch(fetchAssets());
      dispatch(
        enqueueSnackbar({
          message: "Asset updated",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const removeAsset = (asset: Asset): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await Axios.delete(`/api/assets/${asset.id}`);
    batch(() => {
      dispatch(fetchAssets());
      dispatch(setOpenAsset(undefined));
      dispatch(
        enqueueSnackbar({
          message: "Asset deleted",
        })
      );
    });
  } catch (err) {
    dispatch(setLoading(false));
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const resetAssetDaysOutsideGeozone = (asset: Asset): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading(true));
  try {
    const response = await Axios.put(
      `/api/assets/${asset.id}/days-outside-geo-zone/reset`,
      asset
    );

    batch(() => {
      dispatch(fetchAssets());
      dispatch(setOpenAsset(response.data));
    });
  } catch (err) {
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const deregisterAsset = (asset: Asset): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await Axios.put(`/api/assets/${asset.id}/deregister`, asset);
    batch(() => {
      dispatch(fetchAssets());
      dispatch(
        enqueueSnackbar({
          message: "Notification is send",
        })
      );
    });
  } catch (err) {
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const deliveredAsset = (asset: Asset): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await Axios.put(`/api/assets/${asset.id}/delivered`, asset);
    batch(() => {
      dispatch(fetchAssets());
    });
  } catch (err) {
    const error: AxiosError = err;
    console.error(error);
    dispatch(
      enqueueSnackbar({
        message: error.response?.data?.message || error.message,
        options: {
          variant: "error",
        },
      })
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const assetsSelector = (state: RootState) => state.assets.assets;
export const assetsIsLoadingSelector = (state: RootState) =>
  state.assets.isLoading;
export const assetsSearchSelector = (state: RootState) => state.assets.search;
export const assetsSelectedAssetsSelector = (state: RootState) =>
  state.assets.selectedAssets;
export const assetsFilterSelector = (state: RootState) => state.assets.filter;
export const assetsTotalItemsSelector = (state: RootState) =>
  state.assets.totalItems;
export const assetsPageSelector = (state: RootState) => state.assets.page;
