import { Button, IconButton, ListSubheader, MenuItem } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import Menu from "../Menu/Menu"


export const ConfirmButton: FunctionComponent<{
  loading?: boolean;
  title: string;
  variant?: "text" | "outlined" | "contained" | undefined;
  color?: "primary" | "secondary" | undefined;
  iconOnly?: boolean;
  icon?: any;
  okText: string;
  cancelText: string;
  buttonText: string;
  onClick: () => void;
}> = ({loading, icon, iconOnly, color, variant, title, okText, cancelText, buttonText, onClick}) => {

  return (
    <Menu
      loading={!!loading}
      items={(closeMenu) => [
        <ListSubheader key={0} component="div">
          {title}
        </ListSubheader>,
        <MenuItem
          key={0}
          onClick={() => {
            onClick();
            closeMenu();
          }}
        >
          {okText}
        </MenuItem>,
        <MenuItem key={0} onClick={closeMenu}>
          {cancelText}
        </MenuItem>
      ]}
    >
      {!!iconOnly ? (
        <IconButton>
          {icon}
        </IconButton>
      ) : (
        <Button
          color={color}
          variant={variant || "text"}
          disableElevation
          startIcon={icon}
        >
          {buttonText}
        </Button>
      )}
    </Menu>
  )
}