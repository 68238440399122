import useTitle from "../../common/hooks/useTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "./Table/Table";

export const Roles = () => {
  const { t } = useTranslation();
  useTitle(t("Roles"));

  return (
    <>
      <Table />
    </>
  );
};
