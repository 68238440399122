import { Grid, GridSize, TextField, Typography } from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const PropertyField: FunctionComponent<{
  label: string;
  value: any;
  autoComplete?: string;
  isEditing?: boolean;
  multiline?: boolean;
  required?: boolean;
  xs?: GridSize;
  sm?: GridSize;
  type?: any;
  onChange?: (value: string) => void;
}> = ({
  label,
  value,
  autoComplete,
  isEditing,
  multiline,
  required,
  xs,
  sm,
  type = "text",
  onChange,
}) => {
  const props =
    xs !== undefined || sm !== undefined ? { item: true, xs, sm } : {};
  const Wrapper = xs !== undefined || sm !== undefined ? Grid : Fragment;

  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      {isEditing ? (
        <TextField
          variant="filled"
          label={label}
          value={value || ""}
          fullWidth
          autoComplete={autoComplete}
          multiline={multiline}
          required={required}
          type={type}
          onChange={
            onChange ? ({ target: { value } }) => onChange(value) : undefined
          }
        />
      ) : (
        <>
          <Typography variant={"subtitle2"}>{label}</Typography>
          <Typography variant={"body2"}>
            {value !== undefined && value !== "" && value !== null ? (
              value
            ) : (
              <Typography variant={"inherit"} color={"textSecondary"}>
                {t("Empty")}
              </Typography>
            )}
          </Typography>
        </>
      )}
    </Wrapper>
  );
};

export default PropertyField;
