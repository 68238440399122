import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import SidePanelModal from "../../../components/SidePanelModal/SidePanelModal";
import React, { FunctionComponent, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { UserFilter } from "../User";
import {
  setFilter,
  usersFilterSelector,
  usersIsLoadingSelector,
} from "../usersSlice";

export const Filter: FunctionComponent<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });
  const dispatch = useDispatch();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const isLoading = useSelector(usersIsLoadingSelector);
  const filter = useSelector(usersFilterSelector);

  return (
    <SidePanelModal
      title={t("Filter")}
      open={open}
      onClose={onClose}
      width={theme.spacing(50)}
    >
      <DialogContent>
        <Formik
          initialValues={
            {
              id: "" as any,
              email: "",
              name: "",
              username: "",
              "userTenantRoles.tenant.company": "",
              ...filter,
            } as UserFilter
          }
          validationSchema={Yup.object().shape<UserFilter>({
            id: Yup.number().nullable(true).integer(t("Must be a number")),
            email: Yup.string().email(t("Must be a valid email")),
            name: Yup.string(),
          })}
          onSubmit={(values) => {
            dispatch(setFilter(values));
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="id"
                    label={t("ID")}
                    type={isMobile ? "tel" : "number"}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="['userTenantRoles.tenant.company']"
                    label={t("Company")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="name"
                    label={t("Name")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="username"
                    label={t("Username")}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="filled"
                    fullWidth
                    name="email"
                    label={t("Email")}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
              <button
                ref={submitButtonRef}
                type="submit"
                style={{ border: 0, padding: 0 }}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(setFilter({}));
            onClose();
          }}
          disabled={isLoading}
        >
          {t("Clear")}
        </Button>
        <Button
          onClick={() => {
            submitButtonRef.current?.click();
            onClose();
          }}
          type="submit"
          color="primary"
          variant="contained"
          disableElevation
          disabled={isLoading}
        >
          {t("Filter")}
        </Button>
      </DialogActions>
    </SidePanelModal>
  );
};
